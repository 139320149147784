import React from "react";
import Profile from "./Profile";

const TopHeaderSection = () => {
  return (
    <>
      <div className="container-fluid py-4 d-md-block d-none">
        <div className="row">
          <div className="col-auto align-self-center">
            <h4 className="mb-0"> </h4>
          </div>

          <Profile />
        </div>
      </div>
    </>
  );
};

export default TopHeaderSection;
