import React, { useEffect, useState } from "react";
import CommonSearchBox from "../../components/CommonSearchBox";
import Header from "../../components/Header";
import Pagination from "../../components/Pagination";
import TopHeaderSection from "../../components/TopHeaderSection";
import { getAPI } from "../../service/ApiClient";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";

import MobileNav from "../../components/MobileNav";
import Footer from "../../components/Footer";
import Contentloader from "../../components/ContentLoader";
import "rsuite/dist/rsuite.min.css";
import PageIcon from "@rsuite/icons/Page";
import FileDownloadIcon from "@rsuite/icons/FileDownload";

import { Popover, Whisper, Dropdown, IconButton } from "rsuite/";
import NoData from "../../components/Nodata";

const TeamMetrics = () => {
  const [teamData, setTeamData] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [asc, setAsc] = useState(false);
  const [loading, setLoading] = useState(false);
  const [csvList, setCsvList] = useState([])
  useEffect(() => {
    async function fetchMyAPI() {
      setLoading(true);
      await getAPI("/v1/user/admin/teams-list?search=" + searchText)
        .then((response) => {
          setLoading(false);
          setTeamData(
            response.data.data
              .sort((a, b) => {
                if (a.team_name.toLowerCase() < b.team_name.toLowerCase()) {
                  return asc === false ? -1 : 1;
                }
                if (a.team_name.toLowerCase() > b.team_name.toLowerCase()) {
                  return asc === false ? 1 : -1;
                }

                return 0;
              })
              .map((object) => {
                const { _id, game_type, ...rest } = object;

                return rest;
              })
          );
        })
        .catch((err) => setLoading(false));
    }
    fetchMyAPI();
  }, [searchText, asc]);

  useEffect(() => {
    setCsvList(teamData?.map((item) => ({
      TEAM_NAME: item.team_name,
      TEAM_TYPE: item.team_type,
      AGE_GROUP: item.age_value,
      SEASON: item.season_name,
      LOCATION: item.address,
    })))
  }, [teamData, searchText])

  // Get current page items
  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;

  const paginate = (number) => {
    setcurrentPage(number);
  };

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Teams";
    const headers = [
      ["TEAM NAME", "TEAM TYPE", "AGE GROUP", "SEASON", "LOCATION"],
    ];

    const data = teamData.map((elt) => [
      elt.team_name,
      elt.team_type,
      elt.age_value,
      elt.season_name,
      elt.address,
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("report.pdf");
  };

  const renderMenu = ({ left, top, className }, ref) => {
    return (
      <Popover ref={ref} className={className} style={{ left, top }} full>
        <Dropdown.Menu title="Create new file">
          <Dropdown.Item icon={<PageIcon />} eventKey={1}>
            <CSVLink data={csvList} target="_blank" filename="Team list">
              <button>CSV format ⬇</button>
            </CSVLink>
          </Dropdown.Item>
          <Dropdown.Item icon={<FileDownloadIcon />} eventKey={2}>
            <button onClick={() => exportPDF()}>PDF format ⬇</button>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Popover>
    );
  };

  return (
    <>
      <div className="container-fluid position-relative w-100 h-100">
        <div className="row h-100">
          <div
            className="col-sm-2 px-0 h-100 d-md-block d-none"
            style={{ width: "15%" }}
          >
            <Header />
          </div>
          <MobileNav />
          <div className="col-12 col-md-10 px-0 h-100">
            <div className="w-100 h-100 d-flex flex-column pb-3">
              <TopHeaderSection />
              <div className="container-fluid py-3 border-top overflow-auto flex-fill">
                <div className="row">
                  <div className="col">
                    <h5 className="mb-0">Teams</h5>
                  </div>
                  <div className="col-auto ms-auto">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0"></ol>
                    </nav>
                  </div>

                  <div className="border rounded mt-3">
                    <div className="d-md-flex align-items-center border-bottom p-1 col-12">
                      <div className="col-lg-4 px-0">
                        <button
                          className="btn  w-10 mt-4 mb-3"
                          style={{ marginRight: "1vw", cursor: "default" }}
                        >
                          <h6 className="mb-0 fw-bold active-text-color">
                            Teams List
                          </h6>
                        </button>
                      </div>

                      <div className="col-lg-8 d-md-flex justify-content-end align-items-center px-0">
                        <div className="col-lg-6 col-md-7 pe-0 mb-md-0 mb-3">
                          <CommonSearchBox
                            searchText={searchText}
                            setSearchText={setSearchText}
                          />
                        </div>
                        <center>
                          <div style={{ padding: 20 }}>
                            <Whisper
                              placement="bottomStart"
                              trigger="click"
                              speaker={renderMenu}
                            >
                              <IconButton
                                appearance="primary"
                                color="blue"
                                icon={<PageIcon />}
                              >
                                Export report
                              </IconButton>
                            </Whisper>
                          </div>
                        </center>
                      </div>
                    </div>
                    {loading === false ? (
                      <div className="row p-0 gx-0 mt-3">
                        <div className="col p-1">
                          <div className="table-responsive">
                            {teamData.length > 0 ? (
                              <table className="table mb-0">
                                <thead className="text-nowrap">
                                  <tr className="bg-light">
                                    <th>S No.</th>
                                    <th>
                                      {" "}
                                      {asc === false ? (
                                        <i
                                          className="fa fa-arrow-up cursor-pointer"
                                          onClick={() => setAsc(!asc)}
                                        ></i>
                                      ) : (
                                        <i
                                          className="fa fa-arrow-down cursor-pointer"
                                          onClick={() => setAsc(!asc)}
                                        ></i>
                                      )}{" "}
                                      Team Name
                                    </th>
                                    <th>Team Type</th>
                                    <th>Age Group</th>
                                    <th>Season</th>
                                    <th>Location</th>
                                  </tr>
                                </thead>

                                {teamData
                                  ?.slice(indexOfFirstPost, indexOfLastPost)
                                  .map((item, index) => {
                                    return (
                                      <>
                                        <tbody
                                          key={index}
                                          className="text-nowrap"
                                        >
                                          <tr>
                                            <td>
                                              {(
                                                (currentPage - 1) * 10 +
                                                index +
                                                1
                                              )
                                                .toString()
                                                .padStart(2, "0")}
                                            </td>
                                            <td>{item.team_name}</td>
                                            <td>
                                              {item.team_type
                                                ? item.team_type
                                                : "Unavailable"}
                                            </td>
                                            <td>
                                              {item.age_value
                                                ? item.age_value
                                                : "Unavailable"}
                                            </td>
                                            <td>{item.season_name}</td>
                                            <td>{item.address}</td>
                                          </tr>
                                        </tbody>
                                      </>
                                    );
                                  })}
                              </table>
                            ) : (
                              <NoData data={"No teams found"} />
                            )}
                          </div>
                        </div>
                        {teamData.length > 10 ? (
                          <>
                            <Pagination
                              itemsPerPage={itemsPerPage}
                              totalItems={teamData?.length}
                              paginate={paginate}
                              currentPage={currentPage}
                              setPrevBtn={() =>
                                setcurrentPage(() => currentPage - 1)
                              }
                              setNextBtn={() =>
                                setcurrentPage(() => currentPage + 1)
                              }
                              searchText={searchText}
                            />
                          </>
                        ) : null}
                      </div>
                    ) : (
                      <Contentloader />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default TeamMetrics;
