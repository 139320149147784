import { devBaseUrl, qaBaseUrl, prodBaseUrl } from "../config/config";
import { toast } from "react-toastify";
import axios from "axios";

export const ApiClient = () => {};
let baseUrl;
// const baseUrl = qaBaseUrl;

const environment = process.env.NODE_ENV;
console.log(environment);
const currentURL = new URL(window.location.href);
const baseURL = `${currentURL.protocol}//${currentURL.host}/`;
const PRODURL = "https://admin.gloversscorebooks.com/";
console.log(prodBaseUrl);
console.log(baseURL);
if (baseURL === PRODURL) {
  baseUrl = prodBaseUrl;
} else if (baseURL === qaBaseUrl) {
  baseUrl = qaBaseUrl;
} else if (baseURL === devBaseUrl) {
  baseUrl = devBaseUrl;
} else {
  baseUrl = devBaseUrl;
}
console.log(baseUrl);

let UserToken = localStorage.getItem("userToken");
let UserDetail = localStorage.getItem("userDetail");

export async function getAPI(route) {
  try {
    const response = await axios.get(`${baseUrl}/${route}`, {
      method: "GET",
      cache: "no-cache",
      credentials: "same-origin",
      headers: { Authorization: JSON.parse(UserToken)?.refresh_token },
    });
    if (response?.data?.status === "error") {
      toast.error("Went Wrong");
    }
    if (response?.data?.status === "success") {
      return response;
    }
  } catch (err) {
    if (
      err?.response?.status === 401 &&
      err?.response?.data?.error === "Token expired"
    ) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload();
    } else {
      console.error(`GET error: ${err}`);
    }
  }
}

// for active / deactivated toggled button
export async function postApi(route, id, status) {
  const myHeaders = {
    "Content-Type": "application/json",
    Authorization: JSON.parse(UserToken).refresh_token,
  };

  const data = {
    userId: id,
    status: status,
  };

  try {
    const response = await axios.post(`${baseUrl}/${route}`, data, {
      headers: myHeaders,
    });
    if (response?.data?.status === "error") {
      toast.error("Went Wrong");
    }
    if (response?.data?.status === "success") {
      return response;
    }
  } catch (error) {
    console.log("POST error in toggling button state", error);
  }
}

// for edit profile
export async function apiPost(
  route,
  userFirstName,
  userLastName,
  imageName,
  imageExtension,
  forImage
) {
  const myHeaders = {
    "Content-Type": "application/json",
    Authorization: forImage
      ? JSON.parse(UserDetail).refresh_token
      : JSON.parse(UserToken).refresh_token,
  };

  const data = {
    first_name: userFirstName,
    last_name: userLastName,
    photo: imageName,
    imageType: imageExtension,
  };

  try {
    const response = await axios.post(`${baseUrl}/${route}`, data, {
      headers: myHeaders,
    });
    if (response?.data?.status === "error") {
      toast.error("Went Wrong");
    }
    if (response?.data?.status === "success") {
      return response;
    }
  } catch (error) {
    console.log("POST error", error);
  }
}

// for refresh token value post method
export async function refreshTokenPost(route, refreshTokenValue) {
  const myHeaders = {
    "Content-Type": "application/json",
  };

  const data = {
    refresh_token: refreshTokenValue,
  };

  try {
    const response = await axios.post(`${devBaseUrl}/${route}`, data, {
      headers: myHeaders,
    });
    if (response?.data?.status === "error") {
      toast.error("Went Wrong");
    }
    if (response?.data?.status === "success") {
      return response;
    }
  } catch (error) {
    console.log("POST error", error);
  }
}

// for login and forgot password
export async function authPost(route, userName, password, forgotPass) {
  const myHeaders = {
    "Content-Type": "application/json",
  };

  let data;

  if (!forgotPass) {
    data = {
      email: userName,
      password: password,
    };
  } else {
    data = {
      email: userName,
    };
  }

  try {
    const response = await axios.post(`${devBaseUrl}/${route}`, data, {
      headers: myHeaders,
    });
    if (!forgotPass) {
      if (response?.data?.status === "error") {
        toast.error("Wrong Detail");
      }
      if (response?.data?.status === "success") {
        return response;
      }
    } else if (response?.data?.status === "success") {
      return response;
    }
  } catch (error) {
    console.log("POST error", error);
  }
}

export async function post(route, objData) {
  try {
    const response = await post(`${baseUrl}/${route}`, {
      objData,
    });
    return response.json();
  } catch (err) {
    console.error(`GET error: ${err}`);
  }
}

export async function postGlobalSearch(route, searchText) {
  const myHeaders = {
    "Content-Type": "application/json",
    Authorization: JSON.parse(UserToken).refresh_token,
  };
  const data = JSON.stringify({
    search: searchText,
    type: "users",
  });
  try {
    const response = await axios.post(`${baseUrl}/${route}`, data, {
      headers: myHeaders,
    });
    return response;
  } catch (err) {
    console.error(`GET error: ${err}`);
  }
}

// for event setting button
export async function postSettingApi(route, type, number) {
  const myHeaders = {
    "Content-Type": "application/json",
    Authorization: JSON.parse(UserToken).refresh_token,
  };

  const data = JSON.stringify({
    type: type,
    value: parseInt(number),
  });

  // const data = {
  //   type: type,
  //   value: number,
  // };

  try {
    const response = await axios.post(`${baseUrl}/${route}`, data, {
      headers: myHeaders,
    });
    if (response?.data?.status === "error") {
      toast.error("Went Wrong");
    }
    if (response?.data?.status === "success") {
      return response;
    }
  } catch (error) {
    console.log("POST error in toggling button state", error);
  }
}

export async function postSeason(route, name, start_date, end_date) {
  const myHeaders = {
    "Content-Type": "application/json",
    Authorization: JSON.parse(UserToken).refresh_token,
  };

  const data = {
    name: name,
    start_date,
    end_date,
  };

  try {
    const response = await axios.post(`${baseUrl}/${route}`, data, {
      headers: myHeaders,
    });
    if (response?.data?.status === "error") {
      console.log(response);
      toast.error(response?.data?.message);
    }
    if (response?.data?.status === "success") {
      return response;
    }
  } catch (error) {
    console.log("POST error in toggling button state", error);
  }
}

export async function postTeam(route, email) {
  const myHeaders = {
    "Content-Type": "application/json",
    Authorization: JSON.parse(UserToken).refresh_token,
  };

  const data = {
    email: email,
  };

  try {
    const response = await axios.post(`${baseUrl}/${route}`, data, {
      headers: myHeaders,
    });
    if (response?.data?.status === "error") {
      console.log(response);
      toast.error(response?.data?.message);
    }
    if (response?.data?.status === "success") {
      return response;
    }
  } catch (error) {
    console.log("POST error", error);
  }
}

export async function updateSeason(
  route,
  season_id,
  name,
  start_date,
  end_date
) {
  const myHeaders = {
    "Content-Type": "application/json",
    Authorization: JSON.parse(UserToken).refresh_token,
  };

  const data = {
    season_id,
    name: name,
    start_date,
    end_date,
  };

  try {
    const response = await axios.post(`${baseUrl}/${route}`, data, {
      headers: myHeaders,
    });
    if (response?.data?.status === "error") {
      toast.error("Went Wrong");
    }
    if (response?.data?.status === "success") {
      return response;
    }
  } catch (error) {
    console.log("POST error in toggling button state", error);
  }
}

export async function deleteSeason(route, season_id) {
  const myHeaders = {
    "Content-Type": "application/json",
    Authorization: JSON.parse(UserToken).refresh_token,
  };

  const data = {
    season_id,
  };

  try {
    const response = await axios.put(`${baseUrl}/${route}`, data, {
      headers: myHeaders,
    });
    if (response?.data?.status === "error") {
      toast.error("Went Wrong");
    }
    if (response?.data?.status === "success") {
      return response;
    }
  } catch (error) {
    console.log("POST error in toggling button state", error);
  }
}

export async function getSeason(route) {
  const myHeaders = {
    "Content-Type": "application/json",
    Authorization: JSON.parse(UserToken).refresh_token,
  };

  try {
    const response = await axios.get(`${baseUrl}/${route}`, {
      headers: myHeaders,
    });
    if (response?.data?.status === "error") {
      toast.error("Went Wrong");
    }
    if (response?.data?.status === "success") {
      return response;
    }
  } catch (err) {
    if (
      err?.response?.status === 401 &&
      err?.response?.data?.error === "Token expired"
    ) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload();
    } else {
      console.error(`GET error: ${err}`);
    }
  }
}

export async function postPromo(
  route,
  name,
  type,
  amount,
  duration,
  duration_in_months,
  description
) {
  const myHeaders = {
    "Content-Type": "application/json",
    Authorization: JSON.parse(UserToken).refresh_token,
  };
  const data = {
    name,
    type,
    amount,
    duration,
    duration_in_months,
    description,
  };
  try {
    const response = await axios.post(`${baseUrl}/${route}`, data, {
      headers: myHeaders,
    });
    if (response?.data?.status === "error") {
      toast.error("Went Wrong");
    }
    if (response?.data?.status === "success") {
      return response;
    }
  } catch (error) {
    console.log("POST error in toggling button state", error);
  }
}

export async function getPromo(route) {
  const myHeaders = {
    "Content-Type": "application/json",
    Authorization: JSON.parse(UserToken).refresh_token,
  };

  try {
    const response = await axios.get(`${baseUrl}/${route}`, {
      headers: myHeaders,
    });
    if (response?.data?.status === "error") {
      toast.error("Went Wrong");
    }
    if (response?.data?.status === "success") {
      return response;
    }
  } catch (error) {
    console.log("POST error in toggling button state", error);
  }
}

export async function deletePromo(route, id) {
  const myHeaders = {
    "Content-Type": "application/json",
    Authorization: JSON.parse(UserToken).refresh_token,
  };
  const data = {
    id,
  };
  try {
    const response = await axios.post(`${baseUrl}/${route}`, data, {
      headers: myHeaders,
    });
    if (response?.data?.status === "error") {
      toast.error("Went Wrong");
    }
    if (response?.data?.status === "success") {
      return response;
    }
  } catch (error) {
    console.log("POST error in toggling button state", error);
  }
}

export async function getSubscriptionPlan(route) {
  const myHeaders = {
    "Content-Type": "application/json",
    Authorization: JSON.parse(UserToken).refresh_token,
  };

  try {
    const response = await axios.get(`${baseUrl}/${route}`, {
      headers: myHeaders,
    });
    if (response?.data?.status === "error") {
      toast.error("Went Wrong");
    }
    if (response?.data?.status === "success") {
      return response;
    }
  } catch (err) {
    if (
      err?.response?.status === 401 &&
      err?.response?.data?.error === "Token expired"
    ) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload();
    } else {
      console.error(`GET error: ${err}`);
    }
  }
}

export async function getTransactionDetails(route) {
  const myHeaders = {
    "Content-Type": "application/json",
    Authorization: JSON.parse(UserToken).refresh_token,
  };

  try {
    const response = await axios.get(`${baseUrl}/${route}`, {
      headers: myHeaders,
    });
    if (response?.data?.status === "error") {
      toast.error("Went Wrong");
    }
    if (response?.data?.status === "success") {
      return response;
    }
  } catch (error) {
    console.log("POST error in toggling button state", error);
  }
}

export async function getPdfTransactionDetail(route) {
  const myHeaders = {
    "Content-Type": "application/json",
    Authorization: JSON.parse(UserToken).refresh_token,
  };

  try {
    const response = await axios.get(`${baseUrl}/${route}`, {
      headers: myHeaders,
    });
    if (response?.data?.status === "error") {
      toast.error("Went Wrong");
    }
    if (response?.data?.status === "success") {
      return response;
    }
  } catch (error) {
    console.log("POST error in toggling button state", error);
  }
}
