import React from "react";
import { NavLink } from "react-router-dom";

const Header = () => {
  const navLinksStyle = ({ isActive }) => {
    return {
      fontWeight: isActive ? "bold" : "normal",
      padding: "10px",
      fontSize: isActive ? "1vw" : "1vw",
      background: isActive
        ? "linear-gradient(to right bottom, #F0F8FF, #E6E6FA)"
        : null,
      borderRadius: isActive ? "5px" : null,
    };
  };

  return (
    <div className="w-100 h-100 p-3 border-end bg-light bg-opacity-10 d-flex flex-column">
      <a href="/dashboard">
        <img
          src="../assets/images/logo.png"
          className="w-75 mb-3 mt-3"
          alt=""
        />
      </a>
      <p className="small text-muted text-uppercase mb-0 mt-4">Main Menu</p>
      <nav className="d-flex my-3 flex-column">
        <NavLink
          className="my-2 d-flex text-black"
          to="/dashboard"
          style={navLinksStyle}
        >
          <img
            src="../assets/icons/tachometer.png"
            className="align-self-center  me-2"
            alt=""
            style={{ width: "1.2vw" }}
            onClick={() => localStorage.setItem("globalSearchShow", true)}
          />
          <span
            className="align-self-center text"
            onClick={() => localStorage.setItem("globalSearchShow", true)}
          >
            Dashboard
          </span>
        </NavLink>

        <NavLink
          className="my-2 d-flex text-black"
          to="/usermetrics"
          style={navLinksStyle}
        >
          <img
            src="../assets/icons/user.png"
            className=" align-self-center  me-2"
            alt=""
            style={{ width: "1vw" }}
            onClick={() => localStorage.setItem("globalSearchShow", false)}
          />
          <span
            className="align-self-center"
            onClick={() => localStorage.setItem("globalSearchShow", false)}
          >
            Users List
          </span>
        </NavLink>

        <NavLink
          className="my-2 d-flex text-black"
          to="/teammetrics"
          style={navLinksStyle}
        >
          <img
            src="../assets/icons/group.png"
            className=" align-self-center  me-2"
            alt=""
            style={{ width: "1vw" }}
            onClick={() => localStorage.setItem("globalSearchShow", false)}
          />
          <span
            className="align-self-center"
            onClick={() => localStorage.setItem("globalSearchShow", false)}
          >
            Teams List
          </span>
        </NavLink>

        <NavLink
          to="/events"
          className="my-2 d-flex text-black"
          style={navLinksStyle}
        >
          <img
            src="../assets/icons/calendar.png"
            className=" align-self-center  me-2"
            alt=""
            style={{ width: "0.9vw" }}
            onClick={() => localStorage.setItem("globalSearchShow", false)}
          />
          <span
            className="align-self-center"
            onClick={() => localStorage.setItem("globalSearchShow", false)}
          >
            Events List
          </span>
        </NavLink>
        <NavLink
          className="my-2 d-flex text-black"
          to="/seasons"
          style={navLinksStyle}
        >
          <img
            src="../assets/icons/group.png"
            className=" align-self-center  me-2"
            alt=""
            style={{ width: "1vw" }}
            onClick={() => localStorage.setItem("globalSearchShow", false)}
          />
          <span
            className="align-self-center"
            onClick={() => localStorage.setItem("globalSearchShow", false)}
          >
            Seasons
          </span>
        </NavLink>
        <NavLink
          className="my-2 d-flex text-black"
          to="/pricing"
          style={navLinksStyle}
        >
          <img
            src="../assets/icons/dollarIcon.png"
            className=" align-self-center  me-2"
            alt=""
            style={{ width: "1vw" }}
            onClick={() => localStorage.setItem("globalSearchShow", false)}
          />
          <span
            className="align-self-center"
            onClick={() => localStorage.setItem("globalSearchShow", false)}
          >
            Subscription Plans
          </span>
        </NavLink>
        <NavLink
          to="/setting"
          className="my-2 d-flex text-black"
          style={navLinksStyle}
        >
          <img
            src="../assets/icons/development.png"
            className=" align-self-center  me-2"
            alt=""
            style={{ width: "1.1vw" }}
            onClick={() => localStorage.setItem("globalSearchShow", false)}
          />
          <span
            className="align-self-center"
            onClick={() => localStorage.setItem("globalSearchShow", false)}
          >
            Settings
          </span>
        </NavLink>
      </nav>
    </div>
  );
};

export default Header;
