import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../../src/view/Dashboard";
import EditProfile from "../../src/view/EditProfile";
import UserMetrics from "../../src/view/UserMetrics";
import RoleMetrics from "../../src/view/RoleMetrics";
import TeamMetrics from "../../src/view/TeamMetrics";
import Events from "../../src/view/Events";
import Settings from "../view/Settings";
import NotificationPreferences from "../../src/view/NotificatinPreference";
import PrivateRoute from "./PrivateRoutes";
import ChangePassword from "../../src/view/ChangePassword";
import Error from "../404";
import Seasons from "../../src/view/Seasons"
import Pricing from "../view/Pricing";


function AuthRoutes() {
  return (
    <Routes>
      <Route
        path="/dashboard"
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/editprofile"
        element={
          <PrivateRoute>
            <EditProfile />
          </PrivateRoute>
        }
      />
      <Route
        path="/usermetrics"
        element={
          <PrivateRoute>
            <UserMetrics />
          </PrivateRoute>
        }
      />
      <Route
        path="/rolemetrics"
        element={
          <PrivateRoute>
            <RoleMetrics />
          </PrivateRoute>
        }
      />
      <Route
        path="/teammetrics"
        element={
          <PrivateRoute>
            <TeamMetrics />
          </PrivateRoute>
        }
      />
      <Route
        path="/events"
        element={
          <PrivateRoute>
            <Events />
          </PrivateRoute>
        }
      />
      <Route
        path="/seasons"
        element={
          <PrivateRoute>
            <Seasons />
          </PrivateRoute>
        }
      />
      <Route
        path="/notificationpreference"
        element={
          <PrivateRoute>
            <NotificationPreferences />
          </PrivateRoute>
        }
      />
      <Route
        path="/pricing"
        element={
          <PrivateRoute>
            <Pricing />
          </PrivateRoute>
        }
      />
      <Route
        path="/setting"
        element={
          <PrivateRoute>
            <Settings />
          </PrivateRoute>
        }
      />

      <Route
        path="/changepassword"
        element={
          <PrivateRoute>
            <ChangePassword />
          </PrivateRoute>
        }
      />

      <Route path="*" element={<Error />} />
    </Routes>
  );
}

export default AuthRoutes;
