import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

import Header from "../../components/Header";
// import Profile from "../Components/profile";
import TopHeaderSection from "../../components/TopHeaderSection";
import { devBaseUrl } from "../../config/config";
import Footer from "../../components/Footer";
import MobileNav from "../../components/MobileNav";

const ChangePassword = () => {
  // useEffect(() => {
  //   setSelectedFile(
  //     "https://images.unsplash.com/photo-1511367461989-f85a21fda167?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cHJvZmlsZXxlbnwwfHwwfHw%3D&w=1000&q=80"
  //   );
  // }, []);
  // const [selectedFile, setSelectedFile] = useState();
  const [oldPasswod, setOldPasswod] = useState("");
  const [newPasswod, setNewPasswod] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  let UserDetail = localStorage.getItem("userDetail");
  let UserOldPassword = localStorage.getItem("userOldPassword");

  let navigate = useNavigate();

  const changePassword = async () => {
    if (UserOldPassword === oldPasswod) {
      if (newPasswod === confirmPassword) {
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          JSON.parse(UserDetail).token_details.access_token
        );
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
          old_password: oldPasswod,
          new_password: newPasswod,
        });

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        await fetch(devBaseUrl + "v1/auth/changePassword", requestOptions)
          .then((response) => response.json())
          .then((result) => {
            if (result.status === "success") {
              alert(result.data);
              navigate("/");
            }
          })
          .catch((error) => console.log("error", error));
      } else {
        alert(
          "New Password and Confirm Password are not matching. Please try again"
        );
      }

      await fetch(devBaseUrl + "v1/auth/changePassword", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.status === "success") {
            alert(result.data);
            navigate("/");
          }
        })
        .catch((error) => console.log("error", error));
    } else {
      alert("Old Password is not matching. Please try again");
    }
  };

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div className="container-fluid position-relative w-100 h-100">
        <div className="row h-100">
          <div
            className="col-sm-2 px-0 h-100 d-md-block d-none"
            style={{ width: "15%" }}
          >
            <Header />
          </div>
          <MobileNav />
          <div className="col-12 col-md-10 px-0 h-100">
            <div className="w-100 h-100 d-flex flex-column pb-3">
              <TopHeaderSection />
              <div className="container-fluid py-3 border-top border-bottom">
                <div className="row">
                  <div className="col">
                    <h5 className="mb-0">Change Password</h5>
                  </div>
                  <div className="col-auto ms-auto">
                   
                  </div>
                </div>
              </div>
              <div className="container-fluid mt-3 overflow-auto flex-fill">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="bg-light p-3 mt-3">
                      <div className="p-sm-3">
                        <form action="">
                          <div className="mb-3">
                            <label className="form-label">Old Password</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Old Password"
                              onChange={(e) => setOldPasswod(e.target.value)}
                              value={oldPasswod}
                            />
                          </div>

                          <div className="mb-3">
                            <label className="form-label">New Password</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="New Password"
                              onChange={(e) => setNewPasswod(e.target.value)}
                              value={newPasswod}
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">
                              Confirm Password
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Confirm Password"
                              onChange={(e) =>
                                setConfirmPassword(e.target.value)
                              }
                              value={confirmPassword}
                            />
                          </div>

                          <button
                            className="btn btn-secondary"
                            onClick={(e) => changePassword(e.preventDefault())}
                          >
                            Update
                          </button>
                          <button
                            className="btn btn-info"
                            style={{ marginLeft: "5px" }}
                            onClick={() => navigate("/dashboard")}
                          >
                            Close
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default ChangePassword;
