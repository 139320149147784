import React from "react";
import ContentLoader from "react-content-loader";

const Contentloader = () => {
  return (
    <div>
      <ContentLoader viewBox="0 0 820 450" height={"100%"} width={"100%"}>
        <rect x="10" y="10" rx="5" ry="5" width="100%" height="50" />

        <rect x="10" y="80" rx="5" ry="5" width="50%" height="150" />
        <rect x="450" y="80" rx="2" ry="2" width="50%" height="150" />
      </ContentLoader>
    </div>
  );
};

export default Contentloader;
