import React, { useEffect, useState } from "react";
import {
  accessKey,
  region,
  s3Bucket,
  secretAccessKey,
} from "../../config/config";
import { useNavigate } from "react-router-dom";
// import Profile from "../Components/profile";
import { uploadFile } from "react-s3";
import Header from "../../components/Header";
import TopHeaderSection from "../../components/TopHeaderSection";
import { apiPost } from "../../service/ApiClient";
import MobileNav from "../../components/MobileNav";
import { toast } from "react-toastify";
import Footer from "../../components/Footer";

window.Buffer = window.Buffer || require("buffer").Buffer;

const S3_BUCKET = s3Bucket;
const REGION = region;
const ACCESS_KEY = accessKey;
const SECRET_ACCESS_KEY = secretAccessKey;

const config = {
  bucketName: S3_BUCKET,
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
};

const EditProfile = () => {
  let UserDetail = localStorage.getItem("userDetail");
  console.log("UserDetail", JSON.parse(UserDetail));
  // let UserToken = localStorage.getItem("userToken");
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);
  const [profilePicture, setProfilePicture] = useState(null);

  const [imageName, setImageName] = useState("imagename");
  const [imageExtension, setImageExtention] = useState("");

  useEffect(() => {
    setUserFirstName(JSON.parse(UserDetail)?.first_name);
    setUserLastName(JSON.parse(UserDetail)?.last_name);
    // setImageName(JSON.parse(UserDetail)?.photo);
  }, [UserDetail]);

  let navigate = useNavigate();

  useEffect(() => {
    setProfilePicture(
      "https://gs-dev-media.s3.amazonaws.com/" + JSON.parse(UserDetail)?.photo
    );
  }, [UserDetail]);

  const handleFileInput = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleUpload = async (file) => {
    await uploadFile(file, config)
      .then((data) => {
        console.log("data", data);
        setProfilePicture(data.location);
        setImageName(data.key);
        setImageExtention(data.key.split(".").pop());
      })
      .catch((err) => console.error("err", err));
  };

  const updateUserDetail = async () => {
    apiPost("v1/user/profile-update", userFirstName, userLastName, imageName)
      .then((res) => {
        console.log("res", res);
        localStorage.setItem("userDetail", JSON.stringify(res.data.data));
        toast.success("Profile updated successfully");
        imageUploadToken();
        window.location.reload();
      })
      .catch((err) => {
        if (err) {
          toast.error("Went wrong! Please try again");
        }
      });
  };

  const imageUploadToken = async () => {
    apiPost(
      "v1/user/imageUploadToken",
      userFirstName,
      userLastName,
      imageName,
      imageExtension,
      "forImage"
    ).then((res) => console.log("image", res));
  };

  console.log("imageName", imageName);
  return (
    <>
      <div className="container-fluid position-relative w-100 h-100">
        <div className="row h-100">
          <div
            className="col-sm-2 px-0 h-100 d-md-block d-none"
            style={{ width: "15%" }}
          >
            <Header />
          </div>
          <MobileNav />
          <div className="col-12 col-md-10 px-0 h-100">
            <div className="w-100 h-100 d-flex flex-column pb-3">
              <TopHeaderSection />
              <div className="container-fluid py-3 border-top border-bottom">
                <div className="row">
                  <div className="col">
                    <h5 className="mb-0">Edit Profile</h5>
                  </div>
                  <div className="col-auto ms-auto">
                    {/* <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0">
                        <li className="breadcrumb-item">
                          <a href="/dashboard">Dashboard</a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          User
                        </li>
                      </ol>
                    </nav> */}
                  </div>
                </div>
              </div>
              <div className="container-fluid mt-3 overflow-auto flex-fill">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="bg-light p-3 mt-3">
                      <div className="p-sm-3">
                        <p className="mb-0" style={{ fontWeight: "bold" }}>
                          Profile Picture
                        </p>
                      </div>
                      <div className="px-sm-3 py-3">
                        <div className="row">
                          <div className="col-sm-12 rounded">
                            <div className="d-flex">
                              <input
                                type="file"
                                style={{ marginBottom: "5px" }}
                                onChange={handleFileInput}
                              />
                              <button
                                className="btn btn-primary"
                                onClick={() => handleUpload(selectedFile)}
                              >
                                Upload
                              </button>
                            </div>
                          </div>
                          <img
                            src={profilePicture}
                            className="rounded w-100"
                            alt="..."
                          />
                        </div>
                      </div>
                      <div className="p-sm-3">
                        <form action="">
                          <div className="mb-3">
                            <label
                              className="form-label"
                              style={{ fontWeight: "bold" }}
                            >
                              First Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="First Name"
                              onChange={(e) => setUserFirstName(e.target.value)}
                              value={userFirstName}
                            />
                          </div>
                          <div className="mb-3">
                            <label
                              className="form-label"
                              style={{ fontWeight: "bold" }}
                            >
                              Last Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Last Name"
                              onChange={(e) => setUserLastName(e.target.value)}
                              value={userLastName}
                            />
                          </div>

                          <div className="mb-3">
                            <label
                              className="form-label"
                              style={{ fontWeight: "bold" }}
                            >
                              Email
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder={JSON.parse(UserDetail)?.email}
                              disabled
                            />
                          </div>

                          <hr />
                          <button
                            className="btn btn-secondary"
                            onClick={(e) =>
                              updateUserDetail(e.preventDefault())
                            }
                          >
                            Update
                          </button>
                          <button
                            className="btn btn-info"
                            style={{ marginLeft: "5px" }}
                            onClick={() => navigate("/dashboard")}
                          >
                            Close
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default EditProfile;
