import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LogoutPopup from "./LogoutPopup";

const Profile = () => {
  const [showModal, setShowModal] = useState(false);

  let navigate = useNavigate();

  let UserDetail = localStorage.getItem("userDetail");

  return (
    <>
      <div className="col-auto ms-auto align-self-center dropdown d-md-block d-none" >
        <div
          className="bg-light d-flex py-2 px-3 cursor-pointer"
          id="profile-dropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false"
         
        >
          {/* <i className="far fa-user-circle me-2 align-self-center"></i> */}
          <img
            src="../assets/icons/user.png"
            className=" align-self-center me-2 mb-1"
            alt=""
            style={{ width: "10px", minWidth: "16px" }}
          />
          <p className="mb-0">{JSON.parse(UserDetail)?.username}</p>
          {/* <i className="far fa-chevron-down ms-2 align-self-center"></i> */}
          <img
            src="../assets/icons/down-chevron.png"
            className=" align-self-center"
            alt=""
            style={{ width: "16px", minWidth: "25px" }}
          />
        </div>
        <ul
          className="dropdown-menu dropdown-menu-end mt-1 pl-2 pr-2"
          aria-labelledby="profile-dropdown"
          style={{backgroundColor: '#F2F7FF'}}
        >
          <li>
            <button
              className="dropdown-item border-bottom"
              onClick={() => navigate("/editprofile")}
            >
              <span className="pr-2" style={{ marginLeft: "-9px" }}>
                {/* <i className="fal fa-user"></i> */}
                <img
                  src="../assets/icons/user.png"
                  className=" align-self-center me-2"
                  alt=""
                  style={{ width: "16px", minWidth: "17px" }}
                />
              </span>
              Edit Profile
            </button>
          </li>
          <li>
            <button
              className="dropdown-item border-bottom"
              onClick={() => navigate("/changepassword")}
            >
              <span className="pr-2" style={{ marginLeft: "-9px" }}>
                {/* <i className="fal fa-unlock"></i> */}
                <img
                  src="../assets/icons/password.png"
                  className=" align-self-center me-2"
                  alt=""
                  style={{ width: "16px", minWidth: "17px" }}
                />
              </span>
              Change Password
            </button>
          </li>
          {/* <li>
            <button
              className="dropdown-item border-bottom"
              onClick={() => navigate("/notificationpreference")}
            >
              <span className="pr-2" style={{ marginLeft: "-9px" }}>
                <img
                  src="../assets/icons/ringing.png"
                  className=" align-self-center me-2"
                  alt=""
                  style={{ width: "16px", minWidth: "17px" }}
                />
              </span>
              Notification Preferences
            </button>
          </li> */}
          <li>
            <button
              className="dropdown-item"
              onClick={() => setShowModal(true)}
            >
              <span className="pr-2" style={{ marginLeft: "-9px" }}>
                {/* <i className="fal fa-arrow-right"></i> */}
                <img
                  src="../assets/icons/logout.png"
                  className=" align-self-center me-2"
                  alt=""
                  style={{ width: "16px", minWidth: "17px" }}
                />
              </span>
              Logout
            </button>
          </li>
        </ul>
        {showModal && <LogoutPopup setShowModal={setShowModal} />}
      </div>
    </>
  );
};

export default Profile;
