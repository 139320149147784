import React, { useEffect, useState } from "react";
import { postApi } from "../service/ApiClient";
import "./active_toggle_style.css";

const InActiveToggleButton = ({
  label1,
  label2,
  id,
  role,
  firstName,
  lastName,
}) => {
  // let UserToken = sessionStorage.getItem("userToken");

  const [toggled, setToggled] = useState(false);
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);

  // const [confirm, setConfirm] = useState(false);
  const [modal, setShowModal] = useState(false);
  const handleChange = (confirm) => {
    if (confirm == true) {
      setShowModal(false);

      setToggled(!toggled);
      setTimeout(() => {
        if (role === "coach") {
          userActiveCoach(id);
        }

        if (role === "staff") {
          userActiveStaff(id);
        }

        if (role === "player") {
          userActivePlayer(id);
        }

        if (role === "fans") {
          userActiveFans(id);
        }
      }, 100);
    } else {
      setShowModal(true);
    }
  };

  useEffect(() => {
    setstatus();
  }, []);

  const setstatus = () => {
    if (toggled === false) {
      setStatus("ACTIVE");
    } else {
      setStatus("INACTIVE");
    }
  };

  const userActiveCoach = async () => {
    setLoading(true);
    await postApi("v1/user/admin/update-coach-status", id, status)
      .then((res) => {
        setLoading(false);

        if (res.data.status === "success") {
          window.location.reload();
        }
      })
      .catch((err) => setLoading(false));
  };

  const userActiveStaff = async () => {
    setLoading(true);

    await postApi("v1/user/admin/update-staff-status", id, status)
      .then((res) => {
        setLoading(false);
        if (res.data.status === "success") {
          window.location.reload();
        }
      })
      .catch((err) => setLoading(false));
  };

  const userActivePlayer = async () => {
    setLoading(true);

    await postApi("v1/user/admin/update-player-status", id, status)
      .then((res) => {
        setLoading(false);
        if (res.data.status === "success") {
          window.location.reload();
        }
      })
      .catch((err) => setLoading(false));
  };

  const userActiveFans = async () => {
    setLoading(true);

    await postApi("v1/user/admin/update-fan-status", id, status)
      .then((res) => {
        setLoading(false);
        if (res.data.status === "success") {
          window.location.reload();
        }
      })
      .catch((err) => setLoading(false));
  };

  const activeDeactiveUser = (confirm) => {
    handleChange(confirm);
  };

  return (
    <>
      <div>
        <label className="switch-button">
          <input
            type="checkbox"
            checked={toggled}
            onChange={() =>
              toggled ? handleChange() : activeDeactiveUser(true)
            }
          />
          <span className="slider round"></span>
        </label>
        <span style={{ marginRight: 5, marginLeft: 3 }}>
          {toggled ? label2 : label1}
        </span>
      </div>
      {modal == true ? (
        <div className="fixed-top fixed-bottom h-100 d-flex align-items-center justify-content-center">
          <div
            className="position-absolute top-0 bottom-0 h-100 w-100"
            style={{
              zIndex: -1,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              backdropFilter: "blur(1px)",
            }}
          />
          <div
            className="bg-light p-4 py-5 border border-3 rounded w-50"
            // style={{ width: "37%" }}
          >
            <div className="w-75 mt-4">
              <h5>
                Are you sure you want to {toggled ? "deactivate" : "activate"}{" "}
                <span className="fw-bold">
                  {firstName} {lastName}
                </span>
                ?
              </h5>
            </div>

            <div className="d-flex gap-4 mt-3 justify-content-end">
              <button
                type="button"
                className="btn btn-outline-secondary border-2 fw-bolder"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger fw-bolder"
                onClick={() => activeDeactiveUser(true)}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      ) : null}

      {loading === true ? (
        <div className="fixed-top fixed-bottom h-100 d-flex align-items-center justify-content-center">
          <div
            className="position-absolute top-0 bottom-0 h-100 w-100"
            style={{
              zIndex: -1,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              backdropFilter: "blur(1px)",
            }}
          />
          <div
            className="bg-light p-4 py-5 border border-3 rounded w-50"
            // style={{ width: "57%" }}
          >
            <div className="w-75 mt-4">
              <h5>
                <span className="fw-bold">Please wait...</span>
              </h5>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default InActiveToggleButton;
