// import React from "react";
import React from "react";

import EventInputBox from "../../components/EventInputBox";
import Header from "../../components/Header";
import TopHeaderSection from "../../components/TopHeaderSection";
import MobileNav from "../../components/MobileNav";
import Footer from "../../components/Footer";

const data = [
  {
    id: 1,
    name: "Number of Players for team",
    identity: "total_no_players_home_team",
    type: "player",
    typee: "home_team",
  },
  {
    id: 2,
    name: "Number of Staff + Coaches for team",
    identity:"no_of_staffs_coaches_home_team",
    type: "staff",
    typee: "staff_home_team",
  },
  // {
  //   id: 3,
  //   name: "Number of Players for Opponents team",
  //   type: "player",
  //   typee: "opponent_team",
  // },
];

const Settings = () => {
  return (
    <>
      <div className="container-fluid position-relative w-100 h-100">
        <div className="row h-100">
          <div
            className="col-sm-2 px-0 h-100 d-md-block d-none"
            style={{ width: "15%" }}
          >
            <Header />
          </div>
          <MobileNav />
          <div className="col-12 col-md-10 px-0 h-100">
            <div className="w-100 h-100 d-flex flex-column pb-3">
              <TopHeaderSection />
              <div className="container-fluid py-3 border-top overflow-auto flex-fill">
                <div className="row">
                  <div className="col">
                    <h5 className="mb-0">Settings</h5>
                  </div>
                  <div className="col-auto ms-auto">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0"></ol>
                    </nav>
                  </div>

                  <div className="border rounded mt-3">
                    <div className="d-flex align-items-center  p-1 col-12">
                      <div className="col-6 px-0">
                        <button
                          className="btn  w-10 mt-4 mb-3"
                          style={{ marginRight: "1vw", cursor: "default" }}
                        >
                          <h6 className="mb-0 fw-bold active-text-color text-nowrap">
                            Event Creation
                          </h6>
                        </button>
                      </div>
                      <div className="col-6"></div>
                    </div>
                    <div className="row p-0 gx-0">
                      <div className="col p-1">
                        <div className="table-responsive ">
                          <table className="mb-0 ">
                            <thead>
                              <tr>
                                <th
                                  className="settings_th"
                                  style={{ width: "350px", padding: "20px" }}
                                >
                                  Type
                                </th>
                                <th style={{ width: "100px" }}>Value</th>
                                <th>Action</th>
                              </tr>
                            </thead>

                            {data.map((item, index) => {
                              return (
                                <>
                                  <tbody key={index}>
                                    <tr>
                                      <td
                                        style={{ padding: "20px" }}
                                        className="settings_th"
                                      >
                                        {item.name}
                                      </td>
                                      <EventInputBox item={item} />
                                    </tr>
                                  </tbody>
                                </>
                              );
                            })}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Settings;
