import React, { useEffect, useState, createContext } from "react";

export const AuthContext = createContext();

export default function AuthContextProvider({ children }) {
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const checkAuth = () => {
    const token = localStorage.getItem("userToken");

    if (token) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  };

  useEffect(() => {
    checkAuth();
    loadDataFromLocal();
  }, []);

  const loadDataFromLocal = () => {
    let UserDetail = localStorage.getItem("userDetail");
    setUserData(UserDetail);
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isLoading,
        userData,
        setUserData,
        setIsLoading,
        setIsAuthenticated,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
