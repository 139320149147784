import React, { useEffect, useState } from "react";
import CommonSearchBox from "../../components/CommonSearchBox";
import Header from "../../components/Header";
import Pagination from "../../components/Pagination";
import TopHeaderSection from "../../components/TopHeaderSection";
import {
  deleteSeason,
  getAPI,
  getSeason,
  postSeason,
  updateSeason,
} from "../../service/ApiClient";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import MobileNav from "../../components/MobileNav";
import Footer from "../../components/Footer";
import Contentloader from "../../components/ContentLoader";
import "rsuite/dist/rsuite.min.css";
import PageIcon from "@rsuite/icons/Page";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import moment from "moment";
import { Popover, Whisper, Dropdown, IconButton } from "rsuite/";
import NoData from "../../components/Nodata";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Icon } from '@iconify/react';

const Seasons = () => {
  const [teamData, setTeamData] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [asc, setAsc] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);

  const [selectedOption, setSelectedOption] = useState("");
  const [season_id, setSeason_id] = useState(null);
  const [IsDeleteOpen, setIsDeleteOpen] = useState(null);
  const [seasonName, setSeasonName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  useEffect(() => {
    if (seasonName && startDate && endDate) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  }, [seasonName, startDate, endDate]);

  useEffect(() => {
    fetchSeasons();
  }, []);

  const handleSubmit = async () => {
    if (isEditOpen) {
      try {
        // const todayDate = moment(currentDate).format("YYYY-MM-DD")
        // const tomorrowDate = moment(tomorrow).format("YYYY-MM-DD")
        // Perform the API call here
        const response = await updateSeason(
          "/v1/user/admin/season-create",
          season_id,
          seasonName,
          startDate,
          endDate
        );

        // Handle the API response
        console.log("API response:", response.data);

        // Perform additional actions based on the API response

        togglePopup();
        fetchSeasons(); // Close the popup after submitting
      } catch (error) {
        // Handle API call errors
        console.error("API error:", error);
      }
    } else {
      try {
        const response = await postSeason(
          "/v1/user/admin/season-create",
          seasonName,
          startDate,
          endDate
        );

        // Handle the API response
        console.log("API response:", response.data);
        togglePopup();
        fetchSeasons(); // Close the popup after submitting
      } catch (error) {
        togglePopup();
        // console.error('API error:', error);
      }
    }
  };

  const fetchSeasons = async () => {
    setLoading(true);
    try {
      const response = await getSeason("/v1/user/season");
      setTeamData(response.data.data.Season);
      setLoading(false);
    } catch (error) {
      console.error("API error:", error);
      setLoading(false);
    }
  };

  // Get current page items
  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;

  const paginate = (number) => {
    setcurrentPage(number);
  };

  const togglePopup = () => {
    setIsOpen(!isOpen);
    setSelectedOption("");
    setIsEditOpen(false);
    setSeasonName("");
    setStartDate(null);
    setEndDate(null);
  };

  const updatedPopup = (id) => {
    const selectedSeason = teamData.find((item) => item._id === id);
    if (selectedSeason) {
      setSeason_id(selectedSeason._id);
      setSeasonName(selectedSeason.values);
      // Convert start_date to "MM/dd/yyyy" format with leading zeros
      setStartDate(
        selectedSeason.start_date
          ? new Date(selectedSeason.start_date).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })
          : null
      );
      // Convert end_date to "MM/dd/yyyy" format with leading zeros
      setEndDate(
        selectedSeason.end_date
          ? new Date(selectedSeason.end_date).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })
          : null
      );
    }
    setIsOpen(!isOpen);
    setIsEditOpen(true);
  };

  const deletePopup = (id) => {
    const selectedSeason = teamData.find((item) => item._id === id);
    if (selectedSeason) {
      setSeasonName(selectedSeason.values);
      setSeason_id(selectedSeason._id);
    }
    setIsDeleteOpen(true);
  };

  const closeDeletePopup = () => {
    setIsDeleteOpen(false);
    setSeason_id(null);
  };

  const confirmDelete = async () => {
    try {
      await deleteSeason("v1/user/inactive_season", season_id);
      setLoading(false);
      setIsDeleteOpen(false);
      fetchSeasons();
    } catch (error) {
      console.error("API error:", error);
      setLoading(false);
    }
  };

  const getCurrentDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  // const calculateEndDate = (selectedDate) => {
  //   if (!selectedDate) {
  //     setEndDate("");
  //   }
  //   const startDateObject = new Date(selectedDate);
  //   startDateObject.setDate(startDateObject.getDate() - 1);
  //   const endDateObject = new Date(
  //     startDateObject.getFullYear(),
  //     startDateObject.getMonth() + 3,
  //     startDateObject.getDate()
  //   );
  //   const year = endDateObject.getFullYear();
  //   const month = String(endDateObject.getMonth() + 1).padStart(2, '0');
  //   const day = String(endDateObject.getDate()).padStart(2, '0');
  //   const formattedEndDate = `${month}/${day}/${year}`;
  //   return formattedEndDate;
  // };
  const calculateEndDate = (selectedDate) => {
    if (!selectedDate) {
      setEndDate("");
    }
    
    const startDateObject = new Date(selectedDate);
    const endDateObject = new Date(startDateObject);
    endDateObject.setDate(startDateObject.getDate() + 150); // Adding 150 days
  
    const year = endDateObject.getFullYear();
    const month = String(endDateObject.getMonth() + 1).padStart(2, '0');
    const day = String(endDateObject.getDate()).padStart(2, '0');
    const formattedEndDate = `${month}/${day}/${year}`;
    return formattedEndDate;
  };
  const CustomDatePickerInput = React.forwardRef(({ value, onClick, placeholder, disabled }, ref) => (
    <input
      className="form-control"
      type="text"
      value={value}
      onClick={onClick}
      placeholder={placeholder}
      disabled={disabled}
      ref={ref}
      style={{ backgroundColor: disabled ? '#e0e0e0' : 'white', color: 'black' }}
    />
  ));

  return (
    <>
      <div className="container-fluid position-relative w-100 h-100">
        <div className="row h-100">
          <div
            className="col-sm-2 px-0 h-100 d-md-block d-none"
            style={{ width: "15%" }}
          >
            <Header />
          </div>
          <MobileNav />
          <div className="col-12 col-md-10 px-0 h-100">
            <div className="w-100 h-100 d-flex flex-column pb-3">
              <TopHeaderSection />
              <div className="container-fluid py-3 border-top overflow-auto flex-fill">
                <div className="row">
                  <div className="col">
                    <h5 className="mb-0">Seasons</h5>
                  </div>
                  <div className="col-auto ms-auto">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0"></ol>
                    </nav>
                  </div>

                  <div className="border rounded mt-3">
                    <div className="d-md-flex align-items-center border-bottom p-1 col-12">
                      <div className="col-lg-4 px-0">
                        <button
                          className="btn  w-10 mt-4 mb-3"
                          style={{ marginRight: "1vw", cursor: "default" }}
                        >
                          <h6 className="mb-0 fw-bold active-text-color">
                            Season List
                          </h6>
                        </button>
                      </div>

                      <div className="col-lg-8 d-md-flex justify-content-end align-items-center px-0">
                        {/* <div className="col-lg-6 col-md-7 pe-0 mb-md-0 mb-3">
                                                    <CommonSearchBox
                                                        searchText={searchText}
                                                        setSearchText={setSearchText}
                                                    />
                                                </div> */}
                        <center>
                          <div style={{ padding: 20 }}>
                            <button onClick={togglePopup}>Create Season</button>
                            {isOpen && (
                              <div className="popup-overlay">
                                <div className="popup">
                                  <button
                                    className="close-button btn btn-link"
                                    onClick={togglePopup}
                                  >
                                    X
                                  </button>
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Season Name:
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Season Name"
                                      value={seasonName}
                                      onChange={(e) =>
                                        setSeasonName(
                                          e.target.value.trimStart()
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Start Date:
                                    </label>
                                    <div className="position-relative">
                                      <DatePicker
                                        value={startDate}
                                        onChange={(e) => {
                                          const inputDate = new Date(e);
                                          const year = inputDate.getFullYear();
                                          const month = String(inputDate.getMonth() + 1).padStart(2, '0');   // Month is zero-indexed, so we add 1 to get the correct month
                                          const day = String(inputDate.getDate()).padStart(2, '0');
                                          const selectedDate = `${month}/${day}/${year}`;
                                          setStartDate(selectedDate);
                                          // if (endDate && selectedDate > endDate) {
                                          //     setEndDate(selectedDate);
                                          // }
                                          setEndDate(
                                            calculateEndDate(selectedDate)
                                          );
                                        }}
                                        selectsStart
                                        placeholderText={"mm/dd/yyyy"}
                                        dateFormat="MM/dd/yyyy"
                                        className="custom-date-picker"
                                        autoComplete="off"
                                        minDate={new Date()}
                                      />
                                      <Icon
                                        className="position-absolute"
                                        style={{ top: "25%", right: "10%" }}
                                        icon="majesticons:calendar"
                                        height={17}
                                        width={17}
                                      />
                                    </div>
                                  </div>
                                  <div className="mb-3">
                                    <label className="form-label">
                                      End Date:
                                    </label>
                                    <div className="position-relative">

                                      <DatePicker
                                        value={endDate}
                                        onChange={(e) => {
                                          const inputDate = new Date(e);
                                          const year = inputDate.getFullYear();
                                          const month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Month is zero-indexed, so we add 1 to get the correct month
                                          const day = String(inputDate.getDate()).padStart(2, '0');

                                          const selectedDate = `${month}/${day}/${year}`;
                                          setStartDate(selectedDate);
                                          // if (endDate && selectedDate > endDate) {
                                          //     setEndDate(selectedDate);
                                          // }
                                          setEndDate(
                                            calculateEndDate(selectedDate)
                                          );
                                        }}
                                        selectsStart
                                        placeholderText="mm/dd/yyyy"
                                        dateFormat="MM/dd/yyyy"
                                        customInput={
                                          <CustomDatePickerInput />}
                                        autoComplete="off"
                                        minDate={startDate || getCurrentDate()}
                                        disabled
                                      />
                                      <Icon
                                        className="position-absolute"
                                        style={{ top: "25%", right: "10%" }}
                                        icon="majesticons:calendar"
                                        height={17}
                                        width={17}
                                      />
                                    </div>
                                  </div>
                                  <button
                                    className="submit-button btn btn-primary"
                                    onClick={handleSubmit}
                                    disabled={isSubmitDisabled}
                                  >
                                    {isEditOpen ? "Update" : "Submit"}
                                  </button>
                                </div>
                                <style>
                                  {`
                  .custom-date-picker {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.custom-date-picker:focus-visible {
  outline: none;
}
`}
                                </style>
                              </div>
                            )}
                            {IsDeleteOpen && (
                              <div className="popup-overlay">
                                <div className="popup">
                                  <h3 className="mb-3 delete-popup-title">
                                    Are you sure you want to delete the{" "}
                                    {seasonName}?
                                  </h3>
                                  <div className="d-flex justify-content-between">
                                    <button
                                      className="btn btn-danger"
                                      onClick={confirmDelete}
                                    >
                                      Delete
                                    </button>
                                    <button
                                      className="btn btn-secondary"
                                      onClick={closeDeletePopup}
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}

                            <style>
                              {`
          .popup-overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 9999;
          }

          
          .popup {
            position: relative;
            background: white;
            padding: 4rem;
            border-radius: 5px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
          }
          
          .close-button {
            position: absolute;
            top: 10px;
            right: 10px;
            border: none;
            background: none;
            font-size: 18px;
            cursor: pointer;
            text-decoration:none;
          }

          .delete-popup-title {
            font-size: 16px;
          }
        `}
                            </style>
                          </div>
                        </center>
                      </div>
                    </div>
                    {loading === false ? (
                      <div className="row p-0 gx-0 mt-3">
                        <div className="col p-1">
                          <div className="table-responsive">
                            {teamData.length > 0 ? (
                              <table className="table mb-0">
                                <thead className="text-nowrap">
                                  <tr className="bg-light">
                                    <th>S No.</th>
                                    <th>Season</th>
                                    <th>Update</th>
                                    <th>Delete</th>
                                    <th>Status</th>
                                  </tr>
                                </thead>

                                {teamData
                                  ?.slice(indexOfFirstPost, indexOfLastPost)
                                  .map((item, index) => {
                                    return (
                                      <>
                                        <tbody
                                          key={item._id}
                                          className="text-nowrap"
                                        >
                                          <tr>
                                            <td>
                                              {(
                                                (currentPage - 1) * 10 +
                                                index +
                                                1
                                              )
                                                .toString()
                                                .padStart(2, "0")}
                                            </td>
                                            <td>{item.values}</td>
                                            <td>
                                              <Whisper
                                                onClick={() =>
                                                  updatedPopup(item._id)
                                                }
                                                placement="bottom"
                                                trigger="hover"
                                                speaker={
                                                  <Popover>Edit</Popover>
                                                }
                                                onEnter={() =>
                                                  setDropdownVisible(true)
                                                }
                                                onExit={() =>
                                                  setDropdownVisible(false)
                                                }
                                              >
                                                <i className="edit-icon cursor-pointer">
                                                  &#9998;
                                                </i>
                                              </Whisper>
                                            </td>
                                            <td>
                                              <Whisper
                                                onClick={() =>
                                                  deletePopup(item._id)
                                                }
                                                placement="bottom"
                                                trigger="hover"
                                                speaker={
                                                  <Popover>Delete</Popover>
                                                }
                                                onEnter={() =>
                                                  setDropdownVisible(true)
                                                }
                                                onExit={() =>
                                                  setDropdownVisible(false)
                                                }
                                              >
                                                <i
                                                  className="cursor-pointer"
                                                  onClick={() =>
                                                    deletePopup(item._id)
                                                  }
                                                >
                                                  &#128465;
                                                </i>
                                              </Whisper>
                                            </td>
                                            {item.season_expired ? (
                                              <td
                                                style={{
                                                  color: "red",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                Expired
                                              </td>
                                            ) : (
                                              <td
                                                style={{
                                                  color: "green",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                Active
                                              </td>
                                            )}
                                          </tr>
                                        </tbody>
                                      </>
                                    );
                                  })}
                              </table>
                            ) : (
                              <NoData data={"No teams found"} />
                            )}
                          </div>
                        </div>
                        {teamData.length > 10 ? (
                          <>
                            <Pagination
                              itemsPerPage={itemsPerPage}
                              totalItems={teamData?.length}
                              paginate={paginate}
                              currentPage={currentPage}
                              setPrevBtn={() =>
                                setcurrentPage(() => currentPage - 1)
                              }
                              setNextBtn={() =>
                                setcurrentPage(() => currentPage + 1)
                              }
                              searchText={searchText}
                            />
                          </>
                        ) : null}
                      </div>
                    ) : (
                      <Contentloader />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Seasons;
