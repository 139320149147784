import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
// import { devBaseUrl } from "../../config/config";
import { AuthContext } from "../../context/auth";
// import { toast } from "react-toastify";
import { authPost } from "../../service/ApiClient";

const Login = () => {
  let navigate = useNavigate();
  const { setUserData, setIsAuthenticated } = useContext(AuthContext);

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState(false);
  const [passShow, setPassShow] = useState(false);

  const loginToDashboard = async () => {
    localStorage.setItem("globalSearchShow", true);
    setLoader(true);
    authPost("v1/auth/adminlogin", userName, password)
      .then((res) => {
        setLoader(false);
        setUserData(res.data.data.user);
        localStorage.setItem("userDetail", JSON.stringify(res.data.data.user));
        localStorage.setItem(
          "userToken",
          JSON.stringify(res.data.data.token_details)
        );
        localStorage.setItem("userDetail", JSON.stringify(res.data.data.user));
        localStorage.setItem(
          "userToken",
          JSON.stringify(res.data.data.token_details)
        );
        setIsAuthenticated(true);
        navigate("../dashboard", { replace: true });

        navigate(0);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  return (
    <>
      <div
        className="container-fluid position-relative w-100 h-100 login-bg"
        style={{
          backgroundColor: "#F5F7FF",
          backgroundImage: `url(${"assets/images/baseball.png"})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="row h-100 justify-content-center overflow-hidden">
          <div
            className="col-sm-6 col-lg-4 col-xl-3 align-self-center d-flex flex-column p-sm-5 p-4 login"
            style={{ backgroundColor: "#F5F7FF", width: "33%" }}
          >
            <img
              src="assets/images/logo.png"
              // className="mx-auto img-fluid"
              style={{maxWidth: "50%", maxHeight: "100%", alignSelf: "center",}}            
              alt=""
            />
            <form onSubmit={(e) => loginToDashboard(e.preventDefault())}>
              <div className="border rounded mt-4 p-sm-5 p-4">
                <h3>Login</h3>
                <p>Please enter the email and password</p>
                <div className="mb-3 mt-4 d-flex flex-row">
                  {/* <label className="form-label">Email</label> */}
                  <input
                    type="email"
                    className="form-control"
                    required
                    onChange={(e) => setUserName(e.target.value)}
                    placeholder="Email"
                  />

                  {/* <span style={{ marginLeft: "-24px", alignSelf: "center" }}>
                    <i className="fal fa-paper-plane"></i>
                  </span> */}
                </div>
                <div className="mb-3 d-flex flex-row">
                  {/* <label className="form-label">Password</label> */}
                  <input
                    type={passShow === true ? "text" : "password"}
                    className="form-control"
                    required
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                  />
                  <span
                    style={{ marginLeft: "-24px", alignSelf: "center" }}
                    onClick={() => setPassShow(!passShow)}
                  >
                    {passShow === true ? (
                      <i className="fal fa-eye-slash"></i>
                    ) : (
                      <i className="fal fa-eye"></i>
                    )}
                  </span>
                </div>

                <button
                  className="btn btn-primary w-100 mt-4 mb-3"
                  type="submit"
                  disabled={loader === true ? true : false}
                >
                  {loader === true ? (
                    <>
                      <span>Please wait...</span>
                      <img
                        src="assets/images/baseballgif.gif"
                        className="mx-auto img-fluid"
                        alt=""
                        width={27}
                        height={27}
                      />
                    </>
                  ) : (
                    "Login"
                  )}
                </button>

                <span
                  onClick={() => navigate("/forgotpassword")}
                  className="cursor-pointer text-primary text-decoration-underline"
                >
                  Forgot Password?
                </span>
              </div>
            </form>

            <p className="small mt-3 text-center">
              © 2023 EC Frazier & Associates Inc, Inc. All rights reserved
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
