import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import AuthRoutes from "./routes/Auth";
import UnAuth from "./routes/unAuth";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

function App() {
  let UserToken = JSON.parse(localStorage.getItem("userToken"))?.access_token;

  const navigate = useNavigate();

  useEffect(() => {
    if (!UserToken) {
      navigate("/");
    }
  }, []);

  return (
    <React.Fragment>
      <ToastContainer />
      <UnAuth></UnAuth>
      {UserToken ? <AuthRoutes></AuthRoutes> : null}
    </React.Fragment>
  );
}

export default App;
