import React from "react";
import { NavLink } from "react-router-dom";

function Error() {
  return (
    <div
      className="container-fluid position-relative w-100 h-100"
      style={{
        backgroundColor: "#F5F7FF",
        backgroundImage: `url(${"/assets/images/baseball.png"})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className="row h-100 justify-content-center overflow-hidden">
        <div
          className="col-sm-6 col-lg-4 col-xl-3 align-self-center d-flex flex-column p-5"
          style={{ backgroundColor: "#F5F7FF", width: "50%" }}
        >
          <img
            src="/assets/images/404.jpg"
            className="mx-auto img-fluid"
            alt=""
          />

          <p className="large mt-3 text-center">
            It seems we can't find what you're looking for. <br />
            Go back to <br />
            <button className="btn btn-primary  w-10 mt-4 mb-3">
              <NavLink className=" text-white" to="/dashboard">
                Home
              </NavLink>
            </button>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Error;
