import React from "react";
import { useNavigate } from "react-router-dom";

const MobileNav = () => {
  let navigate = useNavigate();

  return (
    <nav class="navbar navbar-expand-lg navbar-light bg-light border-bottom d-flex d-md-none">
      <img src="../assets/images/logo.png" className="w-25" alt="" />
      <div class="d-flex">
        <div
          className="bg-light d-flex p-2 cursor-pointer"
          id="profile-dropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img
            src="../assets/icons/user.png"
            className=" align-self-center"
            alt=""
            style={{ width: "24px" }}
          />
        </div>
        <ul
          className="dropdown-menu dropdown-menu-end mt-1 pl-2 pr-2"
          aria-labelledby="profile-dropdown"
        >
          <li>
            <button
              className="dropdown-item border-bottom"
              onClick={() => navigate("/editprofile")}
            >
              <span className="pr-2" style={{ marginLeft: "-9px" }}>
                <img
                  src="../assets/icons/user.png"
                  className=" align-self-center me-2"
                  alt=""
                  style={{ width: "16px", minWidth: "17px" }}
                />
              </span>
              Edit Profile
            </button>
          </li>
          <li>
            <button
              className="dropdown-item border-bottom"
              onClick={() => navigate("/changepassword")}
            >
              <span className="pr-2" style={{ marginLeft: "-9px" }}>
                <img
                  src="../assets/icons/password.png"
                  className=" align-self-center me-2"
                  alt=""
                  style={{ width: "16px", minWidth: "17px" }}
                />
              </span>
              Change Password
            </button>
          </li>
          {/* <li>
            <button
              className="dropdown-item border-bottom"
              onClick={() => navigate("/notificationpreference")}
            >
              <span className="pr-2" style={{ marginLeft: "-9px" }}>
                <img
                  src="../assets/icons/ringing.png"
                  className=" align-self-center me-2"
                  alt=""
                  style={{ width: "16px", minWidth: "17px" }}
                />
              </span>
              Notification Preferences
            </button>
          </li> */}
          <li>
            <button
              className="dropdown-item"
              //   onClick={() => setShowModal(true)}
            >
              <span className="pr-2" style={{ marginLeft: "-9px" }}>
                <img
                  src="../assets/icons/logout.png"
                  className=" align-self-center me-2"
                  alt=""
                  style={{ width: "16px", minWidth: "17px" }}
                />
              </span>
              Logout
            </button>
          </li>
        </ul>
        <button
          class="navbar-toggler border-0 shadow-none"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="far h4 fa-align-left cursor-pointer mb-0 text-black"></span>
        </button>
      </div>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto mt-3">
          <li class="nav-item active">
            <a class="nav-link d-flex" href="/dashboard">
              <img
                src="../assets/icons/tachometer.png"
                className="align-self-center  me-2"
                alt=""
                style={{ width: "16px" }}
                onClick={() => localStorage.setItem("globalSearchShow", true)}
              />
              <span
                className="align-self-center text"
                onClick={() => localStorage.setItem("globalSearchShow", true)}
              >
                Dashboard
              </span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link d-flex" href="/usermetrics">
              <img
                src="../assets/icons/user.png"
                className="align-self-center me-2"
                alt=""
                style={{ width: "16px" }}
                onClick={() => localStorage.setItem("globalSearchShow", false)}
              />
              <span
                className="align-self-center"
                onClick={() => localStorage.setItem("globalSearchShow", false)}
              >
                Users List
              </span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link d-flex" href="/teammetrics">
              <img
                src="../assets/icons/group.png"
                className="align-self-center me-2"
                alt=""
                style={{ width: "16px" }}
                onClick={() => localStorage.setItem("globalSearchShow", false)}
              />
              <span
                className="align-self-center"
                onClick={() => localStorage.setItem("globalSearchShow", false)}
              >
                Teams List
              </span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link d-flex" href="/events">
              <img
                src="../assets/icons/calendar.png"
                className=" align-self-center  me-2"
                alt=""
                style={{ width: "16px" }}
                onClick={() => localStorage.setItem("globalSearchShow", false)}
              />
              <span
                className="align-self-center"
                onClick={() => localStorage.setItem("globalSearchShow", false)}
              >
                Events List
              </span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link d-flex" href="/pricing">
              <img
                src="../assets/icons/dollarIcon.png"
                className=" align-self-center  me-2"
                alt=""
                style={{ width: "16px" }}
                onClick={() => localStorage.setItem("globalSearchShow", false)}
              />
              <span
                className="align-self-center"
                onClick={() => localStorage.setItem("globalSearchShow", false)}
              >
                Subscription Plans
              </span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link d-flex" href="/setting">
              <img
                src="../assets/icons/development.png"
                className=" align-self-center  me-2"
                alt=""
                style={{ width: "16px" }}
                onClick={() => localStorage.setItem("globalSearchShow", false)}
              />
              <span
                className="align-self-center"
                onClick={() => localStorage.setItem("globalSearchShow", false)}
              >
                Settings
              </span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link d-flex" href="#.">
              <img
                src="../assets/icons/logout.png"
                className="nav-icon align-self-center  me-2"
                alt=""
                style={{ width: "16px", minWidth: "1.5vw" }}
              />
              <span
                className="align-self-center"
                style={{ fontSize: "14px", color: "#000" }}
              >
                Logout
              </span>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default MobileNav;
