import React from "react";

const ActiveDeacticeColor = () => {
  return (
    <>
      <div
        style={{
          position: "absolute",
          marginTop: "1vw",
        }}
      >
        <input
          type="checkbox"
          checked={true}
          style={{
            marginLeft: 2,
            marginRight: 5,
          }}
          readOnly={true}
        />
        Activated
        <input
          type="checkbox"
          checked={false}
          style={{
            marginLeft: 20,
            marginRight: 5,
          }}
          readOnly={true}
        />
        Deactivated
      </div>
    </>
  );
};

export default ActiveDeacticeColor;
