import React, { useEffect, useState } from "react";

import Header from "../../components/Header";
import GlobalSearchTable from "../../components/GlobalSearchTable";
import CommonSearchBox from "../../components/CommonSearchBox";
import { Link } from "react-router-dom";
import ContentLoader from "react-content-loader";
import Profile from "../../components/Profile";
import { CChart } from "@coreui/react-chartjs";

import { getAPI, postGlobalSearch } from "../../service/ApiClient";
import MobileNav from "../../components/MobileNav";
import Footer from "../../components/Footer";
import Contentloader from "../../components/ContentLoader";
const Dashboard = () => {
  const [invited, setInvited] = useState(false);
  const [signedUp, setSignedUp] = useState(false);
  const [allUsers, setAllusers] = useState(true);

  const [loading, setLoading] = useState(true);

  const [usermetricCount, setUserMetricCount] = useState([]);
  const [invitedUser, setInvitedUsers] = useState([]);
  const [signedUpUser, setSignedUpUsers] = useState([]);

  const [eventCount, setEventCount] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [roleMetricsList, setRoleMetrics] = useState({});
  const [teamMetric, setTeamMetrics] = useState({});
  const [liveEvents, setLiveEvent] = useState([]);
  const [recenteventcount, setRecentEventCount] = useState(0);
  const [upcomingeventcount, setUpcomingEventCount] = useState(0);
  const [totalEventCount, setTotalEventCount] = useState(0);
  const [liveEventCount, setLiveEventCount] = useState(0);
  const [staffCount, setStaffCount] = useState(0);
  const [coachCount, setCoachCount] = useState(0);
  const [fanCount, setFanCount] = useState(0);
  const [playerCount, setPlayerCount] = useState(0);

  useEffect(() => {
    userMetricsList();
    eventsMetricList();

    invitedUsers();
    signedUpUsers();
    roleMetrics();
    teamMetrics();
    eventList();
    if (searchText !== "") {
      globalSearch();
    }
    if (searchText.length === 0 || searchText.length < 0) {
      setSearchResult([]);
    }
  }, [searchText]);

  const globalSearch = () => {
    postGlobalSearch("v1/user/admin/global-search", searchText)
      .then((response) => {
        setSearchResult(response.data.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const invitedUsers = () => {
    getAPI("v1/user/admin/user-matrics?type=PENDING")
      .then((response) => {
        setInvitedUsers(response.data.data);
      })
      .catch((err) => console.log(err));
  };

  const signedUpUsers = () => {
    getAPI("v1/user/admin/user-matrics?type=ACCEPTED")
      .then((response) => setSignedUpUsers(response.data.data))
      .catch((err) => console.log(err));
  };

  const teamMetrics = () => {
    getAPI("v1/user/admin/team-matrics")
      .then((response) => setTeamMetrics(response.data.data))
      .catch((err) => console.log(err));
  };

  const roleMetrics = () => {
    getAPI("v1/user/admin/role-matrics")
      .then((response) => setRoleMetrics(response.data.data))
      .catch((err) => console.log(err));
  };

  const userMetricsList = () => {
    getAPI("v1/user/admin/user-matrics")
      .then((response) => {
        setUserMetricCount(response.data.data);
        setStaffCount(
          response.data.data.filter((el) => {
            if (el.role === "STAFF") {
              return el.count;
            } else {
              return 0;
            }
          })[0].count
        );
        setFanCount(
          response.data.data.filter((el) => {
            if (el.role === "FAN") {
              return el.count;
            } else {
              return 0;
            }
          })[0].count
        );
        setCoachCount(
          response.data.data.filter((el) => {
            if (el.role === "COACH") {
              return el.count;
            } else {
              return 0;
            }
          })[0].count
        );
        setPlayerCount(
          response.data.data.filter((el) => {
            if (el.role === "PLAYER") {
              return el.count;
            } else {
              return 0;
            }
          })[0].count
        );
      })
      .catch((err) => console.log(err));
  };

  const eventsMetricList = () => {
    getAPI("v1/user/admin/events-matrics")
      .then((response) => {
        setEventCount(response.data.data);
      })
      .catch((err) => console.log(err));
  };
  const eventList = () => {
    getAPI("v1/user/admin/events-tabs")
      .then((response) => {
        setLiveEvent(response.data.data.live);
        setRecentEventCount(response.data.data.recent.length);
        setUpcomingEventCount(response.data.data.upcoming.length);
        setTotalEventCount(
          response.data.data.live.length +
            response.data.data.recent.length +
            response.data.data.upcoming.length
        );
        setLiveEventCount(response.data.data.live.length);

        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  liveEvents.sort(
    (b, a) =>
      new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
  );

  return (
    <div className="container-fluid position-relative w-100 h-100">
      <div className="row h-100">
        <div
          className="col-sm-2 px-0 h-100 d-md-block d-none"
          style={{ width: "15%" }}
        >
          <Header />
        </div>
        <MobileNav />
        <div className="col-12 col-md-10 px-0 h-100">
          <div className="w-100 h-100 d-flex flex-column pb-3">
            <div className="container-fluid py-md-4 py-2">
              <div className="row">
                <div className="col-lg-4 col-md-6 align-self-center">
                  <CommonSearchBox
                    searchText={searchText}
                    setSearchText={setSearchText}
                  />
                </div>
                <Profile />
              </div>
            </div>

            <div>
              {searchResult.length > 0 && searchText.length > 0 ? (
                <GlobalSearchTable
                  searchResult={searchText.length > 0 ? searchResult : null}
                />
              ) : null}
            </div>
            <div className="container-fluid py-3 border-top border-bottom">
              <div className="row">
                <div className="col">
                  <h5 className="mb-0">Dashboard</h5>
                </div>
                <div className="col-auto ms-auto">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mb-0"></ol>
                  </nav>
                </div>
              </div>
            </div>
            <div className="container-fluid mt-3 overflow-auto flex-fill">
              <div className="row">
                {!loading ? (
                  <div>
                    <div className="d-sm-flex justify-content-between align-items-center border-bottom p-3">
                      <h6 className="mb-0 fw-bold">Events</h6>
                      <div className="d-sm-flex align-items-center mt-sm-0 mt-3">
                        <p className="small mb-0 me-sm-3">
                          <i className="fa fa-circle text-danger"></i> Live:{" "}
                          {liveEvents.length > 0 ? (
                            <a href=".#">
                              {liveEvents[0]?.playing_team} vs{" "}
                              {liveEvents[0]?.opponent_team}
                            </a>
                          ) : null}
                          {liveEvents.length > 1 ? (
                            <a href=".#">
                              |{liveEvents[1]?.playing_team} vs{" "}
                              {liveEvents[1]?.opponent_team}
                            </a>
                          ) : null}
                        </p>
                        <Link className="small" to={"/events"}>
                          View all
                        </Link>
                      </div>
                    </div>

                    <div className="row p-3 gx-3">
                      <div className="col-sm-3 mb-3">
                        <div
                          className="bg-primary text-white rounded p-4 d-flex justify-content-between"
                          style={{
                            background:
                              "linear-gradient(to right bottom, #0ED1A6, #2C7400)",
                          }}
                        >
                          <div>
                            {eventCount
                              .filter((items) => items.title === "Today")
                              .map((items, i) => {
                                return <h1 key={i}>{items.count}</h1>;
                              })}
                            <h5 className="mb-0">Today</h5>
                          </div>
                          <div className="align-self-center">
                            <h1 className="mb-0">
                              <i className="far fa-calendar-day"></i>
                            </h1>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3 mb-3">
                        <div
                          className="bg-light text-white  rounded p-4 d-flex justify-content-between"
                          style={{
                            background:
                              "linear-gradient(to right bottom, #F281AF, #A72E6E)",
                          }}
                        >
                          <div>
                            <h1>
                              {eventCount
                                .filter((items) => items.title === "Tomorrow")
                                .map((items, i) => {
                                  return <h1 key={i}>{items.count}</h1>;
                                })}
                            </h1>
                            <h5 className="mb-0">Tomorrow</h5>
                          </div>
                          <div className="align-self-center">
                            <h1 className="mb-0">
                              <i className="far fa-calendar-week"></i>
                            </h1>
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-3 mb-3">
                        <div
                          className="bg-primary text-white rounded p-4 d-flex justify-content-between"
                          style={{
                            background:
                              "linear-gradient(to right bottom, #FFB860, #C56D00)",
                          }}
                        >
                          <div>
                            {eventCount
                              .filter((items) => items.title === "Today")
                              .map((items, i) => {
                                let result =
                                  (recenteventcount * 100) / totalEventCount;
                                if (isNaN(result)) {
                                  result = 0;
                                }
                                return <h1 key={i}>{result.toFixed(0)}%</h1>;
                              })}
                            <h5 className="mb-0">Completed</h5>
                          </div>
                          <div className="align-self-center">
                            <h1 className="mb-0">
                              <i className="fal fa-check"></i>
                            </h1>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div
                          className="bg-primary text-white rounded p-4 d-flex justify-content-between"
                          style={{
                            background:
                              "linear-gradient(to right bottom, #B481FF, #5C06DA)",
                          }}
                        >
                          <div>
                            {eventCount
                              .filter((items) => items.title === "Today")
                              .map((items, i) => {
                                let result =
                                  ((upcomingeventcount + liveEventCount) *
                                    100) /
                                  totalEventCount;
                                if (isNaN(result)) {
                                  result = 0;
                                }
                                return <h1 key={i}>{result.toFixed(0)}%</h1>;
                              })}
                            <h5 className="mb-0">Scheduled</h5>
                          </div>
                          <div className="align-self-center">
                            <h1 className="mb-0">
                              {/* <i className="far fa-calendar-day"></i> */}
                              <i className="fal fa-hourglass-start"></i>
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <Contentloader />
                )}
                <div className="col-sm-6">
                  {/* graph section start here */}

                  {!loading ? (
                    <div className="border rounded mb-3">
                      <div className="d-flex justify-content-between align-items-center border-bottom p-3">
                        <h6 className="mb-0 fw-bold">Events Graph</h6>
                      </div>

                      <div className="row p-3 gx-3">
                        <CChart
                          type="bar"
                          options={{
                            plugins: {
                              legend: {
                                display: false,
                              },
                            },
                          }}
                          data={{
                            labels: [
                              `Live ${liveEventCount}`,
                              `Recent ${recenteventcount}`,
                              `Upcoming ${upcomingeventcount}`,
                            ],
                            datasets: [
                              {
                                label: ["Events"],

                                backgroundColor: [
                                  "#1E9F4D",
                                  "#E2922F",

                                  "#E573A4",
                                ],
                                data: [
                                  liveEventCount,
                                  recenteventcount,
                                  upcomingeventcount,
                                ],
                              },
                            ],
                          }}
                          labels="events"
                        />
                      </div>
                    </div>
                  ) : (
                    <Contentloader />
                  )}

                  {/* Event section start */}

                  {/* Event section end */}
                  {!loading ? (
                    <div className="border rounded">
                      <div className="d-flex justify-content-between align-items-center border-bottom p-3">
                        <h6 className="mb-0 fw-bold">Team Metrics</h6>
                        <div className="d-flex align-items-center">
                          <h6 className="fw-bold">
                            Total Teams:{" "}
                            {teamMetric["Middle schools"] +
                              teamMetric["High schools"] +
                              teamMetric.Elementary +
                              teamMetric.Schools +
                              teamMetric["Local Leagues"] +
                              teamMetric.Travel}
                          </h6>
                        </div>
                      </div>

                      <div className="row p-0 gx-0">
                        <div className="col p-4">
                          <div className="table-responsive">
                            <table className="table mb-0">
                              <thead>
                                <tr className="bg-light">
                                  <th>Type</th>
                                  <th>No. of teams</th>
                                </tr>
                              </thead>
                              <tbody style={{ height: "282px" }}>
                                <tr>
                                  <td>Local League</td>
                                  <td>{teamMetric["Local Leagues"]}</td>
                                </tr>
                                <tr>
                                  <td>Travel</td>
                                  <td>{teamMetric.Travel}</td>
                                </tr>
                                <tr>
                                  <td>Schools</td>
                                  <td>
                                    {(
                                      teamMetric["Middle schools"] +
                                      teamMetric["High schools"] +
                                      teamMetric.Elementary +
                                      teamMetric.Schools
                                    ).toString()}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="pl-5">- Elementary</td>
                                  <td>{teamMetric.Elementary}</td>
                                </tr>
                                <tr>
                                  <td className="pl-5">- Middle School</td>
                                  <td>{teamMetric["Middle schools"]}</td>
                                </tr>
                                <tr>
                                  <td className="pl-5">- High School</td>
                                  <td>{teamMetric["High schools"]}</td>
                                </tr>
                                <tr>
                                  <td className="pl-5">- College</td>
                                  <td>{teamMetric.Schools}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          {/* <div className="p-3">
                          <a href=".#">See more...</a>
                        </div> */}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <Contentloader />
                  )}
                </div>

                <div className="col-sm-6">
                  {!loading ? (
                    <div className="border rounded mb-3">
                      <div className="d-flex justify-content-between align-items-center border-bottom p-3">
                        <h6 className="mb-0 fw-bold">Users Graph</h6>
                      </div>

                      <div className="row p-3 gx-3">
                        <CChart
                          type="bar"
                          options={{
                            plugins: {
                              legend: {
                                display: false,
                              },
                            },
                          }}
                          data={{
                            labels: [
                              `Staff ${staffCount}`,
                              `Coach ${coachCount}`,
                              `Player ${playerCount}`,
                              `Fan ${fanCount}`,
                            ],
                            datasets: [
                              {
                                label: "Users",
                                backgroundColor: [
                                  "#f87979",
                                  "#8843EC",
                                  "#B250FF",
                                  "#38A6DA",
                                ],
                                data: [
                                  staffCount,
                                  coachCount,
                                  playerCount,
                                  fanCount,
                                ],
                              },
                            ],
                          }}
                          labels="user"
                        />
                      </div>
                    </div>
                  ) : (
                    <Contentloader />
                  )}
                  {/* use metrics start */}
                  {!loading ? (
                    <div className="border rounded mb-3">
                      <div className="d-sm-flex justify-content-between align-items-center border-bottom p-3">
                        <h6 className="mb-0 fw-bold">User Metrics</h6>
                        <ul
                          className="nav nav-pills mt-sm-0 mt-3"
                          id="pills-tab"
                          role="tablist"
                        >
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link px-3 py-1 active btn-sm"
                              id="pills-all-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-all"
                              type="button"
                              role="tab"
                              aria-controls="pills-all"
                              aria-selected="true"
                              onClick={() => {
                                setInvited(false);
                                setSignedUp(false);
                                setAllusers(true);
                              }}
                            >
                              All
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link px-3 py-1 btn-sm"
                              id="pills-invited-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-invited"
                              type="button"
                              role="tab"
                              aria-controls="pills-invited"
                              aria-selected="false"
                              onClick={() => {
                                setInvited(true);
                                setSignedUp(false);
                                setAllusers(false);
                              }}
                            >
                              Invited
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link px-3 py-1 btn-sm"
                              id="pills-signedup-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-signedup"
                              type="button"
                              role="tab"
                              aria-controls="pills-signedup"
                              aria-selected="false"
                              onClick={() => {
                                setSignedUp(true);
                                setInvited(false);
                                setAllusers(false);
                              }}
                            >
                              Signed Up
                            </button>
                          </li>
                        </ul>
                      </div>

                      {invited === false &&
                      signedUp === false &&
                      allUsers === true ? (
                        <div className="tab-content" id="pills-tabContent">
                          <div
                            className="tab-pane fade show active"
                            id="pills-all"
                            role="tabpanel"
                            aria-labelledby="pills-all-tab"
                            tabIndex="0"
                          >
                            <div className="row p-3 gx-3">
                              <div className="col">
                                {usermetricCount
                                  .filter((item) => item.role === "STAFF")
                                  .map((items, i) => {
                                    return (
                                      <div
                                        key={i}
                                        className="bg-light mb-2 p-3 d-flex justify-content-between align-items-center"
                                      >
                                        <div>
                                          <p className="mb-0">
                                            <i className="fal fa-id-badge"></i>{" "}
                                            Staff
                                          </p>
                                        </div>
                                        <div>
                                          <p className="mb-0">{items.count}</p>
                                        </div>
                                      </div>
                                    );
                                  })}
                                {usermetricCount
                                  .filter((item) => item.role === "COACH")
                                  .map((items, i) => {
                                    return (
                                      <div
                                        key={i}
                                        className="bg-light mb-2 p-3 d-flex justify-content-between align-items-center"
                                      >
                                        <div>
                                          <p className="mb-0">
                                            <i className="fal fa-users-crown"></i>{" "}
                                            Coaches
                                          </p>
                                        </div>
                                        <div>
                                          <p className="mb-0">{items.count}</p>
                                        </div>
                                      </div>
                                    );
                                  })}
                                {usermetricCount
                                  .filter((item) => item.role === "PLAYER")
                                  .map((items, i) => {
                                    return (
                                      <div
                                        key={i}
                                        className="bg-light mb-2 p-3 d-flex justify-content-between align-items-center"
                                      >
                                        <div>
                                          <p className="mb-0">
                                            <i className="fal fa-baseball"></i>{" "}
                                            Players
                                          </p>
                                        </div>
                                        <div>
                                          <p className="mb-0">{items.count}</p>
                                        </div>
                                      </div>
                                    );
                                  })}
                                {usermetricCount
                                  .filter((item) => item.role === "FAN")
                                  .map((items, i) => {
                                    return (
                                      <div
                                        key={i}
                                        className="bg-light mb-2 p-3 d-flex justify-content-between align-items-center"
                                      >
                                        <div>
                                          <p className="mb-0">
                                            <i className="fal fa-users"></i>{" "}
                                            Fans
                                          </p>
                                        </div>
                                        <div>
                                          <p className="mb-0">{items.count}</p>
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : invited === true &&
                        allUsers === false &&
                        signedUp === false ? (
                        <div className="tab-content" id="pills-tabContent">
                          <div
                            className="tab-pane fade show active"
                            id="pills-all"
                            role="tabpanel"
                            aria-labelledby="pills-all-tab"
                            tabIndex="0"
                          >
                            <div className="row p-3 gx-3">
                              <div className="col">
                                {invitedUser
                                  .filter((item) => item.role === "STAFF")
                                  .map((items, i) => {
                                    return (
                                      <div
                                        key={i}
                                        className="bg-light mb-2 p-3 d-flex justify-content-between align-items-center"
                                      >
                                        <div>
                                          <p className="mb-0">
                                            <i className="fal fa-id-badge"></i>{" "}
                                            Staff
                                          </p>
                                        </div>
                                        <div>
                                          <p className="mb-0">{items.count}</p>
                                        </div>
                                      </div>
                                    );
                                  })}
                                {invitedUser
                                  .filter((item) => item.role === "COACH")
                                  .map((items, i) => {
                                    return (
                                      <div
                                        key={i}
                                        className="bg-light mb-2 p-3 d-flex justify-content-between align-items-center"
                                      >
                                        <div>
                                          <p className="mb-0">
                                            <i className="fal fa-users-crown"></i>{" "}
                                            Coaches
                                          </p>
                                        </div>
                                        <div>
                                          <p className="mb-0">{items.count}</p>
                                        </div>
                                      </div>
                                    );
                                  })}
                                {invitedUser
                                  .filter((item) => item.role === "PLAYER")
                                  .map((items, i) => {
                                    return (
                                      <div
                                        key={i}
                                        className="bg-light mb-2 p-3 d-flex justify-content-between align-items-center"
                                      >
                                        <div>
                                          <p className="mb-0">
                                            <i className="fal fa-baseball"></i>{" "}
                                            Players
                                          </p>
                                        </div>
                                        <div>
                                          <p className="mb-0">{items.count}</p>
                                        </div>
                                      </div>
                                    );
                                  })}
                                {invitedUser
                                  .filter((item) => item.role === "FAN")
                                  .map((items, i) => {
                                    return (
                                      <div
                                        key={i}
                                        className="bg-light mb-2 p-3 d-flex justify-content-between align-items-center"
                                      >
                                        <div>
                                          <p className="mb-0">
                                            <i className="fal fa-users"></i>{" "}
                                            Fans
                                          </p>
                                        </div>
                                        <div>
                                          <p className="mb-0">{items.count}</p>
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="tab-content" id="pills-tabContent">
                          <div
                            className="tab-pane fade show active"
                            id="pills-all"
                            role="tabpanel"
                            aria-labelledby="pills-all-tab"
                            tabIndex="0"
                          >
                            <div className="row p-3 gx-3">
                              <div className="col">
                                {signedUpUser
                                  .filter((item) => item.role === "STAFF")
                                  .map((items, i) => {
                                    return (
                                      <div
                                        key={i}
                                        className="bg-light mb-2 p-3 d-flex justify-content-between align-items-center"
                                      >
                                        <div>
                                          <p className="mb-0">
                                            <i className="fal fa-id-badge"></i>{" "}
                                            Staff
                                          </p>
                                        </div>
                                        <div>
                                          <p className="mb-0">{items.count}</p>
                                        </div>
                                      </div>
                                    );
                                  })}
                                {signedUpUser
                                  .filter((item) => item.role === "COACH")
                                  .map((items, i) => {
                                    return (
                                      <div
                                        key={i}
                                        className="bg-light mb-2 p-3 d-flex justify-content-between align-items-center"
                                      >
                                        <div>
                                          <p className="mb-0">
                                            <i className="fal fa-users-crown"></i>{" "}
                                            Coaches
                                          </p>
                                        </div>
                                        <div>
                                          <p className="mb-0">{items.count}</p>
                                        </div>
                                      </div>
                                    );
                                  })}
                                {signedUpUser
                                  .filter((item) => item.role === "PLAYER")
                                  .map((items, i) => {
                                    return (
                                      <div
                                        key={i}
                                        className="bg-light mb-2 p-3 d-flex justify-content-between align-items-center"
                                      >
                                        <div>
                                          <p className="mb-0">
                                            <i className="fal fa-baseball"></i>{" "}
                                            Players
                                          </p>
                                        </div>
                                        <div>
                                          <p className="mb-0">{items.count}</p>
                                        </div>
                                      </div>
                                    );
                                  })}
                                {signedUpUser
                                  .filter((item) => item.role === "FAN")
                                  .map((items, i) => {
                                    return (
                                      <div
                                        key={i}
                                        className="bg-light mb-2 p-3 d-flex justify-content-between align-items-center"
                                      >
                                        <div>
                                          <p className="mb-0">
                                            <i className="fal fa-users"></i>{" "}
                                            Fans
                                          </p>
                                        </div>
                                        <div>
                                          <p className="mb-0">{items.count}</p>
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <Contentloader />
                  )}

                  {/* end */}
                  {!loading ? (
                    <div className="border rounded mb-3">
                      <div className="d-flex justify-content-between align-items-center border-bottom p-3">
                        <h6 className="mb-0 fw-bold">Role Metrics</h6>
                      </div>

                      <div className="row p-3 gx-3">
                        <div className="col">
                          <div className="bg-light mb-2 p-3 d-flex justify-content-between align-items-center">
                            <div>
                              <p className="mb-0">
                                <i className="fal fa-video"></i> Video Streamer
                              </p>
                            </div>
                            <div>
                              <p className="mb-0">
                                {roleMetricsList.videoStreamer}
                              </p>
                            </div>
                          </div>

                          <div className="bg-light mb-2 p-3 d-flex justify-content-between align-items-center">
                            <div>
                              <p className="mb-0">
                                <i className="fal fa-chalkboard"></i> Scorer
                              </p>
                            </div>
                            <div>
                              <p className="mb-0">{roleMetricsList.scorer}</p>
                            </div>
                          </div>
                          <div className="bg-light mb-2 p-3 d-flex justify-content-between align-items-center">
                            <div>
                              <p className="mb-0">
                                <i className="fal fa-baseball"></i> PC Keeper
                              </p>
                            </div>
                            <div>
                              <p className="mb-0">
                                {roleMetricsList.pitchCountKeeper}
                              </p>
                            </div>
                          </div>
                          <div className="bg-light mb-2 p-3 d-flex justify-content-between align-items-center">
                            <div>
                              <p className="mb-0">
                                <i className="fal fa-user"></i> Keeper
                              </p>
                            </div>
                            <div>
                              <p className="mb-0">
                                {roleMetricsList.battingInfoKeeper}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <Contentloader />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Dashboard;
