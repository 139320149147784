import React from "react";
import { Dropdown, IconButton, Popover, Whisper } from "rsuite";
import PageIcon from "@rsuite/icons/Page";
import { Icon } from "@iconify/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CommonSearchBox from "../../components/CommonSearchBox";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import { CSVLink } from "react-csv";

const PricingHeader = ({
  typeOfTabs,
  fromDateValue,
  fromDateOnChange,
  toDateValue,
  toDateOnChange,
  searchOnClick,
  setSearchText,
  searchText,
  onClickClearButton,
  onClickPDFButton,
  dataCSV,
}) => {
  const renderMenu = ({ left, top, className }, ref) => {
    return (
      <Popover ref={ref} className={className} style={{ left, top }} full>
        <Dropdown.Menu title="Create new file">
          <Dropdown.Item icon={<PageIcon />} eventKey={1}>
            <CSVLink
              data={dataCSV}
              target="_blank"
              filename={"Transactions"}
            >
              <button>CSV format ⬇</button>
            </CSVLink>
          </Dropdown.Item>
          <Dropdown.Item icon={<FileDownloadIcon />} eventKey={2}>
            <button onClick={onClickPDFButton}>PDF format ⬇</button>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Popover>
    );
  };
  return (
    <>
      {typeOfTabs === "transaction" ? (
        <>
          <div className="d-flex flex-md-row flex-column justify-content-center align-items-center ">
            <div className="d-xl-flex d-none mt-auto">
              <CommonSearchBox
                searchText={searchText}
                setSearchText={setSearchText}
              />
            </div>
            <div className="mx-1">
              <span>From Date: </span>
              <div className="position-relative">
                <DatePicker
                  id="fromDate"
                  selected={fromDateValue}
                  onChange={fromDateOnChange}
                  selectsStart
                  placeholderText={"mm/dd/yyyy"}
                  dateFormat="MM/dd/yyyy"
                  className="custom-date-picker"
                  autoComplete="off"
                  maxDate={toDateValue || null}
                />
                <Icon
                  className="position-absolute"
                  style={{ top: "25%", right: "10%" }}
                  icon="majesticons:calendar"
                  height={17}
                  width={17}
                />
              </div>
            </div>
            <div className="mx-1 mt-md-0 mt-3">
              <span>To Date: </span>
              <div className="position-relative">
                <DatePicker
                  id="fromDate"
                  selected={toDateValue}
                  onChange={toDateOnChange}
                  selectsStart
                  placeholderText={"mm/dd/yyyy"}
                  dateFormat="MM/dd/yyyy"
                  className="custom-date-picker"
                  autoComplete="off"
                  minDate={fromDateValue || null}
                />
                <Icon
                  className="position-absolute"
                  style={{ top: "25%", right: "10%" }}
                  icon="majesticons:calendar"
                  height={17}
                  width={17}
                />
              </div>
              <style>
                {`
                  .custom-date-picker {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.custom-date-picker:focus-visible {
  outline: none;
}
`}
              </style>
            </div>
            <button
              className="submit-button btn btn-primary mx-1 mt-3 align-self-md-end"
              onClick={searchOnClick}
              disabled={fromDateValue && toDateValue ? false : true}
            >
              <Icon icon="wpf:search" color="white" width="20" height="20" />
            </button>
            <button
              className="submit-button btn btn-primary mx-1 mt-3 align-self-md-end"
              onClick={onClickClearButton}
              disabled={fromDateValue || toDateValue ? false : true}
            >
              <h6 className="fw-bold" style={{ display: "contents" }}>
                Clear
              </h6>
            </button>
            <div className="mt-3 mx-2 align-self-md-end">
              <Whisper
                placement="bottomStart"
                trigger="click"
                speaker={renderMenu}
              >
                <IconButton
                  appearance="primary"
                  color="blue"
                  icon={<PageIcon />}
                >
                  Export report
                </IconButton>
              </Whisper>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default PricingHeader;
