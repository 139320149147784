import React from "react";
import { Navigate } from "react-router-dom";

function PrivateRoute({ children }) {
  let isAuthenticated =
    localStorage.getItem("userToken") == null ? false : true;

  if (isAuthenticated) {
    return children;
  }

  return <Navigate to="/" />;
}

export default PrivateRoute;
