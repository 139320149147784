import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { postSettingApi } from "../service/ApiClient";
import { getAPI } from "../service/ApiClient";
import Contentloader from "./ContentLoader";

const EventInputBox = ({ item }) => {
  const [playerValue, setPlayerValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);

  async function fetchMyAPI() {
    setLoading1(true);
    try {
      const response = await getAPI("v1/user/admin/eventcreation-settings");
      if (response.data.data) {
        if (item.identity in response.data.data) {
          setPlayerValue(response.data.data[item.identity].toString());
          setLoading1(false);
        }
      }
    } catch (err) {
      setLoading1(false);
    }
  }

  useEffect(() => {
    fetchMyAPI();
  }, [item.identity]);


  const handleChange = async (type, typee) => {
    if (type === "player") {
      if (playerValue < 1) {
        if (typee === "opponent_team") {
          toast.error("A minimum of one player is required");
          setPlayerValue("");
          fetchMyAPI();
        } else {
          toast.error("A minimum of one player is required.");
          setPlayerValue("");
          fetchMyAPI();
        }
      } else if (playerValue > 9) {
        if (typee === "opponent_team") {
          toast.error("A minimum of one player is required");
          setPlayerValue("");
          fetchMyAPI();
        } else {
          toast.error("A minimum of one player is required.");
          setPlayerValue("");
          fetchMyAPI();
        }
      } else {
        setLoading(true);
        await postSettingApi("v1/user/admin/event-settings", typee, playerValue)
          .then((res) => {
            if (res.data.status === "success") {
              fetchMyAPI();
              setLoading(false);
              toast.success("Updated");
              setPlayerValue("");
            }
          })
          .catch((err) => setLoading(false));
      }
    }

    if (type === "staff") {
      if (playerValue < 1) {
        // alert("Staff + Coaches it can accept only 4 to 15");
        toast.error("A minimum of one staff/coach is required.");
        setPlayerValue("");
        fetchMyAPI();
      } else if (playerValue > 4) {
        // alert("Staff + Coaches it can accept only 4 to 15");
        toast.error("A minimum of one staff/coach is required.");
        setPlayerValue("");
        fetchMyAPI();
      } else {
        setLoading(true)
        await postSettingApi("v1/user/admin/event-settings", typee, playerValue)
          .then((res) => {
            if (res.data.status === "success") {
              fetchMyAPI();
              setLoading(false)
              toast.success("Updated");
              setPlayerValue("");
            }
          })
          .catch((err) => setLoading(false));
      }
    }
  };

  return (
    <>
    {loading1 === false ? (
      <>
      <td>
        <input
          type="number"
          max={item.type === "player" ? 9 : 4}
          min={item.type === "player" ? 1 : 1}
          onChange={(e) => setPlayerValue(e.target.value)}
          value={playerValue}
          key={item.id}
        />
      </td>
      <td>
        <button
          className="btn btn-success w-10 m-2"
          onClick={() => handleChange(item.type, item.typee)}
          key={item.id}
        >
          {loading === false ? (
            <h6 className="mb-0 fw-bold">Submit</h6>
          ) : (
            <h6 className="mb-0 fw-bold">Please wait...</h6>
          )}
        </button>
        <button
          className="btn btn-danger w-10 ml-2"
          onClick={() => fetchMyAPI()}
        >
          <h6 className="mb-0 fw-bold">X</h6>
        </button>
      </td>
      </>
       ) : (
        <Contentloader />
      )}
    </>
  );
};

export default EventInputBox;
