import React from 'react'
import { useNavigate } from "react-router-dom";

const LogoutPopup = ({ setShowModal }) => {

    let navigate = useNavigate();

    const localStorageClear = () => {
        localStorage.clear();
        sessionStorage.clear();
        window.location.reload();
    };

    return (
        <div className='fixed-top fixed-bottom h-100 d-flex align-items-center justify-content-center'>
            <div className='position-absolute top-0 bottom-0 h-100 w-100' style={{ zIndex: -1, backgroundColor: 'rgba(0, 0, 0, 0.5)', backdropFilter: 'blur(1px)' }} />
            <div className="bg-light p-4 py-5 border border-3 rounded">
                <div className='d-flex align-items-center justify-content-between'>
                    <h2>Logout</h2>
                    <span onClick={() => setShowModal(false)}><i className="fal fa-times cursor-pointer fs-3"></i></span>
                </div>

                <div className='my-4'>
                    <h5 className='mb-3'>Are you sure you want to <b>logout ?</b></h5>
                </div>

                <div className='d-flex gap-4 mt-3 justify-content-end'>
                    <button type="button" className='btn btn-outline-secondary border-2 fw-bolder' onClick={() => setShowModal(false)}>Cancel</button>
                    <button type="button" className='btn btn-danger fw-bolder' onClick={localStorageClear}>Confirm</button>
                </div>

            </div>
        </div>)
}

export default LogoutPopup;
