import React from "react";

const NoData = ({ data }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "var(--common-body)",
      }}
    >
      <p>{data}</p>
    </div>
  );
};

export default NoData;
