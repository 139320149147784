import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { authPost } from "../../service/ApiClient";
import "./style.css";

const ForgotPassword = () => {
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [loader, setLoader] = useState(false);
  const forgotPassword = async () => {
    setLoader(true);
    authPost("v1/auth/forgotPassword", email)
      .then((res) => {
        if (res.data.status === "success") {
          setLoader(false);
          alert(res?.data?.data);
          navigate("/");
        } else {
          alert("Please provide valid email id");
        }
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  return (
    <>
      <div
        className="container-fluid position-relative w-100 h-100 login-bg"
        style={{
          backgroundColor: "#F5F7FF",
          backgroundImage: `url(${"assets/images/baseball.png"})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="row h-100 justify-content-center overflow-hidden">
          <div
            className="col-sm-6 col-lg-4 col-xl-3 align-self-center d-flex flex-column p-sm-5 p-4 login"
            style={{ backgroundColor: "#F5F7FF", width: "33%" }}
          >
            <img
              src="assets/images/logo.png"
              style={{
                maxWidth: "50%",
                maxHeight: "100%",
                alignSelf: "center",
              }}
              alt=""
            />
            <form onSubmit={(e) => forgotPassword(e.preventDefault())}>
              <div className="border rounded mt-4 p-sm-5 p-4">
                <h3>Forgot Password</h3>
                <p>Please enter the your email</p>
                <div className="mb-3 mt-4 d-flex flex-row">
                  <input
                    type="email"
                    className="form-control"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                  />
                </div>

                <button
                  className="btn btn-primary w-100 mt-4 mb-3"
                  type="submit"
                  disabled={loader === true ? true : false}
                >
                  {loader === true ? (
                    <>
                      <span>Please wait...</span>
                      <img
                        src="assets/images/baseballgif.gif"
                        className="mx-auto img-fluid"
                        alt=""
                        width={27}
                        height={27}
                      />
                    </>
                  ) : (
                    "Submit"
                  )}
                </button>
                <a
                  href="/"
                  className="cursor-pointer text-primary text-decoration-underline"
                >
                  Login
                </a>
              </div>
            </form>

            <p className="small mt-3 text-center">
              © 2023 EC Frazier & Associates Inc, Inc. All rights reserved
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
