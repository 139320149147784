// import React from "react";
import React from "react";
import Header from "../../components/Header";
import ToggleSwitch from "../../components/ToggleButton";
import TopHeaderSection from "../../components/TopHeaderSection";
import MobileNav from "../../components/MobileNav";
import Footer from "../../components/Footer";
const NotificationPreferences = () => {
  return (
    <>
      <div className="container-fluid position-relative w-100 h-100">
        <div className="row h-100">
          <div
            className="col-sm-2 px-0 h-100 d-md-block d-none"
            style={{ width: "15%" }}
          >
            <Header />
          </div>
          <MobileNav />
          <div className="col-12 col-md-10 px-0 h-100">
            <div className="w-100 h-100 d-flex flex-column pb-3">
              <TopHeaderSection />
              <div className="container-fluid py-3 border-top border-bottom">
                <div className="row">
                  <div className="col">
                    <h5 className="mb-0">Notification Settings</h5>
                  </div>
                  <div className="col-auto ms-auto"></div>
                </div>
              </div>
              <div className="container-fluid mt-3 overflow-auto flex-fill">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="bg-light p-3 mt-3">
                      <div className="p-sm-3">
                        <form action="">
                          <ToggleSwitch label="Mail Notifications" />
                          <ToggleSwitch label="Push Notifications" />
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default NotificationPreferences;
