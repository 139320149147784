import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import TopHeaderSection from "../../components/TopHeaderSection";
import moment from "moment";
import Pagination from "../../components/Pagination";
import { getAPI } from "../../service/ApiClient";
import CommonSearchBox from "../../components/CommonSearchBox";
import { CSVLink } from "react-csv";

import "rsuite/dist/rsuite.min.css";
import PageIcon from "@rsuite/icons/Page";
import FileDownloadIcon from "@rsuite/icons/FileDownload";

import { Popover, Whisper, Dropdown, IconButton } from "rsuite/";
import jsPDF from "jspdf";
import "jspdf-autotable";
import MobileNav from "../../components/MobileNav";
import Footer from "../../components/Footer";
import Contentloader from "../../components/ContentLoader";
import NoData from "../../components/Nodata";

const Events = () => {
  const [page, setPage] = useState({
    currentPageForLive: 1,
    currentPageForRecent: 1,
    currentPageForUpcoming: 1,
  });
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [asc, setAsc] = useState(false);
  const [pdfTitle, setPdfTitle] = useState("");

  const [eventLists, setEventList] = useState([]);
  const [eventListsLive, setEventListLive] = useState([]);
  const [eventListsRecent, setEventListRecent] = useState([]);
  const [eventListsUpcoming, setEventListUpcoming] = useState([]);
  const [pdfList, setPdfList] = useState([]);
  const [csvList, setCsvList] = useState([]);
  const [eventType, setEventType] = useState("live");
  useEffect(() => {
    eventList();
  }, [searchText, asc, eventType]);

  const eventList = async () => {
    setLoading(true);
    await getAPI(
      "v1/user/admin/events-tabs?search=" + searchText + "&type=" + eventType
    )
      .then((response) => {
        setLoading(false);
        setEventList(response.data.data);
        setEventListLive(response.data.data.live);
        setEventListRecent(response.data.data.recent);
        setEventListUpcoming(response.data.data.upcoming);
      })
      .catch((err) => setLoading(false));
  };

  // Get current page items for live event
  const indexOfLastPostForLive = page.currentPageForLive * itemsPerPage;
  const indexOfFirstPostForLive = indexOfLastPostForLive - itemsPerPage;

  // Get current page items for recent events
  const indexOfLastPostForRecent = page.currentPageForRecent * itemsPerPage;
  const indexOfFirstPostForRecent = indexOfLastPostForRecent - itemsPerPage;

  // Get current page items for upcoming events
  const indexOfLastPostForUpcoming = page.currentPageForUpcoming * itemsPerPage;
  const indexOfFirstPostForUpcoming = indexOfLastPostForUpcoming - itemsPerPage;

  const paginate = (number, type) => {
    if (type === "live") {
      setPage({ ...page, currentPageForLive: number });
    }
    if (type === "recent") {
      setPage({ ...page, currentPageForRecent: number });
    }
    if (type === "upcoming") {
      setPage({ ...page, currentPageForUpcoming: number });
    }
  };
  const renderCSVData = ({ item }) => {
    const modifiedCsvData = item.map((entry) => ({
      ...entry,
      status: entry.status ? "Activated" : "DeActivated",
      is_subscribe: entry.is_subscribe ? "Active" : "InActive",
    }));

    // Remove unwanted keys from each object in modifiedCsvData
    const filteredCsvData = modifiedCsvData.map(
      ({ _id, created_at, current_subscription_data, role, responsibility, ...rest }) => rest
    );

    setCsvList(
      filteredCsvData.map((item) => ({
        EVENT_TYPE: item.event_type,
        SCRIMAGE: `${item.scrimmage ? "Yes" : "No"}`,
        PLAYING_TEAM: item.playing_team,
        OPPONENT_TEAM: item.opponent_team,
        LOCATION: item.location,
        PROGRESS: item.progress,
        RESULT: item.game_status,
        CREATED_DATE: new Date(item.game_start_date).toLocaleDateString(),
      }))
    );
    return filteredCsvData;
  };
  useEffect(() => {
    if (eventType === "live") {
      setPdfList(eventListsLive);
      renderCSVData({ item: eventListsLive || [] });
      setPdfTitle("Live event list");
    } else if (eventType === "recent") {
      setPdfList(eventListsRecent);
      renderCSVData({ item: eventListsRecent || [] });
      setPdfTitle("Recent event list");
    } else if (eventType === "upcoming") {
      setPdfList(eventListsUpcoming);
      renderCSVData({ item: eventListsUpcoming || [] });
      setPdfTitle("Upcoming event list");
    }
  }, [eventType, eventListsUpcoming, eventListsLive, eventListsRecent]);

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = pdfTitle;
    const headers = [
      [
        "EVENT TYPE",
        "SCRIMAGE",
        "PLAYING TEAM",
        "OPPONENT TEAM",
        "LOCATION",
        "PROGRESS",
        "RESULT",
        "CREATED DATE",
      ],
    ];

    const data = pdfList?.map((elt) => [
      elt.event_type,
      elt.scrimmage ? "Yes" : "No",
      elt.playing_team,
      elt.opponent_team,
      elt.location,
      elt.progress,
      elt.game_status,
      new Date(elt.game_start_date).toLocaleDateString(),
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("report.pdf");
  };

  const renderMenu = ({ left, top, className }, ref) => {
    return (
      <Popover ref={ref} className={className} style={{ left, top }} full>
        <Dropdown.Menu title="Create new file">
          <Dropdown.Item icon={<PageIcon />} eventKey={1}>
            <CSVLink
              data={csvList}
              target="_blank"
              filename={pdfTitle}
            >
              {" "}
              <button>CSV format ⬇</button>
            </CSVLink>
          </Dropdown.Item>
          <Dropdown.Item icon={<FileDownloadIcon />} eventKey={2}>
            <button onClick={() => exportPDF()}>PDF format ⬇</button>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Popover>
    );
  };

  return (
    <>
      <div className="container-fluid position-relative w-100 h-100">
        <div className="row h-100">
          <div
            className="col-sm-2 px-0 h-100 d-md-block d-none"
            style={{ width: "15%" }}
          >
            <Header />
          </div>
          <MobileNav />
          <div className="col-12 col-md-10 px-0 h-100">
            <div className="w-100 h-100 d-flex flex-column pb-3">
              <TopHeaderSection />
              <div className="container-fluid py-3 border-top overflow-auto flex-fill">
                <div className="row">
                  <div className="col">
                    <h5 className="mb-0">Events</h5>
                  </div>
                  <div className="col-auto ms-auto">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0"></ol>
                    </nav>
                  </div>

                  <div className="border rounded mt-3">
                    <div className="d-lg-flex align-items-center border-bottom p-1 col-12">
                      <div className="col-lg-7 col-md-12 px-0">
                        <button
                          className={
                            eventType === "live"
                              ? "btn  w-10 mt-4 mb-md-3 tabs_user"
                              : "btn  w-10 mt-4 mb-md-3 tabs_user"
                          }
                          onClick={() => {
                            setEventType("live");
                            setSearchText("");
                          }}
                        >
                          <h6
                            className={
                              eventType === "live"
                                ? "mb-0 fw-bold active-text-color"
                                : "mb-0 fw-bold noactive-text-color"
                            }
                          >
                            Live Events
                          </h6>
                        </button>
                        <button
                          className={
                            eventType === "recent"
                              ? "btn  w-10 mt-4 mb-md-3 tabs_user"
                              : "btn  w-10 mt-4 mb-md-3 tabs_user"
                          }
                          onClick={() => {
                            setEventType("recent");
                            setSearchText("");
                          }}
                        >
                          <h6
                            className={
                              eventType === "recent"
                                ? "mb-0 fw-bold active-text-color"
                                : "mb-0 fw-bold noactive-text-color"
                            }
                          >
                            Recent Events
                          </h6>
                        </button>
                        <button
                          className={
                            eventType === "upcoming"
                              ? "btn  w-10 mt-4 mb-md-3 tabs_user"
                              : "btn  w-10 mt-4 mb-md-3 tabs_user"
                          }
                          onClick={() => {
                            setEventType("upcoming");
                            setSearchText("");
                          }}
                        >
                          <h6
                            className={
                              eventType === "upcoming"
                                ? "mb-0 fw-bold active-text-color"
                                : "mb-0 fw-bold noactive-text-color"
                            }
                          >
                            Upcoming Events
                          </h6>
                        </button>
                      </div>
                      <div className="col-lg-5 col-md-12 d-md-flex justify-content-end align-items-center px-0 mt-md-0 mt-3">
                        <div className="col-lg-9 col-md-7 pe-0 mb-md-0 mb-3">
                          <CommonSearchBox
                            searchText={searchText}
                            setSearchText={setSearchText}
                          />
                        </div>
                        <center>
                          <div style={{ padding: 20 }}>
                            <Whisper
                              placement="bottomStart"
                              trigger="click"
                              speaker={renderMenu}
                            >
                              <IconButton
                                appearance="primary"
                                color="blue"
                                icon={<PageIcon />}
                              >
                                Export report
                              </IconButton>
                            </Whisper>
                          </div>
                        </center>
                      </div>
                    </div>

                    {eventType === "live" ? (
                      <>
                        {loading === false ? (
                          <div className="row p-0 gx-0">
                            <div className="col p-1">
                              <div className="table-responsive">
                                {eventLists?.live?.length > 0 ? (
                                  <table className="table mb-0">
                                    <thead className="text-nowrap">
                                      <tr className="bg-light">
                                        <th>S No.</th>
                                        <th>Event Type</th>
                                        <th>Scrimmage </th>
                                        <th>Playing Team</th>
                                        <th>Opponent Team</th>

                                        {/* <th>Away</th> */}
                                        <th>Location</th>
                                        <th>Progress</th>
                                        <th>Result</th>

                                        <th>
                                          {" "}
                                          {asc === false ? (
                                            <i
                                              className="fa fa-arrow-up cursor-pointer"
                                              onClick={() => setAsc(!asc)}
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-arrow-down cursor-pointer"
                                              onClick={() => setAsc(!asc)}
                                            ></i>
                                          )}{" "}
                                          Game Date
                                        </th>
                                        <th>Time</th>

                                        {/* <th>Action</th> */}
                                      </tr>
                                    </thead>
                                    {eventLists.live
                                      ?.slice(
                                        indexOfFirstPostForLive,
                                        indexOfLastPostForLive
                                      )
                                      .sort((a, b) => {
                                        if (
                                          a.game_start_date.toLowerCase() <
                                          b.game_start_date.toLowerCase()
                                        ) {
                                          return asc === false ? 1 : -1;
                                        }
                                        if (
                                          a.game_start_date.toLowerCase() >
                                          b.game_start_date.toLowerCase()
                                        ) {
                                          return asc === false ? -1 : 1;
                                        }

                                        return 0;
                                      })
                                      .map((item, index) => {
                                        return (
                                          <>
                                            <tbody key={index}>
                                              <tr>
                                                <td>
                                                  {(
                                                    (page.currentPageForLive -
                                                      1) *
                                                    10 +
                                                    index +
                                                    1
                                                  )
                                                    .toString()
                                                    .padStart(2, "0")}
                                                </td>
                                                <td>{item.event_type}</td>
                                                <td>
                                                  {JSON.stringify(
                                                    item.scrimmage
                                                  ) === "false"
                                                    ? "No"
                                                    : "Yes"}
                                                </td>
                                                <td>{item.playing_team}</td>
                                                <td>{item.opponent_team}</td>

                                                {/* <td>Away</td> */}
                                                <td style={{ width: "300px" }}>
                                                  {item.location}
                                                </td>
                                                <td
                                                  style={{
                                                    color: "red",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  {item.progress}
                                                </td>
                                                <td
                                                  style={{
                                                    color: "red",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  {item.game_status}
                                                </td>
                                                <td>
                                                  {moment(
                                                    item.game_start_date.split(
                                                      "T"
                                                    )[0]
                                                  ).format("MM/DD/YYYY")}
                                                </td>
                                                <td>
                                                  {new Date(
                                                    "1970-01-01T" +
                                                    item.game_start_date.split(
                                                      "T"
                                                    )[1]
                                                  ).toLocaleTimeString(
                                                    "en-US",
                                                    {
                                                      timeZone: "EST",
                                                      hour12: true,
                                                      hour: "numeric",
                                                      minute: "numeric",
                                                    }
                                                  )}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </>
                                        );
                                      })}
                                  </table>
                                ) : (
                                  <div className="mt-3">
                                    <NoData data={"No live events found"} />
                                  </div>
                                )}
                              </div>
                            </div>
                            {eventLists.live !== undefined &&
                              eventLists.live.length > 10 && (
                                <Pagination
                                  itemsPerPage={itemsPerPage}
                                  totalItems={eventLists.live.length}
                                  paginate={paginate}
                                  currentPage={page?.currentPageForLive}
                                  type="live"
                                  setPrevBtn={() =>
                                    setPage({
                                      ...page,
                                      currentPageForLive:
                                        page.currentPageForLive - 1,
                                    })
                                  }
                                  setNextBtn={() =>
                                    setPage({
                                      ...page,
                                      currentPageForLive:
                                        page.currentPageForLive + 1,
                                    })
                                  }
                                  searchText={searchText}
                                />
                              )}
                          </div>
                        ) : (
                          <Contentloader />
                        )}
                      </>
                    ) : null}

                    {eventType === "recent" ? (
                      <>
                        {loading === false ? (
                          <div className="row p-0 gx-0">
                            <div className="col p-1">
                              <div className="table-responsive">
                                {eventLists?.recent?.length > 0 ? (
                                  <table className="table mb-0">
                                    <thead className="text-nowrap">
                                      <tr className="bg-light">
                                        <th>S No.</th>
                                        <th>Event Type</th>
                                        <th>Scrimmage </th>
                                        <th>Playing Team</th>
                                        <th>Opponent Team</th>
                                        {/* <th>Away</th> */}
                                        <th>Location</th>
                                        <th>Progress</th>
                                        <th>Result</th>
                                        <th>
                                          {" "}
                                          {asc === false ? (
                                            <i
                                              className="fa fa-arrow-up cursor-pointer"
                                              onClick={() => setAsc(!asc)}
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-arrow-down cursor-pointer"
                                              onClick={() => setAsc(!asc)}
                                            ></i>
                                          )}{" "}
                                          Game Date
                                        </th>
                                        <th>Time</th>

                                        {/* <th>Action</th> */}
                                      </tr>
                                    </thead>
                                    {eventLists.recent
                                      ?.slice(
                                        indexOfFirstPostForRecent,
                                        indexOfLastPostForRecent
                                      )
                                      .sort((a, b) => {
                                        if (
                                          a.game_start_date.toLowerCase() <
                                          b.game_start_date.toLowerCase()
                                        ) {
                                          return asc === false ? 1 : -1;
                                        }
                                        if (
                                          a.game_start_date.toLowerCase() >
                                          b.game_start_date.toLowerCase()
                                        ) {
                                          return asc === false ? -1 : 1;
                                        }

                                        return 0;
                                      })
                                      .map((item, index) => {
                                        return (
                                          <>
                                            <tbody key={index}>
                                              <tr>
                                                <td>
                                                  {(
                                                    (page.currentPageForRecent -
                                                      1) *
                                                    10 +
                                                    index +
                                                    1
                                                  )
                                                    .toString()
                                                    .padStart(2, "0")}
                                                </td>
                                                <td>{item.event_type}</td>
                                                <td>
                                                  {JSON.stringify(
                                                    item.scrimmage
                                                  ) === "false"
                                                    ? "No"
                                                    : "Yes"}
                                                </td>
                                                <td>{item.playing_team}</td>
                                                <td>{item.opponent_team}</td>
                                                {/* <td>Away</td> */}
                                                <td style={{ width: "300px" }}>
                                                  {item.location}
                                                </td>
                                                <td
                                                  style={{
                                                    color: "blue",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  {item.progress}
                                                </td>
                                                <td
                                                  style={{
                                                    color: "blue",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  {item.game_status}
                                                </td>
                                                <td>
                                                  {moment(
                                                    item.game_start_date.split(
                                                      "T"
                                                    )[0]
                                                  ).format("MM/DD/YYYY")}
                                                </td>
                                                <td>
                                                  {new Date(
                                                    "1970-01-01T" +
                                                    item.game_start_date.split(
                                                      "T"
                                                    )[1]
                                                  ).toLocaleTimeString(
                                                    "en-US",
                                                    {
                                                      timeZone: "EST",
                                                      hour12: true,
                                                      hour: "numeric",
                                                      minute: "numeric",
                                                    }
                                                  )}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </>
                                        );
                                      })}
                                  </table>
                                ) : (
                                  <div className="mt-3">
                                    <NoData data={"No recent events found"} />
                                  </div>
                                )}
                              </div>
                            </div>
                            {eventLists.recent !== undefined &&
                              eventLists.recent.length > 10 && (
                                <Pagination
                                  itemsPerPage={itemsPerPage}
                                  totalItems={eventLists.recent.length}
                                  paginate={paginate}
                                  currentPage={page?.currentPageForRecent}
                                  type="recent"
                                  setPrevBtn={() =>
                                    setPage({
                                      ...page,
                                      currentPageForRecent:
                                        page.currentPageForRecent - 1,
                                    })
                                  }
                                  setNextBtn={() =>
                                    setPage({
                                      ...page,
                                      currentPageForRecent:
                                        page.currentPageForRecent + 1,
                                    })
                                  }
                                  searchText={searchText}
                                />
                              )}
                          </div>
                        ) : (
                          <Contentloader />
                        )}
                      </>
                    ) : null}

                    {eventType === "upcoming" ? (
                      <>
                        {loading === false ? (
                          <div className="row p-0 gx-0">
                            <div className="col p-1">
                              <div className="table-responsive">
                                {eventLists?.upcoming?.length > 0 ? (
                                  <table className="table mb-0">
                                    <thead className="text-nowrap">
                                      <tr className="bg-light">
                                        <th>S No.</th>
                                        <th>Event Type</th>
                                        <th>Scrimmage </th>
                                        <th>Playing Team</th>
                                        <th>Opponent Team</th>
                                        {/* <th>Away</th> */}
                                        <th>Location</th>
                                        <th>Progress</th>
                                        <th>Result</th>
                                        <th>
                                          {" "}
                                          {asc === false ? (
                                            <i
                                              className="fa fa-arrow-up cursor-pointer"
                                              onClick={() => setAsc(!asc)}
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-arrow-down cursor-pointer"
                                              onClick={() => setAsc(!asc)}
                                            ></i>
                                          )}{" "}
                                          Game Date
                                        </th>
                                        <th>Time</th>

                                        {/* <th>Action</th> */}
                                      </tr>
                                    </thead>
                                    {eventLists.upcoming
                                      ?.slice(
                                        indexOfFirstPostForUpcoming,
                                        indexOfLastPostForUpcoming
                                      )
                                      .sort((a, b) => {
                                        if (
                                          a.game_start_date.toLowerCase() <
                                          b.game_start_date.toLowerCase()
                                        ) {
                                          return asc === false ? 1 : -1;
                                        }
                                        if (
                                          a.game_start_date.toLowerCase() >
                                          b.game_start_date.toLowerCase()
                                        ) {
                                          return asc === false ? -1 : 1;
                                        }

                                        return 0;
                                      })
                                      .map((item, index) => {
                                        return (
                                          <>
                                            <tbody key={index}>
                                              <tr>
                                                <td>
                                                  {(
                                                    (page.currentPageForUpcoming -
                                                      1) *
                                                    10 +
                                                    index +
                                                    1
                                                  )
                                                    .toString()
                                                    .padStart(2, "0")}
                                                </td>
                                                <td>{item.event_type}</td>
                                                <td>
                                                  {JSON.stringify(
                                                    item.scrimmage
                                                  ) === "false"
                                                    ? "No"
                                                    : "Yes"}
                                                </td>
                                                <td>{item.playing_team}</td>
                                                <td>{item.opponent_team}</td>
                                                {/* <td>Away</td> */}
                                                <td style={{ width: "300px" }}>
                                                  {item.location}
                                                </td>
                                                <td
                                                  style={{
                                                    color: "green",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  {item.progress}
                                                </td>
                                                <td
                                                  style={{
                                                    color: "green",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  {item.game_status}
                                                </td>
                                                <td>
                                                  {moment(
                                                    item.game_start_date.split(
                                                      "T"
                                                    )[0]
                                                  ).format("MM/DD/YYYY")}
                                                </td>
                                                <td>
                                                  {new Date(
                                                    "1970-01-01T" +
                                                    item.game_start_date.split(
                                                      "T"
                                                    )[1]
                                                  ).toLocaleTimeString(
                                                    "en-US",
                                                    {
                                                      timeZone: "EST",
                                                      hour12: true,
                                                      hour: "numeric",
                                                      minute: "numeric",
                                                    }
                                                  )}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </>
                                        );
                                      })}
                                  </table>
                                ) : (
                                  <div className="mt-3">
                                    <NoData data={"No upcoming events found"} />
                                  </div>
                                )}
                              </div>
                            </div>
                            {eventLists.upcoming !== undefined &&
                              eventLists.upcoming.length > 10 && (
                                <Pagination
                                  itemsPerPage={itemsPerPage}
                                  totalItems={eventLists.upcoming.length}
                                  paginate={paginate}
                                  currentPage={page?.currentPageForUpcoming}
                                  type="upcoming"
                                  setPrevBtn={() =>
                                    setPage({
                                      ...page,
                                      currentPageForUpcoming:
                                        page.currentPageForUpcoming - 1,
                                    })
                                  }
                                  setNextBtn={() =>
                                    setPage({
                                      ...page,
                                      currentPageForUpcoming:
                                        page.currentPageForUpcoming + 1,
                                    })
                                  }
                                  searchText={searchText}
                                />
                              )}
                          </div>
                        ) : (
                          <Contentloader />
                        )}
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Events;
