import React, { useState } from "react";
import Pagination from "./Pagination";
const GlobalSearchTable = ({ searchResult }) => {
  console.log({searchResult})
  const [itemsPerPage] = useState(5);

  const [page, setPage] = useState({
    currentPageForCoach: 1,
  });
  const paginate = (number, type) => {
    setPage({ ...page, currentPageForCoach: number });
  };
  const indexOfLastPostForCoach = page.currentPageForCoach * itemsPerPage;
  const indexOfFirstPostForCoach = indexOfLastPostForCoach - itemsPerPage;
  return (
    <>
      <div
        className="row p-3 p-md-5 gx-0 m-md-5 m-3 border-5"
        style={{ backgroundColor: "#F2F7FF" }}
      >
        <h5>
          Showing result{" "}
          {/* <span style={{ fontWeight: "bold" }}>1 - {itemsPerPage}</span>{" "}
          <span>of </span> */}
          <span style={{ fontWeight: "bold" }}>{searchResult.length}</span>
        </h5>
        <div className="col p-1">
          <div className="table-responsive">
            <table className="table mb-0">
              <thead>
                <tr className="bg-light">
                  <th>S No.</th>
                  <th>F Name</th>
                  <th>L Name</th>
                  <th>Email</th>
                  <th>Team Name</th>
                </tr>
              </thead>

              {searchResult
                ?.slice(indexOfFirstPostForCoach, indexOfLastPostForCoach)
                ?.map((item, index) => (
                  <tbody>
                    <tr>
                      <td>
                        {((page.currentPageForCoach - 1) * 5 + index + 1)
                          .toString()
                          .padStart(2, "0")}
                      </td>
                      <td>{item.first_name}</td>
                      <td>{item.last_name}</td>
                      <td>{item.email}</td>
                      <td>{item.team_name}</td>
                    </tr>
                  </tbody>
                ))}
            </table>
          </div>
        </div>
        {searchResult.length > 5 && (
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={searchResult.length}
            paginate={paginate}
            currentPage={page?.currentPageForCoach}
            type="coach"
            setPrevBtn={() =>
              setPage({
                ...page,
                currentPageForCoach: page.currentPageForCoach - 1,
              })
            }
            setNextBtn={() =>
              setPage({
                ...page,
                currentPageForCoach: page.currentPageForCoach + 1,
              })
            }
          />
        )}
      </div>
    </>
  );
};

export default GlobalSearchTable;
