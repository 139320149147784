import React from "react";

const CommonSearchBox = ({ searchText, setSearchText }) => {
  return (
    <>
      <div className="d-flex justify-content-start align-self-center ms-2">
        <div
          className="d-flex flex-row search-field search-box-style"
        >
          <span style={{ zIndex: 1, alignSelf: "center" }}>
            <i className="fal fa-search"></i>
          </span>

          <input
            className="form-control bg-light border-0 rounded-pill pl-5"
            type="text"
            placeholder="Search..."
            onChange={(e) => setSearchText(e.target.value)}
            value={searchText}
            style={{ marginLeft: "-24px" }}
          />
          <span
            style={{
              marginLeft: "-24px",
              alignSelf: "center",
            }}
            onClick={() => setSearchText("")}
          >
            <i className="fal fa-times cursor-pointer fs-6"></i>
          </span>
        </div>
      </div>
    </>
  );
};

export default CommonSearchBox;
