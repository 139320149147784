import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import TopHeaderSection from "../../components/TopHeaderSection";
import ActiveToggleButton from "../../components/ActiveToggleButton";
import InActiveToggleButton from "../../components/InActiveToggleButton";
import Pagination from "../../components/Pagination";
import { getAPI, postTeam } from "../../service/ApiClient";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "rsuite/dist/rsuite.min.css";
import PageIcon from "@rsuite/icons/Page";
import UserBadgeIcon from "@rsuite/icons/UserBadge";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import TagFilterIcon from "@rsuite/icons/TagFilter";
import { Popover, Whisper, Dropdown, IconButton } from "rsuite/";
import "./style.css";
import CommonSearchBox from "../../components/CommonSearchBox";
import MobileNav from "../../components/MobileNav";
import Footer from "../../components/Footer";
import Contentloader from "../../components/ContentLoader";
import ActiveDeacticeColor from "../../components/ActiveDeactiveColor";
import NoData from "../../components/Nodata";

const UserMetrics = () => {
  const [coachList, setCoachList] = useState([]);
  const [playerList, setPlayerList] = useState([]);
  const [FansList, setFansList] = useState([]);
  const [staffsList, setStaffsList] = useState([]);
  const [asc, setAsc] = useState(false);
  const [coachActive, setcoachActive] = useState(true);
  const [staffActive, setstaffActive] = useState(false);
  const [playerActive, setplayerActive] = useState(false);
  const [fansActive, setfansActive] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [activatedUsers, setActivetedUsers] = useState("null");
  const [page, setPage] = useState({
    currentPageForCoach: 1,
    currentPageForPlayer: 1,
    currentPageForStaff: 1,
    currentPageForFans: 1,
  });
  const [loading, setLoading] = useState(false);
  const [teamResponsibility, setTeamResponsibility] = useState([]);
  const [staffsResponsibility, setStaffsResponsibility] = useState([]);
  const [itemsPerPage] = useState(10);
  const [responsibility, setResponsibility] = useState("");
  const [responsibilityShow, setResponsibilityShow] = useState(true);
  const [pdfTitle, setPdfTitle] = useState("");
  const [pdfList, setPdfList] = useState([]);
  const [csvList, setCsvList] = useState([]);
  // Get current page items for Coaches
  const indexOfLastPostForCoach = page.currentPageForCoach * itemsPerPage;
  const indexOfFirstPostForCoach = indexOfLastPostForCoach - itemsPerPage;

  // Get current page items for Players
  const indexOfLastPostForPlayer = page.currentPageForPlayer * itemsPerPage;
  const indexOfFirstPostForPlayer = indexOfLastPostForPlayer - itemsPerPage;

  // Get current page items for Staff
  const indexOfLastPostForStaff = page.currentPageForStaff * itemsPerPage;
  const indexOfFirstPostForStaff = indexOfLastPostForStaff - itemsPerPage;

  // Get current page items for Fans
  const indexOfLastPostForFans = page.currentPageForFans * itemsPerPage;
  const indexOfFirstPostForFans = indexOfLastPostForFans - itemsPerPage;

  const paginate = (number, type) => {
    if (type === "coach") {
      setPage({ ...page, currentPageForCoach: number });
    }
    if (type === "player") {
      setPage({ ...page, currentPageForPlayer: number });
    }
    if (type === "staff") {
      setPage({ ...page, currentPageForStaff: number });
    }
    if (type === "fan") {
      setPage({ ...page, currentPageForFans: number });
    }
  };

  useEffect(() => {
    getCoacheList();
    getPlayerList();
    getFansList();
    getStaffList();
  }, [
    searchText,
    asc,
    activatedUsers,
    responsibility,
    coachActive,
    staffActive,
    playerActive,
    fansActive,
  ]);

  const getCoacheList = async () => {
    setLoading(true);
    await getAPI(`v1/user/admin/coach-list?search=${coachActive ? searchText : ''}`)
      .then((response) => {
        setLoading(false);
        if (responsibility === "") {
          setCoachList(
            response.data.data
              .sort((a, b) => {
                if (a.first_name.toLowerCase() < b.first_name.toLowerCase()) {
                  return asc === false ? -1 : 1;
                }
                if (a.first_name.toLowerCase() > b.first_name.toLowerCase()) {
                  return asc === false ? 1 : -1;
                }

                return 0;
              })
              .filter((item) => {
                if (activatedUsers === "true") return item.status;
                if (activatedUsers === "false") return !item.status;
                return true;
              })
          );
        }

        if (responsibility !== "") {
          setCoachList(
            response.data.data
              .sort((a, b) => {
                if (a.first_name.toLowerCase() < b.first_name.toLowerCase()) {
                  return asc === false ? -1 : 1;
                }
                if (a.first_name.toLowerCase() > b.first_name.toLowerCase()) {
                  return asc === false ? 1 : -1;
                }

                return 0;
              })
            // .filter((item) => {
            //   if (responsibility === "b")
            //     return item.responsibility.b === true;
            //   if (responsibility === "pc")
            //     return item.responsibility.pc === true;
            //   if (responsibility === "sc")
            //     return item.responsibility.sc === true;
            //   if (responsibility === "vs")
            //     return item.responsibility.vs === true;

            //   return true;
            // })
          );
        }
      })
      .catch((err) => setLoading(false));
  };

  const getPlayerList = async () => {
    setLoading(true);
    await getAPI(`v1/user/admin/players-list?search=${playerActive ? searchText : ''}`)

      .then((response) => {
        setLoading(false);
        setPlayerList(
          response.data.data
            .sort((a, b) => {
              if (a.first_name.toLowerCase() < b.first_name.toLowerCase()) {
                return asc === false ? -1 : 1;
              }
              if (a.first_name.toLowerCase() > b.first_name.toLowerCase()) {
                return asc === false ? 1 : -1;
              }

              return 0;
            })
            .filter((item) => {
              if (activatedUsers === "true") return item.status;
              if (activatedUsers === "false") return !item.status;
              return true;
            })
        );
      })
      .catch((err) => setLoading(false));
  };

  const getFansList = async () => {
    setLoading(true);
    await getAPI(`v1/user/admin/fans-list?search=${fansActive ? searchText : ''}`)
      .then((response) => {
        setLoading(false);
        setFansList(
          response.data.data
            .sort((a, b) => {
              if (a.first_name.toLowerCase() < b.first_name.toLowerCase()) {
                return asc === false ? -1 : 1;
              }
              if (a.first_name.toLowerCase() > b.first_name.toLowerCase()) {
                return asc === false ? 1 : -1;
              }

              return 0;
            })
            .filter((item) => {
              if (activatedUsers === "true") return item.status;
              if (activatedUsers === "false") return !item.status;
              return true;
            })
        );
      })
      .catch((err) => setLoading(false));
  };

  const teamResponsibilty = async (email) => {
    setLoading(true);
    await postTeam("v1/user/admin/team-responsibility", email)
      .then((res) => {
        setLoading(false);
        if (res.data.status === "success") {
          setTeamResponsibility(res.data.data)
          console.log(res.data.data, "res-coach");
        }
      })
      .catch((err) => {
        console.log(err, "err");
        setLoading(false)
      }
      );
  };

  const staffResponsibilty = async (email) => {
    setLoading(true);
    await postTeam("v1/user/admin/staff-responsibility", email)
      .then((res) => {
        setLoading(false);
        if (res.data.status === "success") {
          setStaffsResponsibility(res.data.data)
          console.log(res.data.data, "res-staff");
        }
      })
      .catch((err) => {
        console.log(err, "err");
        setLoading(false)
      }
      );
  };

  const getStaffList = async () => {
    setLoading(true);
    await getAPI(`v1/user/admin/staffs-list?search=${staffActive ? searchText : ''}`)
      .then((response) => {
        setLoading(false);
        setStaffsList(
          response.data.data
            .sort((a, b) => {
              if (a.first_name.toLowerCase() < b.first_name.toLowerCase()) {
                return asc === false ? -1 : 1;
              }
              if (a.first_name.toLowerCase() > b.first_name.toLowerCase()) {
                return asc === false ? 1 : -1;
              }

              return 0;
            })
            .filter((item) => {
              if (activatedUsers === "true") return item.status;
              if (activatedUsers === "false") return !item.status;
              return true;
            })
        );

        if (responsibility !== "") {
          setStaffsList(
            response.data.data
              .sort((a, b) => {
                if (a.first_name.toLowerCase() < b.first_name.toLowerCase()) {
                  return asc === false ? -1 : 1;
                }
                if (a.first_name.toLowerCase() > b.first_name.toLowerCase()) {
                  return asc === false ? 1 : -1;
                }

                return 0;
              })
              .filter((item) => {
                if (responsibility === "b")
                  return item.responsibility.b === true;
                if (responsibility === "pc")
                  return item.responsibility.pc === true;
                if (responsibility === "sc")
                  return item.responsibility.sc === true;
                if (responsibility === "vs")
                  return item.responsibility.vs === true;

                return true;
              })
          );
        }
      })
      .catch((err) => setLoading(false));
  };
  const handleChange = (event) => {
    setResponsibility("");
    setActivetedUsers(event.target.value);
  };

  const handleChangeResponsibility = (event) => {
    setActivetedUsers("null");
    setResponsibility(event.target.value);
  };

  const renderCSVData = ({ item }) => {
    const modifiedCsvData = item.map((entry) => ({
      ...entry,
      status: entry.status ? "Activated" : "DeActivated",
      is_subscribe: entry.is_subscribe ? "Active" : "InActive",
    }));

    // Remove unwanted keys from each object in modifiedCsvData
    const filteredCsvData = modifiedCsvData.map(
      ({ _id, created_at, current_subscription_data, role, responsibility, ...rest }) => rest
    );

    setCsvList(
      filteredCsvData.map((item) => ({
        UNIQUE_ID: item.customeid,
        FIRSTNAME: item.first_name,
        LASTNAME: item.last_name,
        EMAIL: item.email,
        ...(!coachActive && { TEAM_NAME: item.team_name }),
        ...(playerActive && { JERSY_NO: item.jersy_no ? item.jersy_no : "-" }),
        ...(playerActive && { BATTING: item.batting_style ? item.batting_style : "-" }),
        ...(playerActive && { THROWING: item.throwing_style ? item.throwing_style : "-" }),
        USER_STATUS: item.status,
        ...((coachActive || fansActive) && { SUBSCRIPTION_STATUS: item.is_subscribe, }),
      }))
    );
    return filteredCsvData;
  };
  useEffect(() => {
    if (coachActive) {
      setPdfList(coachList);
      renderCSVData({ item: coachList || [] });
      setPdfTitle("User coach list");
    } else if (staffActive) {
      setPdfList(staffsList);
      renderCSVData({ item: staffsList || [] });
      setPdfTitle("User staff list");
    } else if (playerActive) {
      setPdfList(playerList);
      renderCSVData({ item: playerList || [] });
      setPdfTitle("User player list");
    } else {
      setPdfList(FansList);
      renderCSVData({ item: FansList || [] });
      setPdfTitle("User fans list");
    }
  }, [coachActive, staffActive, playerActive, fansActive, coachList]);

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = pdfTitle;
    const headers = [coachActive ? ["UNIQUE ID","FIRST NAME", "LAST NAME", "EMAIL", "USER STATUS", "SUBSCRIPTION STATUS"]
      : staffActive ? ["UNIQUE ID","FIRST NAME", "LAST NAME", "EMAIL", "TEAM NAME", "USER STATUS"]
        : playerActive ? ["UNIQUE ID","FIRST NAME", "LAST NAME", "EMAIL", "TEAM NAME", "JERSY NO", "BATTING", "THROWING", "USER STATUS"]
          : ["UNIQUE ID","FIRST NAME", "LAST NAME", "EMAIL", "TEAM NAME", "USER STATUS", "SUBSCRIPTION STATUS"]];

    const data = pdfList?.map((elt) => coachActive ? [
      elt.customeid,
      elt.first_name,
      elt.last_name,
      elt.email,
      `${elt.status ? "Activated" : "DeActivated"}`,
      `${elt.is_subscribe && elt.is_subscribe ? "Active" : "InActive"}`,
    ] : staffActive ? [
      elt.customeid,
      elt.first_name,
      elt.last_name,
      elt.email,
      elt.team_name,
      `${elt.status ? "Activated" : "DeActivated"}`,
    ] : playerActive ? [
      elt.customeid,
      elt.first_name,
      elt.last_name,
      elt.email,
      elt.team_name,
      `${elt.jersy_no ? elt.jersy_no : "-"}`,
      `${elt.batting_style ? elt.batting_style : "-"}`,
      `${elt.throwing_style ? elt.throwing_style : "-"}`,
      `${elt.status ? "Activated" : "DeActivated"}`,
    ] : [
      elt.customeid,
      elt.first_name,
      elt.last_name,
      elt.email,
      elt.team_name,
      `${elt.status ? "Activated" : "DeActivated"}`,
      `${elt.is_subscribe && elt.is_subscribe ? "Active" : "InActive"}`,
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("report.pdf");
  };
  const closeModal = () => {
    setTeamResponsibility([]);
    setStaffsResponsibility([]);
  };
  const renderMenu = ({ left, top, className }, ref) => {
    return (
      <Popover ref={ref} className={className} style={{ left, top }} full>
        <Dropdown.Menu title="Create new file">
          <Dropdown.Item icon={<PageIcon />} eventKey={1}>
            <CSVLink
              data={csvList}
              target="_blank"
              filename={pdfTitle}
            >
              <button>CSV format ⬇</button>
            </CSVLink>
          </Dropdown.Item>
          <Dropdown.Item icon={<FileDownloadIcon />} eventKey={2}>
            <button onClick={() => exportPDF()}>PDF format ⬇</button>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Popover>
    );
  };

  // const renderMenu1 = ({ left, top, className }, ref) => {
  //   return (
  //     <Popover ref={ref} className={className} style={{ left, top }} full>
  //       <Dropdown.Menu title="Create new file">
  //         <Dropdown.Item eventKey={1} icon={<TagFilterIcon />}>
  //           <button onClick={handleChangeResponsibility} value="b">
  //             b
  //           </button>
  //         </Dropdown.Item>
  //         <Dropdown.Item eventKey={2} icon={<TagFilterIcon />}>
  //           <button onClick={handleChangeResponsibility} value="pc">
  //             pc
  //           </button>
  //         </Dropdown.Item>
  //         <Dropdown.Item eventKey={2} icon={<TagFilterIcon />}>
  //           <button onClick={handleChangeResponsibility} value="sc">
  //             sc
  //           </button>
  //         </Dropdown.Item>
  //         <Dropdown.Item eventKey={2} icon={<TagFilterIcon />}>
  //           <button onClick={handleChangeResponsibility} value="vs">
  //             vs
  //           </button>
  //         </Dropdown.Item>
  //       </Dropdown.Menu>
  //     </Popover>
  //   );
  // };

  const renderMenu2 = ({ left, top, className }, ref) => {
    return (
      <Popover ref={ref} className={className} style={{ left, top }} full>
        <Dropdown.Menu title="Create new file">
          <Dropdown.Item eventKey={1} icon={<UserBadgeIcon />}>
            <button onClick={handleChange} value="null">
              All
            </button>
          </Dropdown.Item>
          <Dropdown.Item eventKey={2} icon={<UserBadgeIcon />}>
            <button onClick={handleChange} value="true">
              Activated users
            </button>
          </Dropdown.Item>
          <Dropdown.Item eventKey={2} icon={<UserBadgeIcon />}>
            <button onClick={handleChange} value="false">
              Deactivated users
            </button>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Popover>
    );
  };

  return (
    <>
      <div className="container-fluid position-relative w-100 h-100">
        <div className="row h-100">
          <div
            className="col-sm-2 px-0 h-100 d-md-block d-none"
            style={{ width: "15%" }}
          >
            <Header />
          </div>
          <MobileNav />
          <div className="col-12 col-md-10 px-0 h-100">
            <div className="w-100 h-100 d-flex flex-column pb-3">
              <TopHeaderSection />
              <div className="container-fluid py-md-4 py-2 border-top overflow-auto flex-fill">
                <div className="row">
                  <div className="col">
                    <h5 className="mb-0">Users</h5>
                  </div>
                  <div className="col-auto ms-auto">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0"></ol>
                    </nav>
                  </div>

                  <div className="border rounded mt-3 ">
                    <div className="d-xl-flex align-items-center border-bottom p-1 col-12">
                      <div className="col-xl-8 col-12 px-0 d-md-flex d-flex-none">
                        <button
                          className={
                            coachActive === true
                              ? "btn w-10 mt-4 mb-3 tabs_user"
                              : "btn w-10 mt-4 mb-3 tabs_user"
                          }
                          onClick={() => {
                            setcoachActive(true);
                            setplayerActive(false);
                            setstaffActive(false);
                            setfansActive(false);
                            setSearchText("");
                            setAsc(false);
                            setActivetedUsers("null");
                            setResponsibilityShow(true);
                          }}
                          style={{ marginRight: "1vw" }}
                        >
                          <h6
                            className={
                              coachActive === true
                                ? "mb-0 fw-bold active-text-color"
                                : "mb-0 fw-bold noactive-text-color"
                            }
                          >
                            Coaches
                          </h6>
                        </button>

                        <button
                          className={
                            staffActive === true
                              ? "btn w-10 mt-4 mb-3 tabs_user"
                              : "btn w-10 mt-4 mb-3 tabs_user"
                          }
                          onClick={() => {
                            setcoachActive(false);
                            setplayerActive(false);
                            setstaffActive(true);
                            setfansActive(false);
                            setSearchText("");
                            setAsc(false);
                            setActivetedUsers("null");

                            setResponsibilityShow(true);
                          }}
                          style={{ marginRight: "1vw" }}
                        >
                          <h6
                            className={
                              staffActive === true
                                ? "mb-0 fw-bold active-text-color"
                                : "mb-0 fw-bold noactive-text-color"
                            }
                          >
                            Staff
                          </h6>
                        </button>
                        <button
                          className={
                            playerActive === true
                              ? "btn w-10 mt-4 mb-3 tabs_user"
                              : "btn w-10 mt-4 mb-3 tabs_user"
                          }
                          onClick={() => {
                            setcoachActive(false);
                            setplayerActive(true);
                            setstaffActive(false);
                            setfansActive(false);
                            setSearchText("");
                            setAsc(false);
                            setActivetedUsers("null");
                            setResponsibilityShow(false);
                          }}
                          style={{ marginRight: "1vw" }}
                        >
                          <h6
                            className={
                              playerActive === true
                                ? "mb-0 fw-bold active-text-color"
                                : "mb-0 fw-bold noactive-text-color"
                            }
                          >
                            Players
                          </h6>
                        </button>
                        <button
                          className={
                            fansActive === true
                              ? "btn w-10 mt-4 mb-3 tabs_user"
                              : "btn w-10 mt-4 mb-3 tabs_user"
                          }
                          onClick={() => {
                            setcoachActive(false);
                            setplayerActive(false);
                            setstaffActive(false);
                            setfansActive(true);
                            setSearchText("");
                            setAsc(false);
                            setActivetedUsers("null");
                            setResponsibilityShow(false);
                          }}
                        >
                          <h6
                            className={
                              fansActive === true
                                ? "mb-0 fw-bold active-text-color"
                                : "mb-0 fw-bold noactive-text-color"
                            }
                          >
                            Fans
                          </h6>
                        </button>
                        <div className="d-xl-none d-flex ml-auto justify-content-center">
                          <CommonSearchBox
                            searchText={searchText}
                            setSearchText={setSearchText}
                          />
                        </div>
                      </div>
                      <div className="col-xl-4 col-12 pe-md-0 d-md-flex justify-content-end align-items-center">
                        <div className="ms-md-3 d-none d-xl-flex">
                          <CommonSearchBox
                            searchText={searchText}
                            setSearchText={setSearchText}
                          />
                        </div>

                        <center>
                          <div style={{ padding: 10 }}>
                            <Whisper
                              placement="bottomStart"
                              trigger="click"
                              speaker={renderMenu2}
                            >
                              <IconButton
                                appearance="primary"
                                color='cyan'
                                icon={<UserBadgeIcon />}
                              >
                                User Status
                              </IconButton>
                            </Whisper>
                          </div>
                        </center>

                        {/* {responsibilityShow === true ? (
                          <center>
                            <div style={{ padding: 10 }}>
                              <Whisper
                                placement="bottomStart"
                                trigger="click"
                                speaker={renderMenu1}
                              >
                                <IconButton
                                  appearance="primary"
                                  color='violet'
                                  icon={<TagFilterIcon />}
                                >
                                  Responsibility
                                </IconButton>
                              </Whisper>
                            </div>
                          </center>
                        ) : null} */}

                        <center>
                          <div style={{ padding: 10 }}>
                            <Whisper
                              placement="bottomStart"
                              trigger="click"
                              speaker={renderMenu}
                            >
                              <IconButton
                                appearance="primary"
                                color="blue"
                                icon={<PageIcon />}
                              >
                                Export report
                              </IconButton>
                            </Whisper>
                          </div>
                        </center>
                      </div>
                    </div>

                    {coachActive === true ? (
                      <>
                        {loading === false ? (
                          <div className="row p-0 gx-0 mt-3">
                            <div className="col p-1">
                              <div className="table-responsive">
                                {coachList.length > 0 ? (
                                  <>
                                    <table className="table mb-0">
                                      <thead className="text-nowrap">
                                        <tr className="bg-light">
                                          <th>S No.</th>
                                          <th>Unique Id</th>
                                          <th>
                                            {asc === false ? (
                                              <i
                                                className="fa fa-arrow-up cursor-pointer"
                                                onClick={() => setAsc(!asc)}
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-arrow-down cursor-pointer"
                                                onClick={() => setAsc(!asc)}
                                              ></i>
                                            )}{" "}
                                            F Name
                                          </th>
                                          <th>L Name</th>
                                          <th>Email</th>
                                          <th>Subscription Status</th>
                                          <th>Team Details</th>
                                        </tr>
                                      </thead>
                                      {coachList
                                        ?.slice(
                                          indexOfFirstPostForCoach,
                                          indexOfLastPostForCoach
                                        )
                                        ?.map((item, index) => {
                                          return (
                                            <>
                                              <tbody
                                                key={index}
                                                className={
                                                  item.status === true
                                                    ? "table-row-active text-nowrap"
                                                    : "table-row-deactive text-nowrap"
                                                }
                                              >
                                                <tr>
                                                  <td>
                                                    {(
                                                      (page.currentPageForCoach -
                                                        1) *
                                                      10 +
                                                      index +
                                                      1
                                                    )
                                                      .toString()
                                                      .padStart(2, "0")}
                                                  </td>
                                                  <td>{item.customeid}</td>
                                                  <td>{item.first_name}</td>
                                                  <td>{item.last_name}</td>
                                                  <td>{item.email}</td>
                                                  {item.is_subscribe ?
                                                    <td style={{
                                                      color: "green",
                                                      fontWeight: "bold",
                                                    }}> Active</td>
                                                    :
                                                    <td style={{
                                                      color: "red",
                                                      fontWeight: "bold",
                                                    }}>InActive</td>}
                                                  <td><button type="button" class="btn btn-primary" data-toggle="modal" data-target="#myModal" onClick={() => { teamResponsibilty(item.email) }}>View</button></td>


                                                </tr>
                                              </tbody>

                                            </>
                                          );
                                        })}
                                    </table>
                                    <ActiveDeacticeColor />
                                  </>
                                ) : (
                                  <NoData data={"No coaches found"} />
                                )}
                              </div>
                            </div>

                            {coachList.length > 10 && (
                              <Pagination
                                itemsPerPage={itemsPerPage}
                                totalItems={coachList.length}
                                paginate={paginate}
                                currentPage={page?.currentPageForCoach}
                                type="coach"
                                setPrevBtn={() =>
                                  setPage({
                                    ...page,
                                    currentPageForCoach:
                                      page.currentPageForCoach - 1,
                                  })
                                }
                                setNextBtn={() =>
                                  setPage({
                                    ...page,
                                    currentPageForCoach:
                                      page.currentPageForCoach + 1,
                                  })
                                }
                                searchText={searchText}
                              />
                            )}
                          </div>
                        ) : (
                          <Contentloader />
                        )}
                      </>
                    ) : (
                      ""
                    )}

                    {staffActive === true ? (
                      <>
                        {loading === false ? (
                          <div className="row p-0 gx-0 mt-3">
                            <div className="col p-1">
                              <div className="table-responsive">
                                {staffsList.length > 0 ? (
                                  <>
                                    <table className="table mb-0">
                                      <thead className="text-nowrap">
                                        <tr className="bg-light">
                                          <th>S No.</th>
                                          <th>Unique Id</th>
                                          <th>
                                            {" "}
                                            {asc === false ? (
                                              <i
                                                className="fa fa-arrow-up cursor-pointer"
                                                onClick={() => setAsc(!asc)}
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-arrow-down cursor-pointer"
                                                onClick={() => setAsc(!asc)}
                                              ></i>
                                            )}{" "}
                                            F Name
                                          </th>
                                          <th>L Name</th>
                                          <th>Email</th>
                                          <th>Team Details</th>
                                          {/* <th>Team Name</th>
                                          <th>Responsibility</th>
                                          <th>User Status</th> */}
                                        </tr>
                                      </thead>
                                      {staffsList
                                        ?.slice(
                                          indexOfFirstPostForStaff,
                                          indexOfLastPostForStaff
                                        )
                                        ?.map((item, index) => {
                                          return (
                                            <>
                                              <tbody
                                                className={
                                                  item.status === true
                                                    ? "table-row-active text-nowrap"
                                                    : "table-row-deactive text-nowrap"
                                                }
                                              >
                                                <tr>
                                                  <td>
                                                    {(
                                                      (page.currentPageForStaff -
                                                        1) *
                                                      10 +
                                                      (index + 1)
                                                    )
                                                      .toString()
                                                      .padStart(2, "0")}
                                                  </td>
                                                  <td>{item.customeid}</td>
                                                  <td>{item.first_name}</td>
                                                  <td>{item.last_name}</td>
                                                  <td>{item.email}</td>
                                                  <td><button type="button" class="btn btn-primary" data-toggle="modal" data-target="#myModal" onClick={() => { staffResponsibilty(item.email) }}>View</button></td>
                                                  {/* <td>{item.team_name}</td>
                                                  <td>
                                                    b
                                                    <input
                                                      type="checkbox"
                                                      checked={
                                                        item.responsibility
                                                          .b === true
                                                          ? true
                                                          : false
                                                      }
                                                      style={{
                                                        marginLeft: 2,
                                                        marginRight: 5,
                                                      }}
                                                      readOnly={true}
                                                    />
                                                    pc
                                                    <input
                                                      type="checkbox"
                                                      checked={
                                                        item.responsibility
                                                          .pc === true
                                                          ? true
                                                          : false
                                                      }
                                                      style={{
                                                        marginLeft: 2,
                                                        marginRight: 5,
                                                      }}
                                                      readOnly={true}
                                                    />
                                                    sc
                                                    <input
                                                      type="checkbox"
                                                      checked={
                                                        item.responsibility
                                                          .sc === true
                                                          ? true
                                                          : false
                                                      }
                                                      style={{
                                                        marginLeft: 2,
                                                        marginRight: 5,
                                                      }}
                                                      readOnly={true}
                                                    />
                                                    vs
                                                    <input
                                                      type="checkbox"
                                                      checked={
                                                        item.responsibility
                                                          .vs === true
                                                          ? true
                                                          : false
                                                      }
                                                      style={{ marginLeft: 2 }}
                                                      readOnly={true}
                                                    />
                                                  </td>
                                                  <td
                                                    style={{ width: "100px" }}
                                                  >
                                                    <div style={{ height: 0 }}>
                                                      {item.status === true ? (
                                                        <ActiveToggleButton
                                                          // label1=" Deactivated"
                                                          // label2=" Activated"
                                                          id={item._id}
                                                          role="staff"
                                                          firstName={
                                                            item.first_name
                                                          }
                                                          lastName={
                                                            item.last_name
                                                          }
                                                          teamName={
                                                            item.team_name
                                                          }
                                                        />
                                                      ) : (
                                                        <InActiveToggleButton
                                                          // label1=" Deactivated"
                                                          // label2=" Activated"
                                                          id={item._id}
                                                          role="staff"
                                                          firstName={
                                                            item.first_name
                                                          }
                                                          lastName={
                                                            item.last_name
                                                          }
                                                        />
                                                      )}
                                                    </div>
                                                  </td> */}
                                                </tr>
                                              </tbody>
                                            </>
                                          );
                                        })}
                                    </table>
                                    <ActiveDeacticeColor />
                                  </>
                                ) : (
                                  <NoData data={"No staffs found"} />
                                )}
                              </div>
                            </div>

                            {staffsList.length > 10 && (
                              <Pagination
                                itemsPerPage={itemsPerPage}
                                totalItems={staffsList.length}
                                paginate={paginate}
                                currentPage={page?.currentPageForStaff}
                                type="staff"
                                setPrevBtn={() =>
                                  setPage({
                                    ...page,
                                    currentPageForStaff:
                                      page.currentPageForStaff - 1,
                                  })
                                }
                                setNextBtn={() =>
                                  setPage({
                                    ...page,
                                    currentPageForStaff:
                                      page.currentPageForStaff + 1,
                                  })
                                }
                                searchText={searchText}
                              />
                            )}
                          </div>
                        ) : (
                          <Contentloader />
                        )}
                      </>
                    ) : (
                      ""
                    )}

                    {playerActive === true ? (
                      <>
                        {loading === false ? (
                          <div className="row p-0 gx-0 mt-3">
                            <div className="col p-1">
                              <div className="table-responsive">
                                {playerList.length > 0 ? (
                                  <>
                                    <table className="table mb-0">
                                      <thead className="text-nowrap">
                                        <tr className="bg-light">
                                          <th>S No.</th>
                                          <th>Unique Id</th>
                                          <th>
                                            {" "}
                                            {asc === false ? (
                                              <i
                                                className="fa fa-arrow-up cursor-pointer"
                                                onClick={() => setAsc(!asc)}
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-arrow-down cursor-pointer"
                                                onClick={() => setAsc(!asc)}
                                              ></i>
                                            )}{" "}
                                            F Name
                                          </th>
                                          <th>L Name</th>
                                          <th>Email</th>
                                          <th>Team Name</th>
                                          <th>Jersey #</th>
                                          <th>Batting </th>
                                          <th>Throwing </th>
                                          <th>User Status</th>
                                        </tr>
                                      </thead>
                                      {playerList
                                        ?.slice(
                                          indexOfFirstPostForPlayer,
                                          indexOfLastPostForPlayer
                                        )
                                        ?.map((item, index) => {
                                          return (
                                            <>
                                              <tbody
                                                className={
                                                  item.status === true
                                                    ? "table-row-active text-nowrap"
                                                    : "table-row-deactive text-nowrap"
                                                }
                                              >
                                                <tr>
                                                  <td>
                                                    {(
                                                      (page.currentPageForPlayer -
                                                        1) *
                                                      10 +
                                                      (index + 1)
                                                    )
                                                      .toString()
                                                      .padStart(2, "0")}
                                                  </td>
                                                  <td>{item.customeid}</td>
                                                  <td>{item.first_name}</td>
                                                  <td>{item.last_name}</td>
                                                  <td>{item.email}</td>
                                                  <td>{item.team_name}</td>
                                                  <td>{item.jersy_no}</td>
                                                  <td>{item.batting_style}</td>
                                                  <td>{item.throwing_style}</td>

                                                  <td
                                                    style={{ width: "100px" }}
                                                  >
                                                    <div style={{ height: 0 }}>
                                                      {item.status === true ? (
                                                        <ActiveToggleButton
                                                          // label1=" Deactivated"
                                                          // label2=" Activated"
                                                          id={item._id}
                                                          role="player"
                                                          firstName={
                                                            item.first_name
                                                          }
                                                          lastName={
                                                            item.last_name
                                                          }
                                                          teamName={
                                                            item.team_name
                                                          }
                                                        />
                                                      ) : (
                                                        <InActiveToggleButton
                                                          // label1=" Deactivated"
                                                          // label2=" Activated"
                                                          id={item._id}
                                                          role="player"
                                                          firstName={
                                                            item.first_name
                                                          }
                                                          lastName={
                                                            item.last_name
                                                          }
                                                        />
                                                      )}
                                                    </div>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </>
                                          );
                                        })}
                                    </table>
                                    <ActiveDeacticeColor />
                                  </>
                                ) : (
                                  <NoData data={"No players found"} />
                                )}
                              </div>
                            </div>
                            {playerList.length > 10 && (
                              <Pagination
                                itemsPerPage={itemsPerPage}
                                totalItems={playerList.length}
                                paginate={paginate}
                                currentPage={page?.currentPageForPlayer}
                                type="player"
                                setPrevBtn={() =>
                                  setPage({
                                    ...page,
                                    currentPageForPlayer:
                                      page.currentPageForPlayer - 1,
                                  })
                                }
                                setNextBtn={() =>
                                  setPage({
                                    ...page,
                                    currentPageForPlayer:
                                      page.currentPageForPlayer + 1,
                                  })
                                }
                                searchText={searchText}
                              />
                            )}
                          </div>
                        ) : (
                          <Contentloader />
                        )}
                      </>
                    ) : (
                      ""
                    )}

                    {fansActive === true ? (
                      <>
                        {loading === false ? (
                          <div className="row p-0 gx-0 mt-3">
                            <div className="col p-1">
                              <div className="table-responsive">
                                {FansList.length > 0 ? (
                                  <>
                                    <table className="table mb-0">
                                      <thead className="text-nowrap">
                                        <tr className="bg-light">
                                          <th>S No.</th>
                                          <th>Unique Id</th>
                                          <th>
                                            {asc === false ? (
                                              <i
                                                className="fa fa-arrow-up cursor-pointer"
                                                onClick={() => setAsc(!asc)}
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-arrow-down cursor-pointer"
                                                onClick={() => setAsc(!asc)}
                                              ></i>
                                            )}{" "}
                                            F Name
                                          </th>
                                          <th>L Name</th>
                                          <th>Email</th>
                                          <th>Team Name</th>
                                          <th>Subscription Status</th>
                                          <th>User Status</th>
                                        </tr>
                                      </thead>
                                      {FansList?.slice(
                                        indexOfFirstPostForFans,
                                        indexOfLastPostForFans
                                      )?.map((item, index) => {
                                        return (
                                          <>
                                            <tbody
                                              className={
                                                item.status === true
                                                  ? "table-row-active text-nowrap"
                                                  : "table-row-deactive text-nowrap"
                                              }
                                            >
                                              <tr>
                                                <td>
                                                  {(
                                                    (page.currentPageForFans -
                                                      1) *
                                                    10 +
                                                    index +
                                                    1
                                                  )
                                                    .toString()
                                                    .padStart(2, "0")}
                                                </td>
                                                <td>{item.customeid}</td>
                                                <td>{item.first_name}</td>
                                                <td>{item.last_name}</td>
                                                <td>{item.email}</td>
                                                <td>{item.team_name}</td>
                                                {item.is_subscribe ?
                                                  <td style={{
                                                    color: "green",
                                                    fontWeight: "bold",
                                                  }}> Active</td>
                                                  :
                                                  <td style={{
                                                    color: "red",
                                                    fontWeight: "bold",
                                                  }}>InActive</td>}
                                                <td style={{ width: "100px" }}>
                                                  <div style={{ height: 0 }}>
                                                    {item.status === true ? (
                                                      <ActiveToggleButton
                                                        // label1=" Deactivated"
                                                        // label2=" Activated"
                                                        id={item._id}
                                                        role="fans"
                                                        firstName={
                                                          item.first_name
                                                        }
                                                        lastName={
                                                          item.last_name
                                                        }
                                                        teamName={
                                                          item.team_name
                                                        }
                                                      />
                                                    ) : (
                                                      <InActiveToggleButton
                                                        // label1=" Deactivated"
                                                        // label2=" Activated"
                                                        id={item._id}
                                                        role="fans"
                                                        firstName={
                                                          item.first_name
                                                        }
                                                        lastName={
                                                          item.last_name
                                                        }
                                                      />
                                                    )}
                                                  </div>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </>
                                        );
                                      })}
                                    </table>
                                    <ActiveDeacticeColor />
                                  </>
                                ) : (
                                  <NoData data={"No fans found"} />
                                )}
                              </div>
                            </div>
                            {FansList.length > 10 && (
                              <Pagination
                                itemsPerPage={itemsPerPage}
                                totalItems={FansList.length}
                                paginate={paginate}
                                currentPage={page?.currentPageForFans}
                                type="fan"
                                setPrevBtn={() =>
                                  setPage({
                                    ...page,
                                    currentPageForFans:
                                      page.currentPageForFans - 1,
                                  })
                                }
                                setNextBtn={() =>
                                  setPage({
                                    ...page,
                                    currentPageForFans:
                                      page.currentPageForFans + 1,
                                  })
                                }
                                searchText={searchText}
                              />
                            )}
                          </div>
                        ) : (
                          <Contentloader />
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <>
        {(coachActive || staffActive) && <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">

              <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" onClick={closeModal}>&times;</button>
              </div>
              {loading ?
                <>
                  <div class="modal-body">
                    <div className=" d-flex align-items-center justify-content-center">
                      <img src="assets/icons/loading.gif" alt="" style={{ height: "35px", width: "35px" }}></img>
                    </div>
                  </div>
                </> :
                <>
                  <div class="modal-body">
                    {((coachActive && teamResponsibility) || (staffActive && staffsResponsibility)).length !== 0 ? (<table className="table mb-0">
                      <thead className="text-nowrap">
                        <tr className="bg-light">
                          <th>Team Name</th>
                          <th>Responsibility</th>
                          <th>User Status</th>
                        </tr>
                      </thead>
                      {((coachActive && teamResponsibility) || (staffActive && staffsResponsibility)).map((item, index) => (
                        <tbody>
                          <tr>
                            <td>{item.team_name}</td>
                            <td className="d-flex justify-content-between">
                              <div className="d-flex align-items-center">
                                <span>b</span>
                                <input
                                  type="checkbox"
                                  checked={
                                    item.responsibility
                                      .b === true
                                      ? true
                                      : false
                                  }
                                  style={{
                                    marginLeft: 4,
                                    marginRight: 8,
                                  }}
                                  readOnly={true}
                                />
                              </div>
                              <div className="d-flex align-items-center">
                                <span>pc</span>
                                <input
                                  type="checkbox"
                                  checked={
                                    item.responsibility
                                      .pc === true
                                      ? true
                                      : false
                                  }
                                  style={{
                                    marginLeft: 4,
                                    marginRight: 8,
                                  }}
                                  readOnly={true}
                                />
                              </div>
                              <div className="d-flex align-items-center">
                                <span>sc</span>
                                <input
                                  type="checkbox"
                                  checked={
                                    item.responsibility
                                      .sc === true
                                      ? true
                                      : false
                                  }
                                  style={{
                                    marginLeft: 4,
                                    marginRight: 8,
                                  }}
                                  readOnly={true}
                                />
                              </div>
                              <div className="d-flex align-items-center">
                                <span>vs</span>
                                <input
                                  type="checkbox"
                                  checked={
                                    item.responsibility
                                      .vs === true
                                      ? true
                                      : false
                                  }
                                  style={{
                                    marginLeft: 4,
                                  }}
                                  readOnly={true}
                                />
                              </div>
                            </td>
                            <td
                              style={{ width: "100px" }}
                            >
                              <div style={{ height: 0 }}>
                                {item.status === true ? (
                                  <ActiveToggleButton
                                    // label1=" Deactivated"
                                    // label2=" Activated"
                                    id={item._id}
                                    role={coachActive ? "coach" : "staff"}
                                    firstName={
                                      item.first_name
                                    }
                                    lastName={
                                      item.last_name
                                    }
                                    teamName={
                                      item.team_name
                                    }
                                    responsibility={[
                                      item.responsibility
                                        .b,
                                      item.responsibility
                                        .pc,
                                      item.responsibility
                                        .sc,
                                      item.responsibility
                                        .vs,
                                    ]}
                                  />
                                ) : (
                                  <InActiveToggleButton
                                    // label1=" Deactivated"
                                    // label2=" Activated"
                                    id={item._id}
                                    role={coachActive ? "coach" : "staff"}
                                    firstName={
                                      item.first_name
                                    }
                                    lastName={
                                      item.last_name
                                    }
                                  />
                                )}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      ))}

                    </table>) : (<div className="text-center">No Data Found</div>)}




                  </div>
                </>
              }
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" onClick={closeModal}>Close</button>
              </div>

            </div>
          </div>
        </div>}
      </>
    </>
  );
};

export default UserMetrics;
