import React, { useState } from "react";
import moment from "moment";
import NoData from "../../components/Nodata";

const Transcation = ({ data, page }) => {
  const [asc, setAsc] = useState(false);
  return (
    <div className="col border-top">
      <div className="table-responsive mt-3 mb-3">
        {data.length > 0 ? (
          <table className="table mb-0">
            <thead className="text-nowrap">
              <tr className="bg-light">
                <th>S No.</th>
                <th>Name</th>
                <th>Email</th>
                <th>Price</th>
                <th>Payment Method</th>
                <th>Subscription Type</th>
                <th>
                  {asc === false ? (
                    <i
                      className="fa fa-arrow-up cursor-pointer me-1"
                      onClick={() => setAsc(!asc)}
                    />
                  ) : (
                    <i
                      className="fa fa-arrow-down cursor-pointer me-1"
                      onClick={() => setAsc(!asc)}
                    />
                  )}
                  Transaction Date
                </th>
                <th>Renewal Date</th>
                <th>Account Username</th>
              </tr>
            </thead>
            {data
              .sort((a, b) => {
                if (
                  a.data.startDate.toLowerCase() <
                  b.data.startDate.toLowerCase()
                ) {
                  return asc === false ? 1 : -1;
                }
                if (
                  a.data.startDate.toLowerCase() >
                  b.data.startDate.toLowerCase()
                ) {
                  return asc === false ? -1 : 1;
                }

                return 0;
              })

              .map((item, index) => {
                return (
                  <>
                    <tbody key={index}>
                      <tr>
                        <td>
                          {(
                            (page.currentPageForTransaction - 1) * 10 +
                            index +
                            1
                          )
                            .toString()
                            .padStart(2, "0")}
                        </td>
                        <td>{item.username}</td>
                        <td>{item.email}</td>
                        <td>{`$${
                          item.data?.payment_method === "STRIPE"
                            ? item.data.amount / 100
                            : item.data.amount
                        }`}</td>
                        <td>
                          {item.data?.payment_method?.charAt(0).toUpperCase() +
                            item.data?.payment_method?.slice(1).toLowerCase()}
                        </td>
                        <td>
                          {item.data.plan.interval === "month" &&
                          item.data.plan.interval_count >= 2
                            ? "Season"
                            : item.data.plan.interval === "year"
                            ? "Annual"
                            : "Monthly"}
                        </td>
                        <td>
                          {moment(item.data.startDate.split("T")[0]).format(
                            "MM/DD/YYYY"
                          )}
                        </td>
                        <td>
                          {moment(item.data.endDate.split("T")[0]).format(
                            "MM/DD/YYYY"
                          )}
                        </td>
                        <td>{item.username}</td>
                      </tr>
                    </tbody>
                  </>
                );
              })}
          </table>
        ) : (
          <div className="pt-3">
            <NoData data={"No transactions found"} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Transcation;
