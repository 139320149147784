import React, { useState } from "react";
import LogoutPopup from "./LogoutPopup";

const Footer = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <footer className="text-center text-lg-start bg-light text-muted">
        <section
          className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
        >
          <div className="me-5 d-none d-lg-block">
            <nav
              className="d-flex flex-column"
              onClick={() => setShowModal(true)}
              // style={{ bottom: 20, position: "fixed" }}
            >
              <div className="my-2 d-flex text-black-50 cursor-pointer">
                <img
                  src="../assets/icons/logout.png"
                  className="nav-icon align-self-center  me-2"
                  alt=""
                  style={{ width: "1.5vw", minWidth: "1.5vw" }}
                />
                <span
                  className="align-self-center"
                  style={{ fontSize: "1.2vw", color: "#000" }}
                >
                  Logout
                </span>
              </div>
            </nav>
            {showModal && <LogoutPopup setShowModal={setShowModal} />}

            <span>
              © 2023 EC Frazier & Associates Inc, Inc. All rights reserved
            </span>
          </div>

          <div>
            <a href="#." className="me-4 text-reset">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="#." className="me-4 text-reset">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="#." className="me-4 text-reset">
              <i className="fab fa-google"></i>
            </a>
            <a href="#." className="me-4 text-reset">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="#." className="me-4 text-reset">
              <i className="fab fa-linkedin"></i>
            </a>
            <a href="#." className="me-4 text-reset">
              <i className="fab fa-github"></i>
            </a>
          </div>
        </section>
      </footer>
    </>
  );
};

export default Footer;
