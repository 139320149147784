import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../../src/view/Login";
import ForgotPassword from "../view/ForgotPassword";
import Dashboard from "../view/Dashboard";

let UserToken = JSON.parse(localStorage.getItem("userToken"))?.access_token;

function UnAuth() {
  return (
    <Routes>
      <Route path="/" element={UserToken ? <Dashboard /> : <Login />} />
      {/* <Route path="/changepassword" element={<ChangePassword />} /> */}
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      {/* <Route path="/error" element={<Error />} /> */}
    </Routes>
  );
}

export default UnAuth;
