import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import "react-datepicker/dist/react-datepicker.css";
import Header from "../../components/Header";
import MobileNav from "../../components/MobileNav";
import TopHeaderSection from "../../components/TopHeaderSection";
import Footer from "../../components/Footer";
import Pagination from "../../components/Pagination";
import Contentloader from "../../components/ContentLoader";
import PricingHeader from "./PricingHeader";
import Transcation from "./Transcation";
import CommonSearchBox from "../../components/CommonSearchBox";
import {
  deletePromo,
  getPromo,
  getSubscriptionPlan,
  getTransactionDetails,
  postPromo,
} from "../../service/ApiClient";
import jsPDF from "jspdf";

const Pricing = () => {
  const promoInitialState = {
    promoCode: "",
    promoDiscountType: "Discount Type",
    promoPercentage: "",
    promoAmount: "",
    promoDuration: "Once",
    promoNumberOfMonths: "",
    promoDescription: "",
  };
  const [page, setPage] = useState({
    currentPageForTransaction: 1,
  });
  const [loading, setLoading] = useState(false);
  const [typeOfTabs, setTypeOfTabs] = useState("pricing");
  const [searchText, setSearchText] = useState("");
  const [promoModalOpen, setPromoModalOpen] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [formatFromDate, setFormatFromDate] = useState("");
  const [formatToDate, setFormatToDate] = useState("");
  const [offset, setOffset] = useState(0);
  const [addPromoState, setAddPromoState] = useState(promoInitialState);
  const [promoData, setPromoData] = useState([]);
  const [pricingData, setPricingData] = useState([]);
  const [coachPricingObjects, setCoachPricingObjects] = useState([]);
  const [fanPricingObjects, setFanPricingObjects] = useState([]);
  const [transactionDetailsData, setTransactionDetailsData] = useState([]);
  const [clear, setClear] = useState(false);
  const [pdfData, setPdfData] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [filter, setFilter] = useState(false);
  const fetchPricingData = async () => {
    setLoading(true);
    try {
      const response = await getSubscriptionPlan(
        "v1/user/admin/get_subscription_plans"
      );
      setPricingData(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const onClickPromoSubmit = async () => {
    setPromoModalOpen(false);
    const name = addPromoState.promoCode;
    const type = addPromoState.promoDiscountType;
    const amount = addPromoState.promoPercentage || addPromoState.promoAmount;
    const duration = addPromoState.promoDuration;
    const duration_in_months = addPromoState.promoNumberOfMonths;
    const description = addPromoState.promoDescription;
    setLoading(true);
    try {
      await postPromo(
        "/v1/user/admin/create_promocodes",
        name,
        type === "Percentage" ? "percentage" : "amount",
        amount,
        duration === "Once"
          ? "once"
          : duration === "Forever"
          ? "forever"
          : "repeating",
        duration === "Repeating Month" ? duration_in_months : undefined,
        description.length > 0 ? description : undefined
      );
      fetchPromoData();
      setAddPromoState({
        ...addPromoState,
        promoCode: "",
        promoDiscountType: "Discount Type",
        promoPercentage: "",
        promoAmount: "",
        promoDuration: "Once",
        promoNumberOfMonths: "",
        promoDescription: "",
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchPromoData = async () => {
    setLoading(true);
    try {
      const response = await getPromo("/v1/user/admin/get_promocodes");
      setPromoData(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const onClickDeleteButton = async (item) => {
    setLoading(true);
    try {
      const id = item?.id;
      await deletePromo("/v1/user/admin/delete_promocodes", id);
      fetchPromoData();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchTransactionDetailsData = async () => {
    setLoading(true);
    if (clear) {
      setFromDate("");
      setToDate("");
      setFormatToDate("");
      setFormatFromDate("");
    }
    try {
      const response = await getTransactionDetails(
        `/v1/user/admin/get_trancastion_details?offset=${offset}&limit=${10}${
          searchText && `&search_keyword=${searchText}`
        }${
          fromDate &&
          toDate &&
          `&from_date=${formatFromDate}&end_date=${formatToDate}`
        }`
      );
      setTransactionDetailsData(response.data.data);
      setClear(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchPDFTransactionDetails = async () => {
    try {
      const response = await getTransactionDetails(
        `/v1/user/admin/pdf_trancastion_details?${
          searchText && `&search_keyword=${searchText}`
        }${
          fromDate &&
          toDate &&
          `&from_date=${formatFromDate}&end_date=${formatToDate}`
        }`
      );
      setPdfData(response?.data?.data);
      setCsvData(
        response?.data?.data.map((item) => ({
          USERNAME: item.username,
          AMOUNT: `$${item.amount}`,
          PAYMENT_METHOD: `${
            item.data?.payment_method?.charAt(0).toUpperCase() +
            item.data?.payment_method?.slice(1).toLowerCase()
          }`,
          SUBSCRIPTION_TYPE: item.type,
          TRANSACTION_DATE: item.transaction_date,
          RENEWAL_DATE: item.renew_date,
          ACCOUNT_USERNAME: item.username,
        }))
      );
    } catch (error) {}
  };

  useEffect(() => {
    fetchPDFTransactionDetails();
  }, [typeOfTabs, searchText]);

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Transaction Details";
    const headers = [
      [
        "S.No.",
        "NAME",
        "PRICE",
        "PAYMENT METHOD",
        "SUBSCRIPTION TYPE",
        "TRANSACTION DATE",
        "RENEWAL DATE",
        "ACCOUNT USERNAME",
      ],
    ];

    const data = pdfData?.map((elt, index) => [
      `${(index + 1).toString().padStart(2, "0")}`,
      elt.username,
      `$${elt.amount}`,
      `${
        elt.data?.payment_method?.charAt(0).toUpperCase() +
        elt.data?.payment_method?.slice(1).toLowerCase()
      }`,
      elt.type,
      elt.transaction_date,
      elt.renew_date,
      elt.username,
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("Transactions.pdf");
  };

  useEffect(() => {
    if (typeOfTabs === "pricing") {
      fetchPricingData();
    } else if (typeOfTabs === "promo") {
      fetchPromoData();
    } else {
      fetchTransactionDetailsData();
    }
  }, [offset, typeOfTabs, searchText, clear]);

  useEffect(() => {
    if (
      (page.currentPageForTransaction > 1 && searchText) ||
      (formatFromDate && formatToDate)
    ) {
      setOffset(0);
      setPage({
        ...page,
        currentPageForTransaction: 1,
      });
    }
  }, [searchText, clear, filter]);

  useEffect(() => {
    const coachObjects = [];
    const fanObjects = [];
    pricingData.forEach((item) => {
      if (item.type === "coach") {
        coachObjects.push(item);
      } else if (item.type === "fan") {
        fanObjects.push(item);
      }
    });
    setCoachPricingObjects(coachObjects);
    setFanPricingObjects(fanObjects);
  }, [pricingData]);

  const paginate = async (number, type) => {
    if (type === "transaction") {
      setPage((prevPage) => ({
        ...prevPage,
        currentPageForTransaction: number,
      }));
      const newOffset = (number - 1) * 10;
      setOffset(newOffset);
    }
  };

  const RenderPricingData = ({ data }) => {
    return (
      <>
        {data.map((item) => (
          <div
            className="col-md-12 col-lg-6 col-xl-4 p-0 p-sm-3 py-2"
            key={item.title}
          >
            <div className="border rounded-3 p-3 h-100">
              <div className="d-flex border-bottom align-items-center pb-3">
                <Icon
                  icon="fa6-solid:circle-dollar-to-slot"
                  color="#005dab"
                  width="40"
                  height="40"
                />
                <div className="d-flex align-items-center justify-content-center m-auto">
                  <div>
                    <h5 className="fw-bold m-0 text-center">{item.title}</h5>
                    <div className="d-flex">
                      <h4 className="fw-bold me-1">{`$${item.amount}`}</h4>
                      {item.title === "Season Pass" ? (
                        <span className="fs-5">{`per ${item.interval} ${item.billingCycle}`}</span>
                      ) : (
                        <span className="fs-5">{`per ${item.billingCycle}`}</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-3 d-flex">
                <Icon
                  icon="teenyicons:tick-circle-solid"
                  color="green"
                  width="15"
                  height="15"
                />
                <h6 className="ms-1">
                  {`Valid for ${item.interval} ${item.billingCycle} from date of subscription`}
                </h6>
              </div>
            </div>
          </div>
        ))}
      </>
    );
  };

  const RenderPromoData = ({ data }) => {
    return (
      <>
        {data.map((item) => (
          <div
            className="col-md-12 col-lg-6 col-xl-4 p-0 p-sm-3 py-2"
            key={item.Sno}
          >
            <div className="border rounded-3 p-3 h-100 d-flex justify-content-center flex-column">
              <div className="d-flex align-items-center justify-content-between pb-3">
                <Icon
                  icon="fa6-solid:circle-dollar-to-slot"
                  color="#005dab"
                  width="40"
                  height="40"
                />
                <div>
                  <h4 className="fw-bold m-0 text-center">
                    {item.promo_code?.code}
                  </h4>
                  <div className="d-sm-flex justify-content-center">
                    {item.type === "percentage" ? (
                      <h6 className="fw-bold me-1 text-center">
                        {`Save ${item.amount} %`}
                      </h6>
                    ) : (
                      <h6 className="fw-bold text-center">
                        {`Save ${item.amount} $`}
                      </h6>
                    )}
                    {item.duration_in_months && (
                      <h6 className="ms-1">{`( valid for ${item.duration_in_months} months )`}</h6>
                    )}
                  </div>
                  <h6 className="fw-bold text-center">
                    {item.duration[0].toUpperCase() + item.duration.slice(1)}
                  </h6>
                </div>
                <div
                  className="d-flex position-relative"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <Icon
                    style={{ cursor: "pointer" }}
                    icon="iconamoon:menu-kebab-vertical-bold"
                    color="#005dab"
                    width="30"
                    height="30"
                  />
                </div>
                <ul
                  className="dropdown-menu from-control"
                  aria-labelledby="profile-dropdown"
                  style={{
                    backgroundColor: "#F2F7FF",
                  }}
                >
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => onClickDeleteButton(item)}
                    >
                      Delete
                    </button>
                  </li>
                </ul>
              </div>
              {item.description && (
                <div className="pt-3 px-4 d-flex border-top">
                  <div
                    style={{
                      height: "15px",
                      width: "15px",
                    }}
                  >
                    <Icon
                      icon="teenyicons:tick-circle-solid"
                      color="green"
                      width="15"
                      height="15"
                    />
                  </div>
                  <h6 style={{ marginTop: "5px", marginLeft: "5px" }}>
                    {item.description}
                  </h6>
                </div>
              )}
            </div>
          </div>
        ))}
      </>
    );
  };

  const renderPromoModal = () => {
    return (
      <>
        <div className="popup-overlay">
          <div className="popup">
            <button
              className="close-button btn btn-link"
              onClick={() => {
                setPromoModalOpen(false);
                setAddPromoState({
                  ...addPromoState,
                  promoCode: "",
                  promoDiscountType: "Discount Type",
                  promoPercentage: "",
                  promoAmount: "",
                  promoDuration: "Once",
                  promoNumberOfMonths: "",
                  promoDescription: "",
                });
              }}
            >
              X
            </button>
            <div className="mb-3 w-100">
              <label className="form-label">Promo Code:</label>
              <input
                type="text"
                className="form-control"
                placeholder="Create a promo code"
                value={addPromoState.promoCode}
                onChange={(e) => {
                  setAddPromoState({
                    ...addPromoState,
                    promoCode: e.target.value,
                  });
                }}
              />
            </div>
            <div className="mb-3 position-relative">
              <label className="form-label">Type:</label>
              <div
                className="d-flex position-relative"
                id="profile-dropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{ cursor: "pointer" }}
              >
                <div
                  className={`form-control text-start ${
                    addPromoState.promoDiscountType === "Discount Type"
                      ? "text-muted"
                      : null
                  }`}
                >
                  {addPromoState.promoDiscountType}
                </div>
                <img
                  src="../assets/icons/down-chevron.png"
                  className=" align-self-center position-absolute end-0"
                  alt=""
                  style={{
                    width: "16px",
                    minWidth: "25px",
                  }}
                />
              </div>
              <ul
                className="dropdown-menu from-control w-100"
                aria-labelledby="profile-dropdown"
                style={{
                  backgroundColor: "#F2F7FF",
                }}
              >
                <li>
                  <button
                    className="dropdown-item border-bottom"
                    onClick={() =>
                      setAddPromoState({
                        ...addPromoState,
                        promoDiscountType: "Percentage",
                        promoAmount: "",
                      })
                    }
                  >
                    Percentage Discount
                  </button>
                </li>
                <li>
                  <button
                    className="dropdown-item"
                    onClick={() =>
                      setAddPromoState({
                        ...addPromoState,
                        promoDiscountType: "Amount",
                        promoPercentage: "",
                      })
                    }
                  >
                    Amount Discount
                  </button>
                </li>
              </ul>
            </div>

            {addPromoState.promoDiscountType === "Percentage" && (
              <div className="mb-3">
                <label className="form-label">Percentage:</label>
                <div className="d-flex form-control">
                  <span>%</span>
                  <input
                    type="text"
                    className="border-0 p-1"
                    placeholder={"Ex: 100"}
                    value={addPromoState.promoPercentage}
                    onChange={(e) => {
                      setAddPromoState({
                        ...addPromoState,
                        promoPercentage: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            )}
            {addPromoState.promoDiscountType === "Amount" && (
              <div className="mb-3">
                <label className="form-label">Amount:</label>
                <div className="d-flex form-control">
                  <span>$</span>
                  <input
                    type="text"
                    className="border-0 p-1"
                    placeholder={"Ex: 100"}
                    value={addPromoState.promoAmount}
                    onChange={(e) => {
                      setAddPromoState({
                        ...addPromoState,
                        promoAmount: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            )}
            <div className="mb-3 position-relative">
              <label className="form-label">Duration:</label>
              <div
                className="d-flex position-relative"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{ cursor: "pointer" }}
              >
                <div
                  className={`form-control text-start ${
                    addPromoState.promoDuration === "Duration"
                      ? "text-muted"
                      : null
                  }`}
                >
                  {addPromoState.promoDuration}
                </div>
                <img
                  src="../assets/icons/down-chevron.png"
                  className=" align-self-center position-absolute end-0"
                  alt=""
                  style={{
                    width: "16px",
                    minWidth: "25px",
                  }}
                />
              </div>
              <ul
                className="dropdown-menu from-control w-100"
                aria-labelledby="profile-dropdown"
                style={{
                  backgroundColor: "#F2F7FF",
                }}
              >
                <li>
                  <button
                    className="dropdown-item border-bottom"
                    onClick={() =>
                      setAddPromoState({
                        ...addPromoState,
                        promoDuration: "Forever",
                        promoNumberOfMonths: "",
                      })
                    }
                  >
                    Forever
                  </button>
                </li>
                <li>
                  <button
                    className="dropdown-item border-bottom"
                    onClick={() =>
                      setAddPromoState({
                        ...addPromoState,
                        promoDuration: "Once",
                        promoNumberOfMonths: "",
                      })
                    }
                  >
                    Once
                  </button>
                </li>
                <li>
                  <button
                    className="dropdown-item border-bottom"
                    onClick={() =>
                      setAddPromoState({
                        ...addPromoState,
                        promoDuration: "Repeating Month",
                      })
                    }
                  >
                    Repeating Month
                  </button>
                </li>
              </ul>
            </div>
            {addPromoState.promoDuration === "Repeating Month" && (
              <div className="mb-3">
                <label className="form-label">Number of months:</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={"Ex: 3"}
                  value={addPromoState.promoNumberOfMonths}
                  onChange={(e) => {
                    setAddPromoState({
                      ...addPromoState,
                      promoNumberOfMonths: e.target.value,
                    });
                  }}
                />
              </div>
            )}
            <div className="mb-3">
              <label className="form-label">Description:</label>
              <input
                type="text"
                className="form-control"
                placeholder="Description (optional)"
                value={addPromoState.promoDescription}
                onChange={(e) => {
                  setAddPromoState({
                    ...addPromoState,
                    promoDescription: e.target.value,
                  });
                }}
              />
            </div>
            <button
              className="submit-button btn btn-primary"
              onClick={() => onClickPromoSubmit()}
              disabled={
                !(
                  addPromoState.promoCode &&
                  (addPromoState.promoPercentage ||
                    addPromoState.promoAmount) &&
                  !(
                    addPromoState.promoDuration === "Repeating Month" &&
                    !addPromoState.promoNumberOfMonths
                  )
                )
              }
            >
              {"Submit"}
            </button>
          </div>
          <style>
            {`
                                    .popup-overlay {
                                      position: fixed;
                                      top: 0;
                                      left: 0;
                                      width: 100%;
                                      height: 100%;
                                      background: rgba(0, 0, 0, 0.5);
                                      display: flex;
                                      justify-content: center;
                                      align-items: center;
                                      z-index: 9999;
                                    }

                                    .popup {
                                      position: relative;
                                      background: white;
                                      padding: 4rem;
                                      border-radius: 5px;
                                      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
                                    }

                                    .close-button {
                                      position: absolute;
                                      top: 10px;
                                      right: 10px;
                                      border: none;
                                      background: none;
                                      font-size: 18px;
                                      cursor: pointer;
                                      text-decoration:none;
                                    }

                                    .delete-popup-title {
                                      font-size: 16px;
                                    }
                                  `}
          </style>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="container-fluid position-relative w-100 h-100">
        <div className="row h-100">
          <div
            className="col-sm-2 px-0 h-100 d-md-block d-none"
            style={{ width: "15%" }}
          >
            <Header />
          </div>
          <MobileNav />
          <div className="col-12 col-md-10 px-0 h-100">
            <div className="w-100 h-100 d-flex flex-column pb-3">
              <TopHeaderSection />
              <div className="container-fluid py-3 border-top overflow-auto flex-fill">
                <div className="row">
                  <div className="col">
                    <h5 className="mb-0">Subscriptions</h5>
                  </div>
                  <div className="col-auto ms-auto">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0"></ol>
                    </nav>
                  </div>
                  <div className="border rounded mt-3">
                    <div className={"row d-flex align-items-baseline"}>
                      <div
                        className={
                          typeOfTabs === "transaction"
                            ? "d-sm-flex col-12 col-xl-3 align-items-center"
                            : "d-sm-flex col-md-6 align-items-center"
                        }
                      >
                        <div className="d-flex">
                          <button
                            className={
                              typeOfTabs === "transaction"
                                ? "btn  w-10 mt-4 mb-md-3 tabs_user"
                                : "btn  w-10 mt-4 mb-md-3 tabs_user"
                            }
                            onClick={() => {
                              setTypeOfTabs("pricing");
                              setFromDate("");
                              setToDate("");
                              setFormatFromDate("");
                              setFormatToDate("");
                            }}
                          >
                            <h6
                              className={
                                typeOfTabs === "pricing"
                                  ? "mb-0 fw-bold active-text-color"
                                  : "mb-0 fw-bold"
                              }
                            >
                              Pricing
                            </h6>
                          </button>
                          <button
                            className={
                              typeOfTabs === "promo"
                                ? "btn  w-10 mt-4 mb-md-3 tabs_user"
                                : "btn  w-10 mt-4 mb-md-3 tabs_user"
                            }
                            onClick={() => {
                              setTypeOfTabs("promo");
                              setFromDate("");
                              setToDate("");
                              setFormatFromDate("");
                              setFormatToDate("");
                            }}
                          >
                            <h6
                              className={
                                typeOfTabs === "promo"
                                  ? "mb-0 fw-bold active-text-color"
                                  : "mb-0 fw-bold"
                              }
                            >
                              Promo
                            </h6>
                          </button>
                          <button
                            className={
                              typeOfTabs === "transaction"
                                ? "btn  w-10 mt-4 mb-md-3 tabs_user"
                                : "btn  w-10 mt-4 mb-md-3 tabs_user"
                            }
                            onClick={() => {
                              setTypeOfTabs("transaction");
                            }}
                          >
                            <h6
                              className={
                                typeOfTabs === "transaction"
                                  ? "mb-0 fw-bold active-text-color"
                                  : "mb-0 fw-bold"
                              }
                            >
                              Transactions
                            </h6>
                          </button>
                        </div>
                        {typeOfTabs === "transaction" && (
                          <div className="d-flex mt-3 ml-auto justify-content-center d-xl-none">
                            <CommonSearchBox
                              searchText={searchText}
                              setSearchText={setSearchText}
                            />
                          </div>
                        )}
                      </div>
                      <div
                        className={
                          typeOfTabs === "transaction"
                            ? "d-flex col-12 col-xl-9 align-items-center justify-content-center justify-content-lg-end mt-3"
                            : "d-sm-flex col-md-6 align-items-center justify-content-center justify-content-lg-end mt-3"
                        }
                      >
                        <PricingHeader
                          typeOfTabs={typeOfTabs}
                          fromDateValue={fromDate}
                          fromDateOnChange={(e) => {
                            setFromDate(e);
                            const inputFromDate = new Date(e);
                            const formattedFromDate = `${inputFromDate.getFullYear()}-${String(
                              inputFromDate.getMonth() + 1
                            ).padStart(2, "0")}-${String(
                              inputFromDate.getDate()
                            ).padStart(2, "0")}`;
                            setFormatFromDate(formattedFromDate);
                          }}
                          toDateValue={toDate}
                          toDateOnChange={(e) => {
                            setToDate(e);
                            const inputToDate = new Date(e);
                            const nextDay = new Date(inputToDate);
                            nextDay.setDate(inputToDate.getDate() + 1);
                            const formattedToDate = `${nextDay.getFullYear()}-${String(
                              nextDay.getMonth() + 1
                            ).padStart(2, "0")}-${String(
                              nextDay.getDate()
                            ).padStart(2, "0")}`;
                            setFormatToDate(formattedToDate);
                          }}
                          searchText={searchText}
                          setSearchText={setSearchText}
                          searchOnClick={() => {
                            setFilter((prevFilter) => !prevFilter);
                            fetchTransactionDetailsData();
                          }}
                          onClickClearButton={() => {
                            setClear(true);
                          }}
                          onClickPDFButton={() => {
                            exportPDF();
                          }}
                          dataCSV={csvData}
                        />
                      </div>
                    </div>
                    {typeOfTabs === "transaction" ? (
                      <>
                        {loading === false ? (
                          <div className="row p-0 gx-0 mt-3">
                            <Transcation
                              data={transactionDetailsData}
                              page={page}
                            />
                            {transactionDetailsData !== undefined &&
                              transactionDetailsData[0]?.list_count > 10 && (
                                <Pagination
                                  itemsPerPage={10}
                                  totalItems={
                                    transactionDetailsData[0]?.list_count
                                  }
                                  paginate={paginate}
                                  currentPage={page?.currentPageForTransaction}
                                  type="transaction"
                                  setPrevBtn={() => {
                                    const newPage =
                                      page.currentPageForTransaction - 1;
                                    const newOffset = (newPage - 1) * 10;
                                    setPage((prevPage) => ({
                                      ...prevPage,
                                      currentPageForTransaction: newPage,
                                    }));
                                    setOffset(newOffset);
                                  }}
                                  setNextBtn={() => {
                                    const newPage =
                                      page.currentPageForTransaction + 1;
                                    const newOffset = (newPage - 1) * 10;
                                    setPage((prevPage) => ({
                                      ...prevPage,
                                      currentPageForTransaction: newPage,
                                    }));
                                    setOffset(newOffset);
                                  }}
                                  searchText={searchText}
                                />
                              )}
                          </div>
                        ) : (
                          <Contentloader />
                        )}
                      </>
                    ) : null}

                    {typeOfTabs === "pricing" ? (
                      <>
                        {loading === false ? (
                          <>
                            <div className="row p-0 gx-0 mt-3 border-top pt-3">
                              <h5 className="fw-bold ms-3 mt-2">Coach</h5>
                              <RenderPricingData data={coachPricingObjects} />
                              <h5 className="fw-bold ms-3 mt-2">Fan</h5>
                              <RenderPricingData data={fanPricingObjects} />
                            </div>
                          </>
                        ) : (
                          <Contentloader />
                        )}
                      </>
                    ) : null}

                    {typeOfTabs === "promo" ? (
                      <>
                        {loading === false ? (
                          <>
                            <div className="row p-0 gx-0 mt-3 border-top pt-3">
                              <div
                                className="col-md-12 col-lg-6 col-xl-4 p-0 p-sm-3 py-2"
                                style={{
                                  height:
                                    promoData.length !== 0
                                      ? undefined
                                      : "150px",
                                }}
                              >
                                <div className="h-100 d-flex align-items-center justify-content-center">
                                  <button
                                    className="h-100 w-100 rounded-3"
                                    style={{
                                      backgroundColor: "#005dab",
                                      color: "white",
                                      fontSize: 16,
                                    }}
                                    onClick={() => {
                                      setPromoModalOpen(!promoModalOpen);
                                    }}
                                  >
                                    <Icon
                                      className="me-1 mb-1"
                                      icon="mdi-light:plus-box"
                                      color="white"
                                      width="25"
                                      height="25"
                                    />
                                    Add Promo
                                  </button>
                                </div>
                              </div>
                              <RenderPromoData data={promoData} />
                              {promoModalOpen && (
                                <center>{renderPromoModal()}</center>
                              )}
                            </div>
                          </>
                        ) : (
                          <Contentloader />
                        )}
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Pricing;
