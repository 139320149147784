import React from "react";
import Header from "../../components/Header";
import TopHeaderSection from "../../components/TopHeaderSection";

const RoleMetrics = () => {
  return (
    <>
      <div>
        <div className="container-fluid position-relative w-100 h-100">
          <div className="row h-100">
            <div
              className="col-sm-2 px-0 h-100 d-md-block d-none"
              style={{ width: "15%" }}
            >
              <Header />
            </div>
            <div className="col-sm-10 px-0 h-100">
              <div className="w-100 h-100 d-flex flex-column pb-3">
                <TopHeaderSection />
                <div className="container-fluid py-3 border-top border-bottom">
                  <div className="row">
                    <div className="col">
                      <h5 className="mb-0">Role Metrics</h5>
                    </div>
                    <div className="col-auto ms-auto">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0">
                          <li className="breadcrumb-item">
                            <a href="/dashboard">Dashboard</a>
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            Role Metrics
                          </li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div>
                <div className="container-fluid mt-3 overflow-auto flex-fill">
                  <div className="row">
                    <div className="col-lg-5 d-flex flex-column">
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="border border rounded bg-white mb-3 p-3">
                            <div className="d-flex justify-content-between">
                              <p className="mb-0">Video Streamers</p>
                              <p className="align-self-center mb-0 text-success">
                                <i className="far fa-arrow-up"></i>
                                +5.25
                              </p>
                            </div>
                            <div className="d-flex justify-content-between mt-3">
                              <div>
                                <h3>$555.25K</h3>
                                <a href=".#">View more</a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="border border rounded bg-white mb-3 p-3">
                            <div className="d-flex justify-content-between">
                              <p className="mb-0">Scorers</p>
                              <p className="align-self-center mb-0 text-success">
                                <i className="far fa-arrow-up"></i>
                                +5.25
                              </p>
                            </div>
                            <div className="d-flex justify-content-between mt-3">
                              <div>
                                <h3>$555.25K</h3>
                                <a href=".#">View more</a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="border border rounded bg-white mb-3 p-3">
                            <div className="d-flex justify-content-between">
                              <p className="mb-0">Pitch Count Keepers</p>
                              <p className="align-self-center mb-0 text-success">
                                <i className="far fa-arrow-up"></i>
                                +5.25
                              </p>
                            </div>
                            <div className="d-flex justify-content-between mt-3">
                              <div>
                                <h3>$555.25K</h3>
                                <a href=".#">View more</a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="border border rounded bg-white mb-3 p-3">
                            <div className="d-flex justify-content-between">
                              <p className="mb-0">Batting Info keepers</p>
                              <p className="align-self-center mb-0 text-success">
                                <i className="far fa-arrow-up"></i>
                                +5.25
                              </p>
                            </div>
                            <div className="d-flex justify-content-between mt-3">
                              <div>
                                <h3>$555.25K</h3>
                                <a href=".#">View more</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RoleMetrics;
